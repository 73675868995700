import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ContainerRadio from "./ContainerRadio";
import { useDispatch } from "react-redux";
import { setAnswer4 } from "../../redux/slices/auth.slice";
import { Button } from "react-bootstrap";

interface IStep4 {
  stateStep: (el: number) => void;
}

const Step4: React.FC<IStep4> = ({ stateStep }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [activAnswer, setActivAnswer] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleNextStep = () => {
    dispatch(setAnswer4(activAnswer));

    setVisible(false);

    setTimeout(() => {
      stateStep(5);
    }, 1000);
  };

  const handlechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivAnswer(event.target.value);
  };

  return (
    <div
      style={{
        zIndex: 1,
        opacity: visible ? 1 : 0,
        transform: `scale(${visible ? 1 : 0.8})`,
        transition: "all 1.5s",
      }}
    >
      <figcaption className="m-0 text-secondary">{t("form.4")}</figcaption>
      <h2 className="text-start my-3 h4">{t("form.valeur")}</h2>

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.20")}
        value="-20000"
        name="step4"
        id="flexCheck1"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.50")}
        value="20001/50000"
        name="step4"
        id="flexCheck2"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.100")}
        value="50001/100000"
        name="step4"
        id="flexCheck3"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.250")}
        value="100001/250000"
        name="step4"
        id="flexCheck4"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.+250")}
        value="+250000€"
        name="step4"
        id="flexCheck5"
      />

      <div className="w-100 text-center text-lg-end">
        <Button
          disabled={!activAnswer}
          type="button"
          onClick={handleNextStep}
          className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4"
        >
          {t("form.Suivant")}
        </Button>
      </div>
    </div>
  );
};

export default Step4;
