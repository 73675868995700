import React, { useState } from "react";

const PlaceholderEndInput = () => {
  const [inputValue, setInputValue] = useState("0");

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleFocus = () => {
    inputValue === "" && setInputValue("0");
  };

  return (
    <>
      <div className="position-relative form-control form-control-sm py-2 ps-3 rounded-3 mt-2">
        <input
          type="text"
          className="form-control form-control-sm fw-bold outline-none border-0 shadow-none"
          value={`${inputValue}`}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleFocus}
        />

        <span className="fw-bold position-absolute top-50 translate-middle">
          $
        </span>

        <span
          className="position-absolute end-0 top-50 translate-middle fw-bold"
          style={{ color: "#9C9C9B" }}
        >
          USD
        </span>
      </div>

      <div className="mt-1 d-flex flex-wrap justify-content-between">
        <button
          className="mt-2 border-0 rounded-4 px-3 py-1 fw-bold"
          style={{ fontSize: "13px" }}
          onClick={() => setInputValue("50")}
        >
          $50
        </button>
        
        <button
          className="mt-2 border-0 rounded-4 px-3 py-1 fw-bold"
          style={{ fontSize: "13px" }}
          onClick={() => setInputValue("100")}
        >
          $100
        </button>
        <button
          className="mt-2 border-0 rounded-4 px-3 py-1 fw-bold"
          style={{ fontSize: "13px" }}
          onClick={() => setInputValue("500")}
        >
          $500
        </button>
        <button
          className="mt-2 border-0 rounded-4 px-3 py-1 fw-bold"
          style={{ fontSize: "13px" }}
          onClick={() => setInputValue("1000")}
        >
          $1000
        </button>
      </div>
    </>
  );
};

export default PlaceholderEndInput;
