import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
//@ts-ignore
import planetCrypto from "../../static/video/planetCrypto.mp4";
import planetCryptoDark from "../../static/video/planetCryptoDarkBg.mp4";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import ExchangeCryptoBlock from "../ExchangeCrypto/ExchangeCryptoBlock";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../providers/ThemeProvider";

export const Header = () => {
  const { t } = useTranslation();

  const [theme, setTheme] = useContext(ThemeContext);

  const [isVisible, setIsVisible] = useState(true);

  const { colorFirst, colorTextGrey } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const componentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (componentRef.current) {
        //@ts-ignore
        const currentRefRect = componentRef.current.getBoundingClientRect();

        const topOffset =
          (window.innerHeight - currentRefRect.top) / window.innerHeight;

        const bottomOffset =
          (window.innerHeight - currentRefRect.bottom) / window.innerHeight;

        setIsVisible(bottomOffset < 0.6 && topOffset > 0.3);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`position-relative animated-component ${isVisible ? "visible" : "hidden"}`}
      ref={componentRef}
    >
      <div className="container m-0 p-0 mx-auto">
        <div
          className="row m-0 p-0 px-2 pt-4 pt-lg-0"
          style={{ minHeight: "95vh" }}
        >
          <div className="col-12 col-lg-8 p-0 d-flex flex-column justify-content-center mb-5 mb-lg-0">
            <p
              className="lh-1 fw-bold m-0 header__title mx-auto position-relative text-center text-lg-start"
              style={{ fontFamily: "Inter 600", zIndex: 1 }}
            >
              {t("personal.header.title")}
            </p>

            <p
              className="fs-4 my-4 text-center text-lg-start mx-auto mx-lg-0 home__background-text"
              style={{ maxWidth: "500px" }}
            >
              {t("personal.header.sub")}
            </p>

            <div
              className="row mx-0 position-relative d-flex justify-content-center justify-content-lg-start"
              style={{
                zIndex: 2,
              }}
            >
              <Link
                to="/signup"
                className="col-12 col-lg-4 col-sm-5 me-sm-4 navbarCont__button fw-bold text-dark text-decoration-none p-3 text-center rounded-5"
                style={{ backgroundColor: colorFirst }}
              >
                {t("personal.header.buttons.open")}
              </Link>

              <Link
                to="/signup"
                className="col-12 col-lg-4 col-sm-5 navbarCont__button--silver fw-bold home__background-text text-decoration-none p-3 text-center rounded-5 mt-3 mt-sm-0"
                style={{ border: "2px solid black" }}
              >
                {t("personal.header.buttons.buy")}
              </Link>
            </div>
          </div>

          <div className="col-12 col-lg-4 p-0 d-flex justify-content-center justify-content-lg-start align-items-center">
            <ExchangeCryptoBlock />
          </div>
        </div>
      </div>

      {theme === "light" && (
        <video
          autoPlay
          loop
          muted
          className="col-12 col-lg-8 p-0 text-center position-absolute"
          style={{
            zIndex: -1,
            bottom: 0,
          }}
        >
          <source src={planetCrypto} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {theme !== "light" && (
        <video
          autoPlay
          loop
          muted
          className="col-12 col-lg-8 p-0 text-center position-absolute"
          style={{
            zIndex: -1,
            bottom: 0,
          }}
        >
          <source src={planetCryptoDark} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};
