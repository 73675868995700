import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../components/Layout";
import { Link } from "react-router-dom";
import earthHalf from "../static/images/AboutUs/earthHalf.png";
import smartphoneHome from "../static/images/smartphoneHome.png";
import logo from "../static/images/AboutUs/logo.png";
import crypto from "../static/images/AboutUs/crypto.png";
import kycIcon from "../static/icons/aboutUs/kycIcon.svg";
import bankingIcon from "../static/icons/aboutUs/bankingIcon.svg";
import securityIcon from "../static/icons/aboutUs/securityIcon.svg";

import comissionIcon from "../static/icons/aboutUs/comissionIcon.svg";
import cashbackIcon from "../static/icons/aboutUs/cashbackIcon.svg";
import referalIcon from "../static/icons/aboutUs/referalIcon.svg";
import firstIcon from "../static/icons/aboutUs/+40.png";
import secondIcon from "../static/icons/aboutUs/+120.png";
import thirdIcon from "../static/icons/aboutUs/+15.png";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/store/store";

const signUpLink = process.env.REACT_APP_LINK_SIGNUP;
const blogLink = process.env.REACT_APP_LINK_BLOG;

export const AboutUs = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { navHomeHeigth } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const [isVisible, setIsVisible] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const refs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const arrowRef = useRef(null);

  const handleClick = () => {
    //@ts-ignore
    arrowRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setIsVisible((prevIsVisible) => {
              const newIsVisible = [...prevIsVisible];
              newIsVisible[index] = true;
              return newIsVisible;
            });
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    refs.current.forEach((ref) => {
      if (ref.current) {
        //@ts-ignore
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.current.forEach((ref) => {
        if (ref.current) {
          //@ts-ignore
          observer.unobserve(ref.current);
        }
      });
    };
  }, [window.scrollY]);

  return (
    <Layout>
      <div
        className="d-flex flex-column justify-content-between"
        style={{
          minHeight: `calc(100vh - ${navHomeHeigth}px)`,
        }}
      >
        <div className="container">
          <div className="about__title text-center lh-sm">
            <p className="m-0 mt-5">{t("about.title.1")}</p>
            <p>
              <span
                className="text-white rounded-2"
                style={{
                  background: "#D8AFF3",
                  padding: "0 5px",
                }}
              >
                Web3
              </span>{" "}
              {t("about.title.2")}
            </p>
          </div>

          <p
            className="about__subtitle text-center lh-sm mx-auto mt-2"
            style={{
              fontFamily: "Inter, sans-serif",
              maxWidth: "850px",
            }}
          >
            {t("about.subtitle")}
          </p>

          <div className="row m-0 p-0 pt-lg-4 px-xxl-5">
            <div className="col-9 col-sm-8 col-md-4 mx-auto mx-md-0 px-2 mt-4">
              <div
                className="lh-sm"
                style={{
                  background: "#DBB1F6",
                  borderRadius: "15px",
                  padding: "35px 0px",
                }}
              >
                <div className="d-flex justify-content-center about__assets-icon">
                  <img
                    src={firstIcon}
                    alt="+40"
                    style={{
                      height: "100%",
                    }}
                  />
                </div>

                <div className="about__assets text-white text-center px-2">
                  {t("about.why.assets.crypto")}
                </div>
              </div>
            </div>

            <div className="col-9 col-sm-8 col-md-4 mx-auto mx-md-0 px-2 mt-4">
              <div
                className="lh-sm"
                style={{
                  background: "#DBB1F6",
                  borderRadius: "15px",
                  padding: "35px 0px",
                }}
              >
                <div className="d-flex justify-content-center about__assets-icon">
                  <img
                    src={secondIcon}
                    alt="+15"
                    style={{
                      height: "100%",
                    }}
                  />
                </div>

                <div className="about__assets text-center text-white py-0 px-2">
                  {t("about.why.assets.countries")}
                </div>
              </div>
            </div>

            <div className="col-9 col-sm-8 col-md-4 mx-auto mx-md-0 px-2 mt-4 mb-5">
              <div
                className="lh-sm"
                style={{
                  background: "#DBB1F6",
                  borderRadius: "15px",
                  padding: "35px 0px",
                }}
              >
                <div className="d-flex justify-content-center about__assets-icon">
                  <img
                    src={thirdIcon}
                    alt="+120"
                    style={{
                      height: "100%",
                    }}
                  />
                </div>

                <div className="about__assets text-center text-white px-2">
                  {t("about.why.assets.currencies")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex align-items-center fw-bold mx-auto"
          style={{
            transform: "rotate(90deg)",
            height: "120px",
            width: "120px",
          }}
        >
          <button
            className="d-flex align-items-center bg-transparent border-0 p-0 w-100"
            onClick={handleClick}
          >
            <div className="about__arrow" />

            <p
              className="mb-0 mt-1 pe-2 fs-6 color-text-dark"
              style={{
                height: "25px",
              }}
            >
              SCROLL
            </p>

            <div
              className="about__arro border-color"
              style={{
                flex: 1,
              }}
            ></div>
          </button>
        </div>
      </div>

      <div
        className="position-relative"
        ref={arrowRef}
        style={{
          backgroundColor: "#24232F",
          overflow: "hidden",
        }}
      >
        <div className="position-absolute h-100 translate-middle-y d-none d-md-block about__why--earth">
          <img
            src={earthHalf}
            alt="earthHalf"
            style={{
              height: "100%",
            }}
          />
        </div>

        <div className="container">
          <p className="about__title text-white text-center text-md-start mt-4 mt-md-5">
            {t("about.why.title")}
          </p>

          <div
            style={{
              marginTop: "110px",
            }}
          >
            <div
              className="d-flex flex-column flex-md-row align-items-center"
              style={{
                opacity: isVisible[0] ? 1 : 0,
                transition: "all 0.8s ease-in-out",
              }}
              //@ts-ignore
              ref={refs.current[0]}
            >
              <div className="d-flex about__why--icon">
                <img
                  src={kycIcon}
                  alt="kycIcon"
                  style={{
                    height: "100%",
                  }}
                />
              </div>

              <p className="about__why--items text-white text-center text-md-start m-0 ms-4 ms-lg-5 mt-2 mt-sm-0">
                {t("about.why.kyc")}
              </p>
            </div>

            <div
              className="d-flex flex-column flex-md-row align-items-center mt-5"
              style={{
                opacity: isVisible[1] ? 1 : 0,
                transition: "all 0.8s ease-in-out",
              }}
              //@ts-ignore
              ref={refs.current[1]}
            >
              <div className="d-flex about__why--icon">
                <img
                  src={bankingIcon}
                  alt="bankingIcon"
                  style={{
                    height: "100%",
                  }}
                />
              </div>

              <p className="about__why--items text-white text-center text-md-start m-0 ms-4 ms-lg-5 mt-2 mt-sm-0">
                {t("about.why.banking")}
              </p>
            </div>

            <div
              className="d-flex flex-column flex-md-row align-items-center mt-5"
              style={{
                opacity: isVisible[2] ? 1 : 0,
                transition: "all 0.8s ease-in-out",
              }}
              //@ts-ignore
              ref={refs.current[2]}
            >
              <div className="d-flex about__why--icon">
                <img
                  src={securityIcon}
                  alt="securityIcon"
                  style={{
                    height: "100%",
                  }}
                />
              </div>

              <p className="about__why--items text-white text-center text-md-start m-0 ms-4 ms-lg-5 mt-2 mt-sm-0">
                {t("about.why.policy")}
              </p>
            </div>
          </div>

          <div
            className="text-white pb-3 d-flex flex-wrap"
            style={{
              marginTop: "180px",
              fontSize: "12px",
            }}
          >
            <span>{t("about.why.terms.1")}</span>

            <Link to="/conditions" className="text-white d-flex ms-2">
              {t("about.why.terms.2")}
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-between p-0 m-0 my-5 py-5">
          <div className="col-12 col-lg-7 d-flex flex-column justify-content-lg-center">
            <p className="about__title">{t("about.why.company.title")}</p>

            <p className="about__text">{t("about.why.company.text")}</p>
          </div>

          <div className="col-6 col-lg-4 col-xl-3 text-center mx-auto mx-lg-0 pt-5 pt-lg-0">
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>

        <div className="border border-dark my-5"></div>

        <div className="row justify-content-between p-0 m-0 my-5 py-lg-5">
          <div className="col-9 col-lg-5 col-xl-4 d-flex align-items-center mx-auto mx-lg-0 pt-5 pt-lg-0 order-2 order-lg-0">
            <img
              src={crypto}
              alt="crypto"
              style={{
                width: "100%",
              }}
            />
          </div>

          <div className="col-12 col-lg-6 d-flex flex-column justify-content-lg-center order-1">
            <p className="about__title">{t("about.why.choice.title")}</p>

            <p className="about__text">{t("about.why.choice.text")}</p>
          </div>
        </div>

        <div className="border border-dark my-5"></div>

        <div className="row p-0 m-0 my-5 py-lg-5">
          <div className="col-12 col-lg-6">
            <p className="about__title">{t("about.why.knowledge.title")}</p>

            <p className="about__text">{t("about.why.knowledge.text")}</p>
          </div>

          <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center mt-5 mt-lg-0">
            {!!blogLink?.length && (
              <Nav.Link
                href={blogLink}
                className="d-flex justify-content-center py-3 py-lg-2 text-white about__button text-decoration-none"
                style={{
                  background: "#D8AFF3",
                }}
              >
                {t("about.why.knowledge.button")}
              </Nav.Link>
            )}
          </div>
        </div>
      </div>

      <div
        className="py-5"
        style={{
          backgroundColor: "#24232F",
          overflow: "hidden",
        }}
      >
        <div className="container pb-5">
          <p className="about__title text-white text-center text-lg-start my-lg-5 pb-lg-5">
            {t("about.bonuses.title")}
          </p>

          <div className="row justify-content-between m-0 p-0 pt-lg-5">
            <div className="col-7 col-lg-4 d-lg-flex align-items-center mx-auto mx-lg-0 my-5 my-lg-0 p-0 pe-xxl-5">
              <img
                src={smartphoneHome}
                alt="smartphoneHome"
                style={{
                  width: "100%",
                }}
              />
            </div>

            <div className="col-lg-8 pt-md-5 d-flex flex-column justify-content-lg-between p-0">
              <div
                className="d-flex flex-column flex-md-row align-items-center"
                style={{
                  opacity: isVisible[3] ? 1 : 0,
                  transition: "all 0.8s ease-in-out",
                }}
                //@ts-ignore
                ref={refs.current[3]}
              >
                <div className="d-flex about__why--icon">
                  <img
                    src={comissionIcon}
                    alt="comissionIcon"
                    style={{
                      height: "100%",
                    }}
                  />
                </div>

                <p className="about__why--items text-white text-center text-md-start m-0 ms-md-4 mt-2 mt-md-0">
                  {t("about.bonuses.commisson")}
                </p>
              </div>

              <div
                className="d-flex flex-column flex-md-row align-items-center mt-4 mt-xl-5"
                style={{
                  opacity: isVisible[4] ? 1 : 0,
                  transition: "all 0.8s ease-in-out",
                }}
                //@ts-ignore
                ref={refs.current[4]}
              >
                <div className="d-flex about__why--icon">
                  <img
                    src={cashbackIcon}
                    alt="cashbackIcon"
                    style={{
                      height: "100%",
                    }}
                  />
                </div>

                <p className="about__why--items text-white text-center text-md-start m-0 ms-md-4 mt-2 mt-md-0">
                  {t("about.bonuses.cashback")}
                </p>
              </div>

              <div
                className="d-flex flex-column flex-md-row align-items-center mt-4 mt-xl-5"
                style={{
                  opacity: isVisible[5] ? 1 : 0,
                  transition: "all 0.8s ease-in-out",
                }}
                //@ts-ignore
                ref={refs.current[5]}
              >
                <div className="d-flex about__why--icon">
                  <img
                    src={referalIcon}
                    alt="referalIcon"
                    style={{
                      height: "100%",
                    }}
                  />
                </div>

                <p className="about__why--items text-white text-center text-md-start m-0 ms-md-4 mt-2 mt-md-0">
                  {t("about.bonuses.referral")}
                </p>
              </div>

              <div
                className="d-flex justify-content-center justify-content-lg-start mt-5 pt-5"
                style={{
                  opacity: isVisible[6] ? 1 : 0,
                  transition: "all 0.8s ease-in-out",
                }}
                //@ts-ignore
                ref={refs.current[6]}
              >
                <Link
                  // href={signUpLink}
                  to="/signup"
                  className="d-flex justify-content-center py-3 px-5 py-lg-2 bg-white text-dark about__button text-decoration-none"
                  style={{
                    borderRadius: "56px",
                  }}
                >
                  {t("about.bonuses.button")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
