import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IRootState } from "../../redux/store/store";
import homeIcon from "../../static/icons/app/navbar/homeIcon.svg";
import tradeIcon from "../../static/icons/app/navbar/tradeIcon.svg";
import overviewIcon from "../../static/icons/app/navbar/overviewIcon.svg";
import settingsIcon from "../../static/icons/app/navbar/settingsIcon.svg";
import logoutIcon from "../../static/icons/app/navbar/logoutIcon.svg";
import { useTranslation } from "react-i18next";
import { setLogOut } from "../../redux/slices/auth.slice";

const AsideLeft = () => {
  const { colorTextGrey } = useSelector((state: IRootState) => state.styleData);

  const location = useLocation();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");

    dispatch(setLogOut());

    navigate("/signin");
  };

  return (
    <div
      className="pt-5 pb-3 h-100 d-flex flex-column justify-content-between"
      style={{
        fontFamily: "Inter 500",
        fontSize: "21px",
        maxHeight: "80vh",
      }}
    >
      <div>
        <Link
          to="/home"
          className={`d-flex align-items-center text-decoration-none rounded-5 ${location.pathname === "/home" && "app__card"}`}
          style={{
            maxWidth: "223px",
            height: "60px",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center rounded-5"
            style={{
              width: "54px",
              height: "54px",
              marginLeft: "3px",
              backgroundColor: "#E8ECF5",
            }}
          >
            <img src={homeIcon} alt="home" />
          </div>

          <span className="ps-3 color-text-dark">{t("app.nav.home")}</span>
        </Link>

        <Link
          to="/trade"
          className={`d-flex align-items-center text-decoration-none rounded-5 mt-2 ${location.pathname === "/trade" && "app__card"}`}
          style={{
            maxWidth: "223px",
            height: "60px",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center rounded-5"
            style={{
              width: "54px",
              height: "54px",
              marginLeft: "3px",
              backgroundColor: "#E8ECF5",
            }}
          >
            <img src={tradeIcon} alt="tradeIcon" />
          </div>

          <span className="ps-3 color-text-dark">{t("app.nav.trade")}</span>
        </Link>

        <Link
          to="/history"
          className={`d-flex align-items-center text-decoration-none rounded-5 mt-2 ${location.pathname === "/history" && "app__card"}`}
          style={{
            maxWidth: "223px",
            height: "60px",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center rounded-5"
            style={{
              width: "54px",
              height: "54px",
              marginLeft: "3px",
              backgroundColor: "#E8ECF5",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="black"
              className="bi bi-receipt"
              viewBox="0 0 16 16"
            >
              <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
              <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
            </svg>
          </div>

          <span className="ps-3 color-text-dark">{t("app.nav.history")}</span>
        </Link>

        <Link
          to="/explore"
          className={`d-flex align-items-center text-decoration-none rounded-5 mt-2 ${location.pathname === "/explore" && "app__card"}`}
          style={{
            maxWidth: "223px",
            height: "60px",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center rounded-5"
            style={{
              width: "54px",
              height: "54px",
              marginLeft: "3px",
              backgroundColor: "#E8ECF5",
            }}
          >
            <img src={overviewIcon} alt="ExploreIcon" />
          </div>

          <span className="ps-3 color-text-dark">{t("app.nav.explore")}</span>
        </Link>
      </div>

      <div>
        <Link
          to="/settings"
          className={`d-flex align-items-center text-decoration-none rounded-5 ${location.pathname === "/settings" && "app__card"}`}
          style={{
            maxWidth: "223px",
            height: "60px",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center rounded-5"
            style={{
              width: "54px",
              height: "54px",
              marginLeft: "3px",
              backgroundColor: "#E8ECF5",
            }}
          >
            <img src={settingsIcon} alt="settingsIcon" />
          </div>

          <span className="ps-3 color-text-dark py-1">{t("app.nav.settings")}</span>
        </Link>

        <button
          className="d-flex align-items-center text-decoration-none rounded-5 mt-2 border-0 bg-transparent p-0"
          style={{
            maxWidth: "223px",
            height: "60px",
          }}
          onClick={handleLogOut}
        >
          <div
            className="d-flex justify-content-center align-items-center rounded-5"
            style={{
              width: "54px",
              height: "54px",
              marginLeft: "3px",
              backgroundColor: "#E8ECF5",
            }}
          >
            <img src={logoutIcon} alt="logoutIcon" />
          </div>

          <span className="ps-3 color-text-dark">{t("app.nav.logout")}</span>
        </button>
      </div>
    </div>
  );
};

export default AsideLeft;
