import React, { useState, Children, cloneElement } from "react";
import arrowNext from "../../../static/icons/app/home/arrowNext.svg";

export const SliderS = ({ children, displayCount }: any) => {
  const [current, setCurrent] = useState(0);

  const prevSlide = () => {
    setCurrent(
      current - displayCount < 0
        ? Children.count(children) - displayCount
        : current - displayCount
    );
  };

  const nextSlide = () => {
    setCurrent(
      current + displayCount >= Children.count(children)
        ? 0
        : current + displayCount
    );
  };

  const childrenArray = Children.toArray(children);

  return (
    <div className="position-relative">
      <button
        className="position-absolute end-0 top-50 border-0 bg-white rounded-3 translate-middle-y app__home-charts-arrow"
        style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}
        onClick={nextSlide}
      >
        <img src={arrowNext} alt="arrowNext" />
      </button>

      <div className="d-flex justify-content-between pe-3">
        {childrenArray
          .slice(current, current + displayCount)
          .map((child, index) =>
            cloneElement(
              child as React.ReactElement<
                any,
                string | React.JSXElementConstructor<any>
              >,
              { key: index }
            )
          )}
      </div>
    </div>
  );
};
