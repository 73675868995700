import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ContainerRadio from "./ContainerRadio";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setAnswer2 } from "../../redux/slices/auth.slice";

interface IStep2 {
  stateStep: (el: number) => void;
}

const Step2: React.FC<IStep2> = ({ stateStep }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [activAnswer, setActivAnswer] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleNextStep = () => {
    dispatch(setAnswer2(activAnswer));

    setVisible(false);

    setTimeout(() => {
      stateStep(3);
    }, 1000);
  };

  const handlechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivAnswer(event.target.value);
  };

  return (
    <div
      style={{
        zIndex: 1,
        opacity: visible ? 1 : 0,
        transform: `scale(${visible ? 1 : 0.8})`,
        transition: "all 1.5s",
      }}
    >
      <figcaption className="m-0 text-secondary">{t("form.2")}</figcaption>

      <h2 className="text-start my-3 h4">{t("form.déscription")}</h2>

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Employé")}
        value={t("form.Employé")}
        name="step2"
        id="flexCheckDefault1"
      />

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Indépendant")}
        value={t("form.Indépendant")}
        name="step2"
        id="flexCheckDefault2"
      />

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Retraité")}
        value={t("form.Retraité")}
        name="step2"
        id="flexCheckDefault3"
      />

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Étudiant")}
        value={t("form.Étudiant")}
        name="step2"
        id="flexCheckDefault4"
      />

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.emploi")}
        value={t("form.emploi")}
        name="step2"
        id="flexCheckDefault5"
      />

      <div className="w-100 text-center text-lg-end">
        <Button
          disabled={!activAnswer}
          type="button"
          onClick={handleNextStep}
          className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4"
        >
          {t("form.Suivant")}
        </Button>
      </div>
    </div>
  );
};

export default Step2;
