//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import cardStep1 from "../../static/icons/cardStep1.png";
import cardStep2 from "../../static/icons/cardStep2.png";
import cardStep3 from "../../static/icons/rocket1.png";
import planetCard from "../../static/video/planetCard.mp4";
import { useTranslation } from "react-i18next";

const ScrollBrand = () => {
  const { t } = useTranslation();

  const { colorFirst } = useSelector(({ styleData }: IRootState) => styleData);

  const elementLogoRef = useRef(null);
  const elementInfoRef = useRef(null);
  const elementWindow1Ref = useRef(null);
  const elementWindow3Ref = useRef(null);
  const elementWindowMobileRef = useRef(null);

  const [stickyBrand, setStickyBrand] = useState(true);
  const [visibleLogo, setVisibleLogo] = useState(true);
  const [goToSide, setGoToSide] = useState(false);
  const [changeLogo, setChangeLogo] = useState(false);
  const [isMobile] = useState(window.innerWidth <= 900);
  const [visibleBrand, setVisibleBrand] = useState(true);

  const smallWindowWidth = 300;

  useEffect(() => {
    const handleScroll = () => {
      const window1RefRect = elementWindow1Ref.current.getBoundingClientRect();
      const window3RefRect = elementWindow3Ref.current.getBoundingClientRect();
      const infoRefRect = elementInfoRef.current.getBoundingClientRect();

      setStickyBrand(0.08 * window.scrollY < 220);

      setVisibleLogo(window1RefRect.top >= 621);

      if (isMobile) {
        setGoToSide(window3RefRect.top <= 0.6 * window.innerHeight);
      } else {
        setGoToSide(infoRefRect.top <= 0.4 * window.innerHeight);

        setVisibleBrand(infoRefRect.top >= 0.6 * window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(
      () => {
        setChangeLogo(goToSide);
      },
      goToSide ? 300 : 700
    );
  }, [goToSide]);

  return (
    <>
      <div
        className="d-flex flex-column p-0 w-100"
        style={{
          height: "100%",
          minHeight: "4500px",
          marginBottom: !isMobile ? "250px" : "",
        }}
      >
        <div
          className="d-flex flex-column sticky-top w-100 overflow-hidden move__brand"
          style={{
            opacity: visibleBrand ? 1 : 0,
          }}
        >
          <span
            className="fw-bold d-flex ps-4 move__brand-title"
            style={{
              transform: `translate3d(-${0.08 * window.scrollY}px, 0px, 0px)`,
            }}
          >
            CORNERSWAP
          </span>
        </div>

        <div
          ref={elementLogoRef}
          className="sticky-top"
          style={{
            marginTop: "400px",
            zIndex: "2",
            top: !stickyBrand
              ? `${(0.14 * window.scrollY).toFixed()}px`
              : "285px",
            left: isMobile ? "auto" : "68%",

            width: "200px",
            margin: "0 auto",
          }}
        >
          {visibleLogo && (
            <img src={cornerLogo} alt="cornerLogo" height={200} />
          )}
        </div>

        <div
          ref={elementWindow1Ref}
          className="ps-1 mx-auto position-relative h-100"
          style={{
            marginTop: "2000px",
            zIndex: "1",
            transition: "all 2s ease",
            opacity: goToSide && isMobile ? 0 : 1,
            maxWidth: "1320px",
            width: "100%",
          }}
        >
          <div
            className="rounded-5 sticky-top home__background-card"
            style={{
              maxWidth: `${smallWindowWidth}px`,
              width: "100%",
              height: "400px",
              border: "3px solid black",
              top: goToSide
                ? 0.6 * window.innerHeight
                : 0.4 * window.innerHeight,
              transition: "all 2s ease",
              left: goToSide ? "0" : isMobile ? "0" : "66%",
            }}
          >
            <div
              className="w-100 rounded-top-5 p-3 text-center home__background-card--head"
              style={{
                height: "70px",
              }}
            >
              <span className="fw-bold fs-4">Step 1 - Registration</span>
            </div>

            <div
              className="mx-auto text-center d-flex align-items-center justify-content-center"
              style={{ height: "330px" }}
            >
              {!visibleLogo && !changeLogo && (
                <img src={cornerLogo} alt="cornerLogo" height={200} />
              )}

              {changeLogo && (
                <img src={cardStep1} alt="cardStep1" height={250} />
              )}
            </div>
          </div>

          <div
            className="rounded-5 sticky-top home__background-card"
            style={{
              maxWidth: `${smallWindowWidth}px`,
              width: "100%",
              height: "400px",
              transition: "all 2s ease",
              top: goToSide
                ? 0.6 * window.innerHeight
                : 0.5 * window.innerHeight,
              left: goToSide ? "0" : isMobile ? "auto" : "63%",
              right: "0px",
              margin: "0 auto",
              marginTop: "300px",
              border: "3px solid black",
            }}
          >
            <div
              className="w-100 rounded-top-5 p-3 text-center home__background-card--head"
              style={{
                height: "70px",
              }}
            >
              <span className="fw-bold fs-4">Step 2 - KYC</span>
            </div>

            <div
              className="mx-auto text-center d-flex align-items-center justify-content-center"
              style={{ height: "330px" }}
            >
              <img src={cardStep2} alt="cardStep2" height={250} />
            </div>
          </div>

          <div
            ref={elementWindow3Ref}
            className="rounded-5 sticky-top home__background-card"
            style={{
              maxWidth: `${smallWindowWidth}px`,
              width: "100%",
              height: "400px",
              background: "rgb(232, 236, 245)",
              border: "3px solid black",
              marginTop: "300px",
              transition: "all 2s ease",
              top: 0.6 * window.innerHeight,
              left: goToSide ? window.innerWidth : "60%",
            }}
          >
            <div
              className="w-100 rounded-top-5 p-3 text-center home__background-card--head"
              style={{
                height: "70px",
              }}
            >
              <span className="fw-bold fs-4">Step 3 - Let's go !</span>
            </div>

            <div
              className="mx-auto text-center d-flex align-items-center justify-content-center"
              style={{ height: "330px" }}
            >
              {/* <img src={cardStep3} alt="cardStep3" height={250} /> */}

              <video autoPlay loop muted className="w-100 p-0 text-center">
                <source src={planetCard} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div
            ref={elementInfoRef}
            className={`ps-1 h-100 ${isMobile && "d-none"}`}
            style={{
              marginTop: "300px",
              marginBottom: "700px",
              zIndex: "-10",
              transition: "all 2s ease",
              width: "320px",
            }}
          >
            <p className="fw-bold lh-1 header__title">
              Crypto payment anywhere
            </p>

            <p className="fs-5">{t("personal.scrollBrand.sub")}</p>
          </div>
        </div>
      </div>

      <div
        ref={elementWindowMobileRef}
        className={`d-flex flex-column ${!isMobile && "d-none"} px-2`}
        style={{
          transition: "all 2s ease",
        }}
      >
        <div
          className={`px-3 h-100 mb-4`}
          style={{
            transition: "all 2s ease",
          }}
        >
          <p className="fw-bold lh-1" style={{ fontSize: "45px" }}>
            Crypto payment anywhere
          </p>

          <p className="fs-3">{t("personal.scrollBrand.sub")}</p>
        </div>

        <div
          className="mx-auto rounded-5 mt-2 home__background-card"
          style={{
            maxWidth: `${smallWindowWidth}px`,
            width: "100%",
            height: "400px",
            border: "3px solid black",
          }}
        >
          <div
            className="w-100 rounded-top-5 p-3 text-center home__background-card--head"
            style={{
              height: "70px",
            }}
          >
            <span className="fw-bold fs-4">Step 1 - Registration</span>
          </div>

          <div
            className="mx-auto text-center d-flex align-items-center justify-content-center"
            style={{ height: "330px" }}
          >
            <img src={cardStep1} alt="cardStep1" height={250} />
          </div>
        </div>

        <div
          className="rounded-5 mx-auto mt-3 home__background-card"
          style={{
            maxWidth: `${smallWindowWidth}px`,
            width: "100%",
            height: "400px",
            border: "3px solid black",
            transition: "all 2s ease",
          }}
        >
          <div
            className="w-100 rounded-top-5 p-3 text-center home__background-card--head"
            style={{
              height: "70px",
            }}
          >
            <span className="fw-bold fs-4">Step 2 - KYC</span>
          </div>

          <div
            className="mx-auto text-center d-flex align-items-center justify-content-center"
            style={{ height: "330px" }}
          >
            <img src={cardStep2} alt="cardStep2" height={250} />
          </div>
        </div>

        <div
          className="rounded-5 mx-auto mt-3 home__background-card"
          style={{
            maxWidth: `${smallWindowWidth}px`,
            width: "100%",
            height: "400px",
            border: "3px solid black",
            transition: "all 2s ease",
          }}
        >
          <div
            className="w-100 rounded-top-5 p-3 text-center home__background-card--head"
            style={{
              height: "70px",
            }}
          >
            <span className="fw-bold fs-4">Step 3 - Let's go !</span>
          </div>

          <div
            className="mx-auto text-center d-flex align-items-center justify-content-center"
            style={{ height: "330px" }}
          >
            {/* <img src={cardStep3} alt="cardStep3" height={250} /> */}

            <video autoPlay loop muted className="w-100 p-0 text-center">
              <source src={planetCard} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollBrand;
