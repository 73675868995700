import React, { useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { setLangIcon, setLangValue } from "../../redux/slices/language.slice";
import i18n from "../../i18n/config";
import { IRootState } from "../../redux/store/store"

interface ILanguagesBarProps {
  classProp: string;
}

export const LanguagesBar: React.FC<ILanguagesBarProps> = ({ classProp }) => {
  const flagIcon = useSelector(
    ({ languageData }: IRootState) => languageData.lngIcon
  );
  const currentLng = useSelector(
    ({ languageData }: IRootState) => languageData.lngValue
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const lng = localStorage.getItem("lng");
    const lngIcon = localStorage.getItem("lngIcon");

    if (!lng && !lngIcon) {
      localStorage.setItem("lng", "en");
      localStorage.setItem(
        "lngIcon",
        "https://www.shutterstock.com/image-vector/british-flag-standard-colors-circular-600nw-2204124575.jpg"
      );
    } else {
      i18n.changeLanguage(lng ? lng : "en");
    }

  }, []);

  const handleClicklng = (ln: string, srcIcon: string) => {
    i18next.changeLanguage(ln);

    localStorage.setItem("lng", ln);
    localStorage.setItem("lngIcon", srcIcon);

    dispatch(setLangIcon(srcIcon));
    dispatch(setLangValue(ln));
  };

  const navDropdownTitle = (
    <img
      src={flagIcon}
      alt="Flag - Round"
      width="20"
      height="20"
      style={{
        borderRadius: "50%",
      }}
    ></img>
  );

  const checkMark = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="green"
      className="bi bi-check2-circle mx-2"
      viewBox="0 0 16 16"
    >
      {" "}
      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />{" "}
      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />{" "}
    </svg>
  );

  return (
    <NavDropdown
      className={classProp}
      title={navDropdownTitle}
      id="offcanvasNavbarDropdown-expand-lg"
      style={{
        height: "27px",
        fill: "white",
        marginBottom: "1px"
      }}
    >
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "en",
            "https://www.shutterstock.com/image-vector/british-flag-standard-colors-circular-600nw-2204124575.jpg"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://www.shutterstock.com/image-vector/british-flag-standard-colors-circular-600nw-2204124575.jpg"
              alt="Flag UK Vector"
              width="17"
              height="17"
            />
          </div>
          {"  "}
          English
          {currentLng === "en" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "fr",
            "https://cdn.countryflags.com/thumbs/france/flag-3d-round-250.png"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1 border border-dark rounded"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://cdn.countryflags.com/thumbs/france/flag-3d-round-250.png"
              alt="Flag of France - Round"
              width="17px"
              height="17px"
            ></img>
          </div>{" "}
          Français
          {currentLng === "fr" && checkMark}
        </div>
      </NavDropdown.Item>
      {/* <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "pl",
            "https://i.pinimg.com/474x/a2/1b/ab/a21bab6fe3052ced7dac7d5a2f7d201a.jpg"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1 border border-dark rounded"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
              position: "relative",
            }}
          >
            <img
              src="https://i.pinimg.com/474x/a2/1b/ab/a21bab6fe3052ced7dac7d5a2f7d201a.jpg"
              alt="Flag of Polish - Round"
              width="17px"
              height="17px"
            ></img>
          </div>{" "}
          Polonês
          {currentLng === "pl" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "es",
            "https://as2.ftcdn.net/v2/jpg/01/74/67/05/1000_F_174670584_wgZshhnkhaAcQWDQQGam8f08FHRzG3h2.jpg"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
              position: "relative",
            }}
          >
            <img
              src="https://as2.ftcdn.net/v2/jpg/01/74/67/05/1000_F_174670584_wgZshhnkhaAcQWDQQGam8f08FHRzG3h2.jpg"
              width="17"
              height="17"
              alt="Spain flag vector"
            />
          </div>{" "}
          Español
          {currentLng === "es" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "al",
            "https://icons.veryicon.com/png/Flag/Rounded%20World%20Flags/Germany%20Flag.png"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://icons.veryicon.com/png/Flag/Rounded%20World%20Flags/Germany%20Flag.png"
              alt="Flag of Germany - Round"
              width="17"
              height="17"
            ></img>
          </div>{" "}
          Deutsch
          {currentLng === "al" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "it",
            "https://img.freepik.com/premium-vector/italy-flag-icon-flat-country-italy-flag-illustration-national-italian-element-vector_485380-522.jpg"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://img.freepik.com/premium-vector/italy-flag-icon-flat-country-italy-flag-illustration-national-italian-element-vector_485380-522.jpg"
              alt="Flag Italy Vector"
              width="17"
              height="17"
            />
          </div>{" "}
          Italiano
          {currentLng === "it" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "ho",
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP--I17ixug03pVzJXh6ltibmlPHbuhk7CrA&usqp=CAU"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP--I17ixug03pVzJXh6ltibmlPHbuhk7CrA&usqp=CAU"
              alt="Flag Nederlands Vector"
              width="17"
              height="17"
            />{" "}
          </div>{" "}
          Nederlands
          {currentLng === "ho" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "in",
            "https://as1.ftcdn.net/v2/jpg/01/74/67/08/1000_F_174670852_YXvoPN8GvhfYPvpIKKXlgmj8knejHKdf.jpg"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://as1.ftcdn.net/v2/jpg/01/74/67/08/1000_F_174670852_YXvoPN8GvhfYPvpIKKXlgmj8knejHKdf.jpg"
              alt="Flag India Vector"
              width="17"
              height="17"
            />
          </div>{" "}
          हिन्दी
          {currentLng === "in" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "ko",
            "https://images.freeimages.com/vhq/images/previews/fbe/south-korean-flag-104687.png"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1 border border-dark rounded"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://images.freeimages.com/vhq/images/previews/fbe/south-korean-flag-104687.png"
              alt="Flag Korea Vector"
              width="17"
              height="17"
            />
          </div>{" "}
          한국어
          {currentLng === "ko" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "tu",
            "https://icons.veryicon.com/png/Flag/Rounded%20World%20Flags/Turkey%20Flag.png"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://icons.veryicon.com/png/Flag/Rounded%20World%20Flags/Turkey%20Flag.png"
              alt="Flag Turkish Vector"
              width="17"
              height="17"
            />
          </div>{" "}
          Türkçe
          {currentLng === "tu" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "ch",
            "https://icon-library.com/images/china-flag-icon/china-flag-icon-0.jpg"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://icon-library.com/images/china-flag-icon/china-flag-icon-0.jpg"
              alt="Flag China Vector"
              width="17"
              height="17"
            />
          </div>{" "}
          中文
          {currentLng === "ch" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "si",
            "https://vectorflags.s3.amazonaws.com/flags/lk-circle-01.png"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1 border border-dark rounded"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://vectorflags.s3.amazonaws.com/flags/lk-circle-01.png"
              alt="Flag Sinhala Vector"
              width="17"
              height="17"
            />
          </div>{" "}
          සිංහල
          {currentLng === "si" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "ja",
            "https://cdn.countryflags.com/thumbs/japan/flag-3d-round-250.png"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1 border border-dark rounded"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://cdn.countryflags.com/thumbs/japan/flag-3d-round-250.png"
              alt="Flag Japanese Vector"
              width="17"
              height="17"
            />
          </div>{" "}
          日本語
          {currentLng === "ja" && checkMark}
        </div>
      </NavDropdown.Item>

      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "th",
            "https://st.depositphotos.com/1575949/1356/v/450/depositphotos_13564034-stock-illustration-thailand-flag-button.jpg"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1 border border-dark rounded"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://st.depositphotos.com/1575949/1356/v/450/depositphotos_13564034-stock-illustration-thailand-flag-button.jpg"
              alt="Flag Thailand Vector"
              width="17"
              height="17"
            />
          </div>{" "}
          ประเทศไทย
          {currentLng === "th" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "pt",
            "https://icons.veryicon.com/png/Flag/Rounded%20World%20Flags/Portugal%20Flag.png"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://icons.veryicon.com/png/Flag/Rounded%20World%20Flags/Portugal%20Flag.png"
              alt="Portugal flag Image"
              height="15px"
              width="15px"
            />
          </div>{" "}
          Português
          {currentLng === "pt" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "ka",
            "https://static.vecteezy.com/system/resources/previews/004/711/564/original/georgia-3d-rounded-national-flag-button-icon-illustration-vector.jpg"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1 border border-dark rounded"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://static.vecteezy.com/system/resources/previews/004/711/564/original/georgia-3d-rounded-national-flag-button-icon-illustration-vector.jpg"
              alt="Georgia flag Image"
              height="17px"
              width="17px"
            />
          </div>{" "}
          ქართული
          {currentLng === "ka" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "cg",
            "https://cdn.countryflags.com/thumbs/montenegro/flag-3d-round-250.png"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://cdn.countryflags.com/thumbs/montenegro/flag-3d-round-250.png"
              alt="Montengero flag Image"
              height="17px"
              width="17px"
            />
          </div>{" "}
          Црна Гора
          {currentLng === "cg" && checkMark}
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() =>
          handleClicklng(
            "ar",
            "https://seeklogo.com/images/U/uae-round-flag-logo-645EA51734-seeklogo.com.png?v=638251977650000000"
          )
        }
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center justify-content-center me-1 border border-dark rounded"
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              height: "15px",
              width: "15px",
            }}
          >
            <img
              src="https://seeklogo.com/images/U/uae-round-flag-logo-645EA51734-seeklogo.com.png?v=638251977650000000"
              alt="UAE flag Image"
              height="17px"
              width="17px"
            />
          </div>{" "}
          العربية
          {currentLng === "ar" && checkMark}
        </div>
      </NavDropdown.Item> */}
    </NavDropdown>
  );
};
