import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
import step2Bg from "../../static/images/Register/step2Bg.png";
import { useTranslation } from "react-i18next"

export const RegisterStep4 = () => {
  const [visible, setVisible] = useState(false);
  const [visibleGlob, setVisibleGlob] = useState(true);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleChange = () => {
    setVisible(false);
    setVisibleGlob(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transform: `scale(${visible ? 1 : 0.8})`,
          transition: "all 1.5s",
        }}
      ></div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
        }}
      >
        <div
          className="container d-flex flex-column pt-5 position-relative"
          style={{
            flexGrow: 1,
          }}
        >
          <div
            style={{
              zIndex: 1,
              opacity: visible ? 1 : 0,
              transform: `scale(${visible ? 1 : 0.8})`,
              transition: "all 1.5s",
            }}
          >
            <div className="text-center d-none d-md-block">
              <img src={cornerLogo} alt="cornerLogo" height={100} />
            </div>

            <div className="text-center d-md-none">
              <img src={cornerLogo} alt="cornerLogo" height={70} />
            </div>

            <p className="d-flex align-items-center justify-content-center fs-2 fw-semibold text-white m-0 my-5">
              Go to your email and click on the green button for finish your signup.
            </p>

            <button
              onClick={handleChange}
              className="border-0 signin__button signin__button--size text-center w-100 d-flex align-items-center justify-content-center rounded-5 mx-auto text-decoration-none"
            >
              {t("navBar.signIn")}
            </button>
          </div>

          <div
            className="register__step2 d-flex align-items-center justify-content-center"
            style={{
              opacity: visibleGlob ? 1 : 0,
              transition: "all 1.5s",
            }}
          >
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </>
  );
};
// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
// import step2Bg from "../../static/images/Register/step2Bg.png";
// import { useTranslation } from "react-i18next"

// export const RegisterStep4 = () => {
//   const [visible, setVisible] = useState(false);
//   const [visibleGlob, setVisibleGlob] = useState(true);

//   const navigate = useNavigate();

//   const { t } = useTranslation();

//   useEffect(() => {
//     setTimeout(() => {
//       setVisible(true);
//     }, 100);
//   }, []);

//   const handleChange = () => {
//     setVisible(false);
//     setVisibleGlob(false);

//     setTimeout(() => {
//       navigate("/signin");
//     }, 1000);
//   };

//   return (
//     <>
//       <div
//         className="d-flex justify-content-between align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
//         style={{
//           borderBottom: "1px solid white",
//           zIndex: 1,
//           height: "60px",
//           opacity: visible ? 1 : 0,
//           transform: `scale(${visible ? 1 : 0.8})`,
//           transition: "all 1.5s",
//         }}
//       ></div>

//       <div
//         className="d-flex w-100 h-100 p-0 m-0"
//         style={{
//           flex: 1,
//         }}
//       >
//         <div
//           className="container d-flex flex-column pt-5 position-relative"
//           style={{
//             flexGrow: 1,
//           }}
//         >
//           <div
//             style={{
//               zIndex: 1,
//               opacity: visible ? 1 : 0,
//               transform: `scale(${visible ? 1 : 0.8})`,
//               transition: "all 1.5s",
//             }}
//           >
//             <div className="text-center d-none d-md-block">
//               <img src={cornerLogo} alt="cornerLogo" height={100} />
//             </div>

//             <div className="text-center d-md-none">
//               <img src={cornerLogo} alt="cornerLogo" height={70} />
//             </div>

//             <p className="d-flex align-items-center justify-content-center fs-2 fw-semibold text-white m-0 mt-5">
//               {t("register.step4.success")}
//             </p>

//             <p
//               className="text-center signin__text--size"
//               style={{
//                 color: "#DBB1F6",
//               }}
//             >
//               {t("register.step4.log")}
//             </p>

//             <button
//               onClick={handleChange}
//               className="border-0 signin__button signin__button--size text-center w-100 d-flex align-items-center justify-content-center rounded-5 mx-auto text-decoration-none"
//             >
//               {t("navBar.signIn")}
//             </button>
//           </div>

//           <div
//             className="register__step2 d-flex align-items-center justify-content-center"
//             style={{
//               opacity: visibleGlob ? 1 : 0,
//               transition: "all 1.5s",
//             }}
//           >
//             <img
//               src={step2Bg}
//               alt="step2Bg"
//               className="register__step2--image"
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
