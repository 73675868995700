import React, { useState } from "react";
import LayoutApp from "../../components/Application/LayoutApp";
import { StepTrade1 } from "../../components/Application/Trade/StepTrade1";
import { StepTrade2 } from "../../components/Application/Trade/StepTrade2";
import { StepTrade3 } from "../../components/Application/Trade/StepTrade3"
import { useTranslation } from "react-i18next"
import { SuccessOrder } from "../../components/Application/Trade/SuccessOrder"

export const Trade = () => {
  const { t } = useTranslation();

  const [step, setStep] = useState(1);

  return (
    <LayoutApp>
      <div className="mx-lg-2">
        <p
          className="app__home-title border-color"
          style={{
            fontFamily: "Inter 500",
          }}
        >
          {t("app.trade.title")}
        </p>

        {/* {step === 0 && <StepTrade1 setStep={setStep} />} */}

        {step === 1 && <StepTrade2 setStep={setStep} />}

        {step === 2 && <StepTrade3 setStep={setStep} />}

        {step === 3 && <SuccessOrder />}
      </div>
    </LayoutApp>
  );
};
