import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ContainerRadio from "./ContainerRadio";
import { useDispatch } from "react-redux";
import { setAnswer5 } from "../../redux/slices/auth.slice";
import { Button } from "react-bootstrap"

interface IStep5 {
  stateStep: (el: number) => void;
}

const Step5: React.FC<IStep5> = ({ stateStep }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [activAnswer, setActivAnswer] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleNextStep = () => {
    dispatch(setAnswer5(activAnswer));

    setVisible(false);

    setTimeout(() => {
      stateStep(6);
    }, 1000);
  };

  const handlechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivAnswer(event.target.value);
  };

  return (
    <div
      style={{
        zIndex: 1,
        opacity: visible ? 1 : 0,
        transform: `scale(${visible ? 1 : 0.8})`,
        transition: "all 1.5s",
      }}
    >
      <figcaption className="m-0 text-secondary">{t("form.5")}</figcaption>

      <h2 className="text-start my-3 h4">{t("form.intention")}</h2>

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.dessous")}
        value="-10000"
        name="step5"
        id="flexCheckDefault51"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.10001")}
        value="10001/30000"
        name="step5"
        id="flexCheckDefault52"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.30001")}
        value="30001/50000"
        name="step5"
        id="flexCheckDefault53"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.50001")}
        value="50001/100000"
        name="step5"
        id="flexCheckDefault54"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.100000")}
        value="+100000"
        name="step5"
        id="flexCheckDefault55"
      />

      <div className="w-100 text-center text-lg-end">
        <Button
          disabled={!activAnswer}
          type="button"
          onClick={handleNextStep}
          className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4"
        >
          {t("form.Suivant")}
        </Button>
      </div>
    </div>
  );
};

export default Step5;
