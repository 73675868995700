import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import transfersImage from "../../static/images/transfersImage.png";
import { useTranslation } from "react-i18next"

export const Transfers = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState([false, false, false, false]);
  const refs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setIsVisible((prevIsVisible) => {
              const newIsVisible = [...prevIsVisible];
              newIsVisible[index] = true;
              return newIsVisible;
            });
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    refs.current.forEach((ref) => {
      if (ref.current) {
        //@ts-ignore
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.current.forEach((ref) => {
        if (ref.current) {
          //@ts-ignore
          observer.unobserve(ref.current);
        }
      });
    };
  }, [window.scrollY]);

  return (
    <div className="container mx-auto business__banking">
      <div className="row m-0 p-0 justify-content-between">
        <div className="col-12 col-lg-6 pe-5 order-1 order-lg-0">
          <img
            src={transfersImage}
            alt="transfersImage"
            style={{ width: "100%" }}
          />
        </div>

        <div className="col-12 col-lg-6 position-relative business__transfer--padding">
          <div
            className="d-none d-md-flex align-items-center business__transfer-number w-100 position-absolute"
            style={{
              opacity: isVisible[0] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
              zIndex: 0,
            }}
            //@ts-ignore
            ref={refs.current[0]}
          >
            <div className="ms-3">03</div>

            <div
              className="ms-5 business__banking-line"
              style={{
                flex: 1,
              }}
            ></div>
          </div>

          <span
            className="business__transfer-sub position-relative"
            style={{
              opacity: isVisible[0] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
          >
            {t("business.transfers.title")}
          </span>

          <p
            className="business__transfer-title fw-bold mt-5 pt-2"
            style={{
              opacity: isVisible[1] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
              zIndex: 2,
            }}
            //@ts-ignore
            ref={refs.current[1]}
          >
            {t("business.transfers.name")}
          </p>

          <p
            className="m-0 mt-4 business__text-color"
            style={{
              opacity: isVisible[2] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[2]}
          >
            {t("business.transfers.sub")}
          </p>

          <Link
            to="/signin"
            className="text-white business__header-button rounded-5 py-3 d-flex mt-4"
            style={{
              width: "max-content",
              opacity: isVisible[3] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[3]}
          >
            {t("business.transfers.button")}
          </Link>
        </div>
      </div>
    </div>
  );
};
