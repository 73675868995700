import React, { useEffect, useRef, useState } from "react";
import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
import step2Bg from "../../static/images/Register/step2Bg.png";
import { InputPin } from "./InputPin";
import arrowBack from "../../static/icons/signIn/arrowBack.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguagesBar } from "../Navbar/LanguagesBar";

export const RegisterStep3 = () => {
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleSingIn = () => {
    setVisible(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };


  return (
    <>
      <div
        className="d-flex justify-content-end align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div className="d-flex align-items-center text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <span>{t("register.step2.haveAcc")}</span>

          <button
            onClick={handleSingIn}
            className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
        }}
      >
        <div
          className="container d-flex flex-column pt-5 position-relative"
          style={{
            flexGrow: 1,
          }}
        >
          <div
            style={{
              zIndex: 1,
              opacity: visible ? 1 : 0,
              transform: `scale(${visible ? 1 : 0.8})`,
              transition: "all 1.5s",
            }}
          >
            <div className="text-center d-none d-md-block">
              <img src={cornerLogo} alt="cornerLogo" height={100} />
            </div>

            <div className="text-center d-md-none">
              <img src={cornerLogo} alt="cornerLogo" height={70} />
            </div>

            <p className="fs-2 fw-semibold text-white text-center m-0 mb-1 mt-5">
            {t("register.step3.title")}
            </p>
          </div>

          <div className="register__step2 d-flex align-items-center justify-content-center">
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

// import React, { useEffect, useRef, useState } from "react";
// import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
// import step2Bg from "../../static/images/Register/step2Bg.png";
// import { InputPin } from "./InputPin";
// import arrowBack from "../../static/icons/signIn/arrowBack.svg";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { LanguagesBar } from "../Navbar/LanguagesBar";

// interface IRegisterStep3 {
//   setStep: (value: number) => void;
// }

// export const RegisterStep3: React.FC<IRegisterStep3> = ({ setStep }) => {
//   const [pinCode, setPinCode] = useState("");
//   const [resendTime, setResendTime] = useState(60);
//   const [visible, setVisible] = useState(false);

//   const resendTimeRef = useRef(resendTime);

//   const navigate = useNavigate();

//   const { t } = useTranslation();

//   useEffect(() => {
//     setTimeout(() => {
//       setVisible(true);
//     }, 100);
//   }, []);

//   useEffect(() => {
//     resendTimeRef.current = resendTime;
//   }, [resendTime]);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setResendTime((prevTime) => prevTime - 1);

//       if (resendTimeRef.current < 1) {
//         clearInterval(timer);
//         console.log("Timer finished");
//       }
//     }, 1000);

//     return () => clearInterval(timer);
//   }, []);

//   const handleSubmit = () => {
//     // if (pinCode.length === 6) {
//     //   console.log(pinCode, "<== pinCode");

//     //   setVisible(false);

//     //   setTimeout(() => {
//     //     setStep(4);
//     //   }, 1500);
//     // } else {
//     //   alert("Invalid pin code");
//     // }

//     setVisible(false);

//     setTimeout(() => {
//       setStep(4);
//     }, 1500);
//   };

//   const handleSingIn = () => {
//     setVisible(false);

//     setTimeout(() => {
//       navigate("/signin");
//     }, 1000);
//   };

//   const handlePrevStep = () => {
//     setVisible(false);

//     setTimeout(() => {
//       setStep(2);
//     }, 1000);
//   };

//   return (
//     <>
//       <div
//         className="d-flex justify-content-between align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
//         style={{
//           borderBottom: "1px solid white",
//           zIndex: 1,
//           height: "60px",
//           opacity: visible ? 1 : 0,
//           transition: "all 1.5s",
//         }}
//       >
//         <div
//           className="btn text-white text-decoration-none d-flex align-items-center p-0"
//           onClick={handlePrevStep}
//         >
//           <img src={arrowBack} alt="arrowBack" height={12} />
//           <span className="ms-sm-4 ps-2 signin--text-size">
//             {t("logIn.back")}
//           </span>
//         </div>

//         <div className="d-flex align-items-center text-white signin--text-size">
//           <LanguagesBar classProp="me-3" />

//           <span>{t("register.step2.haveAcc")}</span>

//           <button
//             onClick={handleSingIn}
//             className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
//           >
//             {t("register.step1.logIn")}
//           </button>
//         </div>
//       </div>

//       <div
//         className="d-flex w-100 h-100 p-0 m-0"
//         style={{
//           flex: 1,
//         }}
//       >
//         <div
//           className="container d-flex flex-column pt-5 position-relative"
//           style={{
//             flexGrow: 1,
//           }}
//         >
//           <div
//             style={{
//               zIndex: 1,
//               opacity: visible ? 1 : 0,
//               transform: `scale(${visible ? 1 : 0.8})`,
//               transition: "all 1.5s",
//             }}
//           >
//             <div className="text-center d-none d-md-block">
//               <img src={cornerLogo} alt="cornerLogo" height={100} />
//             </div>

//             <div className="text-center d-md-none">
//               <img src={cornerLogo} alt="cornerLogo" height={70} />
//             </div>

//             <p className="d-flex align-items-center justify-content-center fs-2 fw-semibold text-white m-0 mb-1 mt-5">
//               {t("register.step3.confirm")}
//             </p>

//             <p
//               className="text-center signin__text--size"
//               style={{
//                 color: "#DBB1F6",
//               }}
//             >
//               {t("register.step3.code")}
//             </p>

//             <div className="mx-auto signin__button--size">
//               <InputPin setPinCode={setPinCode} />
//             </div>

//             <p className="text-white text-center my-4 mx-auto signin__button--size signin__text--size">
//               {t("register.step3.receive.1")}{" "}
//               <button className="bg-transparent border-0 text-white text-decoration-underline">
//                 {t("register.step3.receive.2")}
//               </button>{" "}
//               {t("register.step3.receive.3")} {resendTime}s.
//             </p>

//             <div
//               className="signin__button signin__button--size text-center border-0 w-100 d-flex align-items-center justify-content-center rounded-5 mx-auto"
//               onClick={handleSubmit}
//               style={{
//                 cursor: "pointer",
//               }}
//             >
//               {t("register.step2.button")}
//             </div>
//           </div>

//           <div className="register__step2 d-flex align-items-center justify-content-center">
//             <img
//               src={step2Bg}
//               alt="step2Bg"
//               className="register__step2--image"
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
