import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Field, Formik, Form } from "formik";
import selectArrow from "../../static/icons/app/Profile/selectArrow.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import config from "../../config"

export const ContactForm = () => {
  const location = useLocation();

  const { t } = useTranslation();

  const [loader, setLoader] = useState(false);
  const [errorSend, setErrorSend] = useState(false);
  const [successSend, setSuccessSend] = useState(false);

  const userEmail = useSelector(
    ({ authData }: IRootState) => authData.fetchAuth.email
  );

  const schema = yup.object().shape({
    topic: yup.string().required("Required field"),
    email: yup.string().email().required("Required field"),
    message: yup.string().required("Required field"),
  });

  interface IFormValues {
    topic: string;
    email: string;
    message: string;
  }

  const handleSubmit = (values: IFormValues, { resetForm }: any) => {
    setLoader(true);
    setErrorSend(false);
    setSuccessSend(false);

    const { email, message, topic } = values;

    axios
      .post(`${config.apiURI}/email/contactForm`, {
        email,
        title: topic,
        content: message,
      })
      .then(() => {
        setLoader(false);

        setSuccessSend(true);

        resetForm();
      })
      .catch(() => {
        setLoader(false);

        setErrorSend(true);
      });
  };

  return (
    <div className="app__card rounded-4 px-4 py-2 pt-xl-4 pb-4 mb-1">
      <p className="app__payment-step3--title home__background-text mt-5">{t("app.contact.title")}</p>

      <div
        className="mb-4"
        style={{
          border: "1px solid #D4D4D4",
        }}
      />

      <p
        className="app__payment--method home__background-text m-0 mt-3"
        style={{
          fontFamily: "Inter, sans-serif",
        }}
      >
        {t("app.contact.sub")}
      </p>

      <Formik
        initialValues={{
          topic: "",
          email: location.pathname === "/contact" ? userEmail : "",
          message: "",
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, handleChange, handleBlur }) => (
          <Form className="d-flex flex-column">
            <div className="row p-0 m-0 mt-4 pt-2 pb-xl-5">
              <div className="col-12 col-lg-6 p-0">
                <select
                  name="topic"
                  value={values.topic}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`w-100 bg-transparent px-3 py-2 mt-3 ${errors.topic && touched.topic ? "border border-danger" : ""}`}
                  style={{
                    border: `1px solid ${!values.topic ? "#D4D4D4" : "black"}`,
                    borderRadius: "11px",
                    outline: "none",
                    appearance: "none",
                    backgroundImage: `url(${selectArrow})`,
                    backgroundPosition: "right 10px center",
                    backgroundRepeat: "no-repeat",
                    color: !values.topic ? "#adb5bd" : "black",
                  }}
                >
                  <option value="" label={t("app.contact.topic.title")}>
                    {t("app.contact.topic.title")}{" "}
                  </option>
                  <option
                    value="Payment issue"
                    label={t("app.contact.topic.payment")}
                  >
                    {" "}
                    {t("app.contact.topic.payment")}
                  </option>
                  <option
                    value="Bug report"
                    label={t("app.contact.topic.report")}
                  >
                    {t("app.contact.topic.report")}
                  </option>
                  <option
                    value="Question to the team"
                    label={t("app.contact.topic.question")}
                  >
                    {t("app.contact.topic.question")}
                  </option>
                  <option
                    value="Business inquiry"
                    label={t("app.contact.topic.business")}
                  >
                    {t("app.contact.topic.business")}
                  </option>
                  <option value="Other" label={t("app.contact.topic.other")}>
                    {t("app.contact.topic.other")}
                  </option>
                </select>

                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder={t("app.contact.email")}
                  className={`w-100 bg-transparent px-3 py-2 mt-3 ${errors.email && touched.email ? "border border-danger" : ""}`}
                  style={{
                    border: `1px solid ${!values.email ? "#D4D4D4" : "black"}`,
                    borderRadius: "11px",
                    outline: "none",
                  }}
                  disabled={location.pathname === "/contact"}
                />

                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  placeholder={t("app.contact.message")}
                  value={values.message}
                  onChange={handleChange}
                  className={`w-100 bg-transparent px-3 py-2 mt-3 custom-placeholder ${errors.message && touched.message ? "border border-danger" : ""}`}
                  style={{
                    border: `1px solid ${!values.message ? "#D4D4D4" : "black"}`,
                    borderRadius: "11px",
                    outline: "none",
                  }}
                />

                <div className="row p-0 m-0">
                  <button
                    type="submit"
                    className="d-block col-12 col-lg-6 col-xl-4 text-white border-0 d-flex justify-content-center align-items-center py-2 app__payment-step3--button mt-4"
                    style={{
                      backgroundColor: "#DBB1F6",
                      borderRadius: "26px",
                    }}
                  >
                    {loader ? (
                      <div
                        className="spinner-border text-light"
                        role="status"
                        style={{
                          height: "27px",
                          width: "27px",
                        }}
                      />
                    ) : (
                      t("app.contact.button")
                    )}
                  </button>

                  {errorSend && (
                    <p
                      className="text-danger text-center text-lg-start"
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {t("errors.someWrong")}
                    </p>
                  )}

                  {successSend && (
                    <p
                      className="text-success text-center text-lg-start"
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Successful sending.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
