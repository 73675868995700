import React, { useEffect, useState } from "react";
import LayoutApp from "../../components/Application/LayoutApp"
import SymbolInfo from "../../components/CryptoInfo/symbolInfo";
import AdvancedChart from "../../components/CryptoInfo/advancedChart";
import TechnicalAnalysisWidget from "../../components/CryptoInfo/technicalAnalysis";
import TopStories from "../../components/CryptoInfo/topStories";
import MarketCrypto from "../../components/CryptoInfo/marketCrypto";
import { useParams } from "react-router-dom";
import CompanyProfileWidget from "../../components/CryptoInfo/companyProfile"
import { SymbolOverview } from "react-ts-tradingview-widgets"

const CryptoInfoApp = () => {
  const { cryptoName } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
  }, [cryptoName]);

  return (
    <LayoutApp>
      <div className="container my-5 p-0 px-lg-3">
        <div className="row p-0 m-0">

          <div className="col-12 p-0">
            <SymbolInfo />

            <AdvancedChart />

            <CompanyProfileWidget />

            <div className="row mt-3 pt-2">
              <div className="col-12 col-md-6" style={{ height: "700px" }}>
                <TechnicalAnalysisWidget />
              </div>

              <div className="col-12 col-md-6">
                <TopStories />
              </div>
            </div>

            <MarketCrypto />
          </div>
        </div>
      </div>
    </LayoutApp>
  );
};

export default CryptoInfoApp;
