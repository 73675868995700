import React, { useState, useEffect } from "react";
import ContainerRadio from "./ContainerRadio";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setAnswer1 } from "../../redux/slices/auth.slice";

interface IStep1 {
  stateStep: (value: number) => void;
}

const Step1: React.FC<IStep1> = ({ stateStep }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [activAnswer, setActivAnswer] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleNextStep = () => {
    dispatch(setAnswer1(activAnswer));

    setVisible(false);

    setTimeout(() => {
      stateStep(2);
    }, 1000);
  };

  const handlechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivAnswer(event.target.value);
  };

  return (
    <div
      style={{
        zIndex: 1,
        opacity: visible ? 1 : 0,
        transform: `scale(${visible ? 1 : 0.8})`,
        transition: "all 1.5s",
      }}
    >
      <figcaption className=" m-0 text-secondary">{t("form.1")}</figcaption>

      <h2 className="text-start my-3 h4">{t("form.origine")}</h2>

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Salaire")}
        value="Salary"
        name="step1"
        id="radio1"
      />

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Économie")}
        value="Saving"
        name="step1"
        id="radio2"
      />

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.investissements")}
        value="EarningsFromPreviousInvestments"
        name="step1"
        id="radio3"
      />

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Retraite")}
        value="Retirement"
        name="step1"
        id="radio4"
      />

      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Crédit")}
        value="BankCredit"
        name="step1"
        id="radio5"
      />

      <div className="w-100 text-center text-lg-end">
        <Button
          disabled={!activAnswer}
          type="button"
          onClick={handleNextStep}
          className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4"
        >
          {t("form.Suivant")}
        </Button>
      </div>
    </div>
  );
};

export default Step1;
