import React from "react";
import { ExchangeTrade } from "./ExchangeTrade";
import { Payment } from "./Payment";

interface IStepTrade2 {
  setStep: (value: number) => void;
}

export const StepTrade2: React.FC<IStepTrade2> = ({ setStep }) => {
  return (
    <div className="row p-lg-0 m-0 ps-0 px-lg-2 ps-xl-0 mb-5 mb-xl-4">
      <div className="col-12 col-xl-4 p-0 pe-xl-4 mb-2 mb-xl-0">
        <ExchangeTrade />
      </div>

      <div className="col-12 col-xl-8 p-0">
        <Payment setStep={setStep} />
      </div>
    </div>
  );
};
