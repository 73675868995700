import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import smartphoneHome from "../../static/images/smartphoneHome.png";
import websiteMobile from "../../static/images/websiteMobile.png";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import { useTranslation } from "react-i18next"

interface IOpenAccount {
  setShow: (value: boolean) => void;
}

export const OpenAccount: React.FC<IOpenAccount> = ({ setShow }) => {
  const { t } = useTranslation();

  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);

  const { colorFirst, colorTextSecond } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const componentRef1 = useRef(null);
  const componentRef2 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (componentRef2.current) {
        const componentTopOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef2.current.getBoundingClientRect().top) /
          window.innerHeight;

        const componentBottomOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef2.current.getBoundingClientRect().bottom) /
          window.innerHeight;

        setIsVisible2(componentBottomOffset < 0.6 && componentTopOffset > 0.15);
      }

      if (componentRef1.current) {
        const componentTopOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef1.current.getBoundingClientRect().top) /
          window.innerHeight;

        const componentBottomOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef1.current.getBoundingClientRect().bottom) /
          window.innerHeight;

        setIsVisible1(componentBottomOffset < 0.6 && componentTopOffset > 0.15);
        setShow(componentBottomOffset < 0.6 && componentTopOffset > 0.15);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`row w-100 mx-auto`}
      style={{ maxWidth: "1320px", margin: "100px 0" }}
    >
      <div
        className={`col-12 col-sm-5 order-1 order-sm-0 animated-component ${isVisible1 ? "visible" : "hidden"}`}
        ref={componentRef1}
      >
        <p className="fw-bold fs-1 m-0 lh-sm" style={{ maxWidth: "450px" }}>
          {t("personal.openAccount.title")}
        </p>

        <p
          className="fs-5 mt-4"
          style={{ maxWidth: "500px", color: colorTextSecond }}
        >
          {t("personal.openAccount.sub")}
        </p>

        <div className="row flex-column flex-lg-row mx-0 my-5">
          <Link
            to="/"
            className="col-10 col-sm-9 col-md-7 col-lg-5 mx-auto mx-sm-0 navbarCont__button me-lg-3 fw-bold text-dark text-decoration-none py-3 text-center rounded-5"
            style={{ backgroundColor: colorFirst }}
          >
            {t("personal.header.buttons.open")}
          </Link>
        </div>

        <div className="row flex-row mx-0">
          <Link to="/" className="col-5 col-lg-4 me-3">
            <img
              src="https://wise.com/public-resources/assets/marketing-components/app-store-badges/en.svg"
              alt="appStore"
              style={{ width: "100%", height: "100%" }}
            />
          </Link>

          <Link to="/" className="col-5 col-lg-4 me-3">
            <img
              src="https://wise.com/public-resources/assets/marketing-components/google-play-store-badges/en.svg"
              alt="googlePlay"
              style={{ height: "100%" }}
            />
          </Link>
        </div>
      </div>

      <div
        className={`col-12 col-sm-7 position-relative px-0 animated-component ${isVisible2 ? "visible" : "hidden"}`}
        style={{ marginBottom: "130px" }}
        ref={componentRef2}
      >
        <div className="d-flex justify-content-sm-end">
          <img
            src={websiteMobile}
            alt="cover-img"
            style={{ width: "85%", height: "100%" }}
          />
        </div>

        <div
          className="position-absolute img-container-phone"
          style={{ width: "35%" }}
        >
          <img src={smartphoneHome} alt="cover-img" style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
};
