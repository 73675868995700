import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LanguagesBar } from "./LanguagesBar";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import cornerLogoBlack from "../../static/icons/CornerSwapFinal.png";
import cornerLogoWhite from "../../static/icons/app/navbar/CornerSwapDark.png";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import { Offcanvas } from "react-bootstrap";
import { ThemeContext } from "../../providers/ThemeProvider";
import { DarkModeSwitch } from "react-toggle-dark-mode";

const blogLink = process.env.REACT_APP_LINK_BLOG;

const Navbarr = () => {
  const { colorFirst, navHomeHeigth } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const location = useLocation();

  const abbrLang = localStorage.getItem("lng");

  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const [theme, setTheme] = useContext(ThemeContext);

  const [isDarkMode, setDarkMode] = useState(false);

  useEffect(() => {
    setDarkMode(theme !== "light");
  }, []);

  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);

    setTheme(checked ? "dark" : "light");
  };

  return (
    <div
      className="navbarCont d-flex position-fixed"
      style={{
        zIndex: 1000,
        // backgroundColor: "white",
        width: "100%",
        height: `${navHomeHeigth}px`,
      }}
    >
      <div
        className="d-flex w-100 mx-auto"
        style={{ maxWidth: "1320px", height: `${navHomeHeigth}px` }}
      >
        <div className="d-none d-lg-flex justify-content-between align-items-center w-100">
          <Link to="/">
            <img
              src={theme === "light" ? cornerLogoBlack : cornerLogoWhite}
              alt="cornerSwapLogo"
              style={{
                height: "50px",
              }}
            />
          </Link>

          <Navbar className="d-flex align-items-center w-100">
            <Nav className="text-start me-3 w-100 d-flex align-items-center">
              <Link
                to="/"
                className={`d-none d-md-flex ms-4 navbarCont__button fw-bold text-decoration-none px-3 py-1 text-center rounded-5 ${location.pathname !== "/" ? "navbarCont__button--silver" : "text-dark"}`}
                style={{
                  backgroundColor: location.pathname === "/" ? colorFirst : "",
                }}
              >
                {t("navBar.personal")}
              </Link>
              <Link
                to="/business"
                className={`d-none d-md-flex fw-bold navbarCont__button text-decoration-none py-1 px-3 ms-1 text-center rounded-5 ${location.pathname !== "/business" ? "navbarCont__button--silver" : "text-dark"}`}
                style={{
                  backgroundColor:
                    location.pathname === "/business" ? colorFirst : "",
                }}
              >
                {t("navBar.business")}
              </Link>
              <Link
                to="/onramp"
                className={`d-none d-md-flex fw-bold  navbarCont__button text-decoration-none py-1 px-3 ms-1 text-center rounded-5 ${location.pathname !== "/onramp" ? "navbarCont__button--silver" : "text-dark"}`}
                style={{
                  backgroundColor:
                    location.pathname === "/onramp" ? colorFirst : "",
                }}
              >
                {t("navBar.onRamp")}
              </Link>
            </Nav>

            <Nav className="d-flex justify-content-end w-100 h-100 pe-2">
              {!!blogLink?.length && (
                <Link
                  to={blogLink}
                  className="ms-2 navbarCont__button--silver fw-bold navbarCont__button text-decoration-none py-1 px-3 text-center rounded-5"
                >
                  {t("navBar.blog")}
                </Link>
              )}

              <div className="d-flex fw-bold align-items-center">
                <LanguagesBar
                  classProp={
                    "p-0 pe-1 text-dark drop-language d-flex justify-content-end align-items-center"
                  }
                />
                <span className="m-0 p-0 fw-bold navbarCont__button">
                  {abbrLang ? abbrLang.toUpperCase() : "EN"}
                </span>
              </div>
              <Link
                className="ms-2 navbarCont__button--silver fw-bold navbarCont__button text-decoration-none py-1 px-2 px-lg-3 text-center rounded-5"
                to="/signin"
              >
                {t("navBar.signIn")}
              </Link>
              <Link
                to="/signup"
                // to={signUpLink ? signUpLink : "/"}
                className="ms-2 navbarCont__button fw-bold text-dark text-decoration-none py-1 px-3 text-center rounded-5"
                style={{ backgroundColor: colorFirst }}
              >
                {t("navBar.register")}
              </Link>
            </Nav>
          </Navbar>
        </div>

        <div className="d-flex d-lg-none w-100 justify-content-between align-items-center px-2">
          <Link to="/">
            <img
              src={theme === "light" ? cornerLogoBlack : cornerLogoWhite}
              alt="cornerSwapLogo"
              style={{
                height: "50px",
              }}
            />
          </Link>

          <div className="d-flex align-items-center">
            <Link
              to="/signin"
              className="home__background-text text-decoration-none fw-bold p-0 pt-1 me-4"
            >
              {t("navBar.signIn")}
            </Link>

            <div
              className="navbarCont1MobileBtn"
              onClick={() => setShowMenu(true)}
              style={{ zIndex: 1001 }}
            >
              <span style={{ backgroundColor: theme === "light" ? "black" : "white" }}></span>
              <span style={{ backgroundColor: theme === "light" ? "black" : "white" }}></span>
              <span style={{ backgroundColor: theme === "light" ? "black" : "white" }}></span>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={showMenu}
        onHide={() => setShowMenu(false)}
        placement="end"
        responsive="lg"
        className="d-lg-none home__background"
        style={{
          transition: "all .3s ease",
        }}
        id={`offcanvasNavbar-expand-xl`}
        aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
      >
        <Offcanvas.Header closeButton >
          <Offcanvas.Title>
            <Link
              style={{
                height: "30px",
              }}
              to="/"
            >
              <img
                src={theme === "light" ? cornerLogoBlack : cornerLogoWhite}
                alt="cornerSwapLogo"
                style={{
                  height: "50px",
                }}
              />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="d-flex flex-column">
          <Navbar className="d-flex flex-column align-items-start pb-4 w-100">
            <Nav>
              <Link
                to="/"
                className={`d-flex navbarCont__button--silver fw-bold home__background-text text-decoration-none px-3 py-1 text-center rounded-5 ${location.pathname === "/" && "text-dark"}`}
                style={{ backgroundColor: location.pathname === "/" ? colorFirst : "" }}
              >
                {t("navBar.personal")}
              </Link>

              <Link
                to="/business"
                className={`d-flex navbarCont__button--silver fw-bold home__background-text text-decoration-none py-1 px-3 text-center rounded-5 ${location.pathname === "/business" && "text-dark"}`}
                style={{ backgroundColor: location.pathname === "/business" ? colorFirst : "" }}
              >
                {t("navBar.business")}
              </Link>
            </Nav>{" "}
            <Link
              to="/onramp"
              className={`mt-3 d-flex navbarCont__button--silver fw-bold home__background-text text-decoration-none px-3 py-1 text-center rounded-5 ${location.pathname === "/onramp" && "text-dark"}`}
              style={{ backgroundColor: location.pathname === "/onramp" ? colorFirst : "" }}
            >
              {t("navBar.onRamp")}
            </Link>

            {!!blogLink?.length && (
              <Link
                to={blogLink}
                className="mt-3 d-flex navbarCont__button--silver fw-bold home__background-text text-decoration-none py-1 px-3 pb-2 text-center rounded-5"
              >
                {t("navBar.blog")}
              </Link>
            )}

            <div className="d-flex w-100 justify-content-between mt-5 pt-5 py-1 px-3">
              <p className="m-0 p-0 fw-bold home__background-text">
                {abbrLang ? abbrLang.toUpperCase() : "EN"}
              </p>

              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "25px",
                  width: "25px",
                }}
              >
                <LanguagesBar classProp="" />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between py-1 px-3">
              <p className="m-0 p-0 fw-bold home__background-text">{t("navBar.theme")}</p>

              <div
                className="border d-flex justify-content-center align-items-center rounded-5"
                style={{
                  height: "25px",
                  width: "25px",
                  background: isDarkMode ? "rgba(63, 63, 63, 1)" : "white",
                  transition: "all 1s ease",
                }}
              >
                <DarkModeSwitch
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  size={16}
                />
              </div>
            </div>
          </Navbar>

          <div className="w-100 d-flex flex-column align-items-center mt-auto px-2">
            <Link
              className="w-100 d-flex justify-content-center navbarCont__button fw-bold text-dark text-decoration-none py-2 text-center rounded-5"
              style={{ backgroundColor: colorFirst }}
              to="/signin"
            >
              {t("navBar.signIn")}
            </Link>

            <Link
              className="w-100 d-flex justify-content-center navbarCont__button--silver fw-bold home__background-text text-decoration-none py-2 text-center rounded-5 mt-3"
              to="/signup"
            >
              {t("navBar.register")}
            </Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Navbarr;
