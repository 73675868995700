import React, { useEffect } from "react";
import { Layout } from "../components/Layout";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div className="container py-5">
        <div className="d-flex justify-content-center flex-wrap px-2">
          <Link
            to="/policy"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
          >
            Privacy Policy
          </Link>

          <Link
            to="/security"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Security
          </Link>

          <Link
            to="/amlkyc"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            AML/KYC
          </Link>

          <Link
            to="/conditions"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Terms and conditions
          </Link>
        </div>
        <p className="privacy__title m-0 mt-5">Privacy Policy</p>
        <p className="privacy__text mt-4">Last Updated: October 1, 2024</p>
        <p className="privacy__text">Welcome to Cornerswap.</p>
        <p className="privacy__text">
          This Privacy Policy outlines how Cornerswap LLC (TIN: 521397835)
          (thereby referred to as: “We”, “Our”, and “Us”) gathers, collects,
          processes, and retains your Personal Data, when You use our
          cryptocurrency trading platform (cornerswap.io). In this Policy,
          “Personal Data” means any information relating to You, as an
          identifiable person.
        </p>
        <p className="privacy__text">
          By accessing or using the Service, you agree to this Privacy Policy.
          If you do not agree with this Privacy Policy, please do not use our
          Service.
        </p>
        <p className="privacy__text">
          We may amend this Privacy Policy at any time by posting the amended
          version on the Site including the effective date of the amended
          version. The current version of the Privacy Policy, as published on
          the Site, is applicable.
        </p>
        <p className="privacy__subtitle mt-5">Your Information</p>
        <p className="privacy__text">
          When visiting our website (
          <Link to="https://cornerswap.io">www.cornerswap.io</Link>) and a blog
          page (
          <Link to="https://blog.cornerswap.io">www.blog.cornerswap.io</Link>)
          we collect various types of information in connection with the
          activities described below, including:
        </p>
        <p className="privacy__text">
          Personal Information. When registering for an account, we may collect
          personal information, such as
        </p>
        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Personal Information: </span>
            When you register for an account, we may collect personal
            information, such as your name, email address, phone number, and
            identification documents.
          </li>

          <li>
            <span className="fw-bold">Transaction Information: </span>
            We collect details of the cryptocurrency transactions you execute
            via our platform, including the types of cryptocurrencies traded,
            transaction amounts, wallet address, and dates.
          </li>

          <li>
            <span className="fw-bold">Technical Information: </span>
            We collect technical information about your use of the Service, such
            as IP address, browser type, operating system, and device
            identifiers.
          </li>

          <li>
            <span className="fw-bold">Usage Information: </span>
            We collect information about how you interact with the Service, such
            as pages visited, time spent on the site, and links clicked.
          </li>
        </ul>
        <div className="privacy__text">
          <p>We use the information we collect to:</p>

          <ul>
            <li>Provide, operate, and maintain our Service.</li>
            <li>
              Process and complete transactions, and send related information,
              including transaction confirmations.
            </li>
            <li>
              Communicate with you, including to send you updates, security
              alerts, and support messages.
            </li>
            <li>
              Monitor and analyze usage and trends to improve your experience.
            </li>
            <li>
              Prevent, detect, and address fraud and other illegal activity.
            </li>
            <li>
              Comply with legal obligations and enforce our terms and policies.
            </li>
          </ul>
        </div>
        <p className="privacy__subtitle mt-5">Security of Your Information</p>
        <p className="privacy__text">
          We implement appropriate technical and organizational measures to
          protect the information we collect and store from unauthorized access,
          disclosure, alteration, and destruction. However, no internet or email
          transmission is ever fully secure or error-free. Please keep this in
          mind when disclosing any information to us via the Internet.
        </p>
        <p className="privacy__text">
          Our Service may contain links to third-party websites or services.
          This information does not apply to third-party websites or the
          corresponding links given on the Site. We assume no responsibility and
          liability for these.
        </p>

        <p className="privacy__subtitle mt-5">Your Choices and Rights</p>
        <p className="privacy__text">
          Depending on your jurisdiction, you may have certain rights regarding
          your personal information, including:
        </p>
        <ul className="privacy__text">
          <li>Right to access..</li>

          <li>Right to rectification.</li>

          <li>Right to be forgotten. </li>

          <li>Right to restriction of processing.</li>

          <li>Right to object.</li>

          <li>Right to withdraw data processing consent. </li>
        </ul>
        <p className="privacy__subtitle mt-5">Right to access.</p>
        <p className="privacy__text">
          You have the right to obtain free information about your personal data
          processed at any time and a copy of this information. Furthermore, you
          will have access to the following information:
        </p>
        <ul className="privacy__text">
          <li>the purposes of the processing; </li>

          <li>the categories of personal data concerned; </li>

          <li>
            where possible, the envisaged period for which the personal data
            will be processed, or,
          </li>

          <li>
            if not possible, the criteria used to determine that period; the
            existence of the right to request from us rectification or erasure
            of personal data, or restriction of processing of personal data
            concerning you, or to object to such processing; the existence of
            the right to lodge a complaint with a supervisory authority;
          </li>
          <li>
            where the personal data are not collected directly from you, any
            available information as to their source; and the existence of
            automated decision-making, including profiling, and, at least in
            those cases, meaningful information about the logic involved, as
            well as the significance and envisaged consequences of such
            processing for you.
          </li>
        </ul>
        <p className="privacy__subtitle mt-5">Right to rectification.</p>
        <p className="privacy__text">
          You have the right to request that inaccurate personal data be
          corrected without undue delay. Additionally, if the data is
          incomplete, you can provide supplementary information to complete it.
        </p>
        <p className="privacy__subtitle mt-5">Right to be forgotten. </p>
        <p className="privacy__text">
          You may request that we delete your Personal Data if it is no longer
          necessary for the purpose it was collected or processed, or if the
          processing was unlawful. We are obligated to erase your data prompt
          unless there are legal grounds for data retention.
        </p>
        <p className="privacy__subtitle mt-5">
          Right to restriction of processing.{" "}
        </p>
        <p className="privacy__text">
          You may request to restrict the processing of your data if its
          accuracy is contested, the processing is unlawful, or you need the
          data for legal claims. While processing is restricted, your data will
          only be used for limited purposes.
        </p>
        <p className="privacy__subtitle mt-5">Right to object. </p>
        <p className="privacy__text">
          You have the right to object to the processing of your Personal Data,
          based on your particular, individual situation. The processing stops
          as per your request unless there are overriding legitimate grounds or
          legal claims, that justify the continuation of data processing.
        </p>
        <p className="privacy__subtitle mt-5">
          Right to withdraw data processing consent.
        </p>
        <p className="privacy__text">
          You have the right to withdraw your consent to the processing of your
          personal data at any time.
        </p>
        <p className="privacy__text">
          To exercise these rights, please contact us at support@cornerswap.io.
          We will respond to your request by applicable law.
        </p>
        <p className="privacy__subtitle mt-5">Data Retention</p>
        <p className="privacy__text">
          We retain your personal information for as long as necessary to
          provide our Service, comply with legal obligations, resolve disputes,
          and enforce our agreements. The retention period will vary depending
          on the type of information and the purpose for which it was collected.
          After the period the personal data will be deleted.
        </p>
        <p className="privacy__subtitle mt-5">Changes to This Privacy Policy</p>
        <p className="privacy__text">
          We may update this Privacy Policy from time to time. If we make any
          material changes, we will notify you by updating the date at the top
          of this policy and, in some cases, providing you with additional
          notice (such as by adding a statement to our homepage or sending you a
          notification). We encourage you to review this Privacy Policy
          periodically to stay informed about our information practices.
        </p>
        <p className="privacy__subtitle">Contact Us</p>
        <p className="privacy__text">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:{" "}
          <span className="fw-bold">office@cornerswap.io</span>
        </p>
        <p className="privacy__text">
          By using our Service, you acknowledge that you have read and
          understood this Privacy Policy. Thank you for trusting Cornerswap with
          your personal information.
        </p>
      </div>
    </Layout>
  );
};
