import React, { useEffect } from "react";
import { Layout } from "../components/Layout";
import { Header } from "../components/OnRamp/Header";
import { WhyCorner } from "../components/OnRamp/WhyCorner";
import { Potential } from "../components/OnRamp/Potential";
import { Integration } from "../components/OnRamp/Integration";

export const OnRamp = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  return (
    <Layout>
      <Header />

      <WhyCorner />

      <Potential />

      <Integration />
    </Layout>
  );
};
