import React, { useContext, useEffect, useState } from "react";
import Navbarr from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { IRootState } from "../redux/store/store";
import { useSelector } from "react-redux";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { ThemeContext } from "../providers/ThemeProvider";
import { useLocation } from "react-router-dom"

export const Layout = ({ children }: any) => {
  const { colorGradient1, colorGradient2, navHomeHeigth } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const location = useLocation();

  const [theme, setTheme] = useContext(ThemeContext);

  const [isDarkMode, setDarkMode] = useState(false);

  useEffect(() => {
    setDarkMode(theme !== "light");
  }, []);

  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);

    setTheme(checked ? "dark" : "light");
  };

  return (
    <div
      className="d-flex flex-column justify-content-between w-100 m-0 p-0 position-relative"
      style={{ minHeight: "100vh", height: "100%" }}
    >
      <Navbarr />

      <div
        className={`w-100 m-0 p-0 ${location.pathname !== "/" && "home__background"}`}
        style={{
          height: `${navHomeHeigth}px`,

          background: `linear-gradient(180deg, ${colorGradient1}, ${colorGradient2})`,
        }}
      ></div>

      <div
        className={`mx-auto w-100 m-0 p-0 ${location.pathname !== "/" && "home__background"}`}
        style={{
          height: "100%",
          flex: "1",
        }}
      >
        {children}
      </div>

      <div
        className="border d-none d-lg-flex justify-content-center align-items-center rounded-5 position-fixed me-1 me-md-3"
        style={{
          height: "36px",
          width: "36px",
          right: 0,
          bottom: 20,
          zIndex: "9999",
          background: isDarkMode ? "rgba(63, 63, 63, 1)" : "white",
          transition: 'all 1s ease'
        }}
      >
        <DarkModeSwitch
          checked={isDarkMode}
          onChange={toggleDarkMode}
          size={16}
        />
      </div>

      <Footer />
    </div>
  );
};
