import React, { useContext } from "react";
import { useParams } from "react-router-dom";
//@ts-ignore
import { CompanyProfile } from "react-ts-tradingview-widgets";
import { ThemeContext } from "../../providers/ThemeProvider"

const CompanyProfileWidget = () => {
  const { cryptoName } = useParams();

  const [theme] = useContext(ThemeContext);

  return (
    <div className="pt-1 pt-md-2 mt-3">
      <div
        className="rounded-3 border-color2"
        style={{
          height: "380px",
          overflow: "hidden",
          pointerEvents: "none",
        }}
      >
        <CompanyProfile
          colorTheme={theme}
          height={380}
          width="100%"
          symbol={cryptoName?.slice(0, -3)}
        ></CompanyProfile>
      </div>
    </div>
  );
};

export default CompanyProfileWidget;
