import React, { useEffect } from "react";
import LayoutApp from "../../components/Application/LayoutApp";
import * as yup from "yup";
import { Field, Formik, Form } from "formik";
import selectArrow from "../../static/icons/app/Profile/selectArrow.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/config"
import { useDispatch } from "react-redux"
import { setLangValue } from "../../redux/slices/language.slice"
import { ContactForm } from "../../components/Application/ContactForm"

export const ContactUs = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    const lngStorage = localStorage.getItem("lng");

    if (lngStorage) {
      i18n.changeLanguage(lngStorage);
      dispatch(setLangValue(lngStorage));
    }
  }, []);

  return (
    <LayoutApp>
      <ContactForm />
    </LayoutApp>
  );
};
