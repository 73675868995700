import React, { useContext } from "react";
import { ThemeContext } from "../../providers/ThemeProvider";
import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";

const MarketCrypto = () => {
  const [theme] = useContext(ThemeContext);

  return (
    <div className="mt-3 rounded-2 app__card border-color2">
      <CryptoCurrencyMarket
        colorTheme={theme}
        width="100%"
        height={660}
      ></CryptoCurrencyMarket>
    </div>
  );
};

export default MarketCrypto;
