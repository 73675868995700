import { createSlice } from "@reduxjs/toolkit";

export interface IStyleReducer {
  colorFirst: string;
  colorSecond: string;
  color3: string;
  colorGrey: string;
  colorGreyDark: string;

  colorTextGrey: string;
  colorTextSecond: string;

  navHomeHeigth: number;
  headerAppHeight: string;

  colorGradient1: string;
  colorGradient2: string;
}

const initialState: IStyleReducer = {
  colorFirst: "#FBDDDB",
  colorSecond: "#C1EBD2",
  color3: "#D8AFF3",
  colorGrey: "#EDEFEB",
  colorGreyDark: "#DCE0D9",

  colorTextGrey: "#454745",
  colorTextSecond: "#5D7079",

  navHomeHeigth: 80,
  headerAppHeight: "80px",

  colorGradient1: "#E8ECF5",
  colorGradient2: "#F5F5F5",
};

const styleSlice = createSlice({
  name: 'styleSlice',
  initialState,
  reducers: {
  },
});

export const { actions, reducer: styleReducer } = styleSlice;
