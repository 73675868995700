import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LanguagesBar } from "../../Navbar/LanguagesBar";
import { useSelector } from "react-redux";
import { IRootState } from "../../../redux/store/store";
import CornerSwapLogo from "../../../static/icons/CornerSwapFinal.svg";
import searchMobileNav from "../../../static/icons/app/navbar/searchMobileNav.svg";
import homeIcon from "../../../static/icons/app/navbar/homeIcon.svg";
import tradeIcon from "../../../static/icons/app/navbar/tradeWhiteIcon.svg";
import overviewIcon from "../../../static/icons/app/navbar/overviewIcon.svg";
import profileIcon from "../../../static/icons/app/navbar/profileIcon.png";
import logoutIcon from "../../../static/icons/app/navbar/logoutIcon.svg";
import { useTranslation } from "react-i18next";

const NavbarAppPhone = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [searchCrypto, setSearchCrypto] = useState(false);
  const [filteredListByCrypto, setFilteredListByCrypto] = useState({});

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const { colorTextGrey, headerAppHeight } = useSelector(
    (state: IRootState) => state.styleData
  );
  const lngValue = useSelector(
    (state: IRootState) => state.languageData.lngValue
  );
  const listByCrypto = useSelector(
    ({ orderData }: IRootState) => orderData.listCryptocurrency
  );

  useEffect(() => {
    if (search === "") {
      setFilteredListByCrypto(listByCrypto);
    } else {
      const searchUpper = search.toUpperCase();
      const searchCapitalized =
        search.charAt(0).toUpperCase() + search.slice(1).toLowerCase();
      const filtered = Object.values(listByCrypto).filter(
        (item) =>
          // @ts-ignore
          item?.search?.startsWith(searchUpper) ||
          //@ts-ignore
          item?.name?.startsWith(searchCapitalized)
      );
      setFilteredListByCrypto(filtered);
    }
  }, [search, listByCrypto]);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      const dropResult =
        dropdownRef.current &&
        //@ts-ignore
        !dropdownRef.current.contains(event.target) &&
        //@ts-ignore
        !buttonRef.current.contains(event.target);

      dropResult && setSearchCrypto(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    // <div className="d-flex d-lg-none w-100 justify-content-between px-2 px-sm-0 my-3">
    //   {/* <Navbar.Toggle
    //     aria-controls="offcanvasNavbar-expand-lg"
    //     className="border-0 p-0"
    //     style={{ boxShadow: "none" }}
    //   />

    //   <div className="d-flex">
    //     <div
    //       className="p-0"
    //       style={{
    //         width: "167px",
    //       }}
    //     >
    //       <button
    //         ref={buttonRef}
    //         onClick={() => setSearchCrypto(!searchCrypto)}
    //         className="border-0 h-100 bg-white d-flex align-items-center rounded-5"
    //         style={{
    //           outline: "transparent",
    //           fontSize: "12px",
    //           width: "167px",
    //         }}
    //       >
    //         <img
    //           src={searchIcon}
    //           alt="searchIcon"
    //           style={{
    //             height: "20x",
    //             width: "20px",
    //           }}
    //         />

    //         <span
    //           className="ms-1"
    //           style={{
    //             color: "#909093",
    //             fontSize: "12px",
    //           }}
    //         >
    //           Search your coins...
    //         </span>
    //       </button>
    //     </div>

    //     <div className="position-relative d-flex justify-content-center align-items-center">
    //       {" "}
    //       <Dropdown className="p-0">
    //         {searchCrypto && (
    //           <div
    //             ref={dropdownRef}
    //             className="app__nav--search-mobile bg-white pt-3 rounded-4"
    //             style={{
    //               zIndex: 1000,
    //               boxShadow: "0px 3px 3px 0px rgb(184, 184, 184)",
    //             }}
    //           >
    //             <div
    //               className="mx-auto bg-white rounded-5 d-flex align-items-center mb-2"
    //               style={{ width: "90%" }}
    //             >
    //               <div className="ps-3 pe-3">
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   width="20"
    //                   height="20"
    //                   className="bi bi-search"
    //                   viewBox="0 0 16 16"
    //                 >
    //                   <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
    //                 </svg>
    //               </div>

    //               <input
    //                 className="input-search border-0"
    //                 type="text"
    //                 value={search}
    //                 onChange={(e) => setSearch(e.target.value)}
    //                 style={{
    //                   backgroundColor: "transparent",
    //                   fontSize: "16px",
    //                   paddingLeft: "10px!important",
    //                   textTransform: "uppercase",
    //                   width: "75%",
    //                 }}
    //                 placeholder=""
    //                 autoFocus
    //               />
    //             </div>
    //             <div
    //               style={{
    //                 height: "250px",
    //                 overflowY: "auto",
    //                 overscrollBehavior: "contain",
    //               }}
    //             >
    //               {Object.values(filteredListByCrypto).map(
    //                 (cryptoInfo, index) => {
    //                   //@ts-ignore
    //                   let base = cryptoInfo?.base;
    //                   if (
    //                     base &&
    //                     base.startsWith("X") &&
    //                     base !== "XCN" &&
    //                     base !== "XRT" &&
    //                     base !== "XTZ"
    //                   ) {
    //                     base = base.slice(1);
    //                   }
    //                   if (base && base.startsWith("Z")) {
    //                     return null;
    //                   }
    //                   if (base === "XBT") {
    //                     base = "BTC";
    //                   }
    //                   if (
    //                     base === "UST" ||
    //                     base === "TVK" ||
    //                     base === "NANO" ||
    //                     base === "ETHW"
    //                   ) {
    //                     return null;
    //                   }
    //                   return (
    //                     <Link
    //                       to={`/explore/cryptoInfo/${base}EUR`}
    //                       key={index}
    //                       className="d-block text-dark text-decoration-none my-1 item-list-crypto"
    //                       onClick={() => {
    //                         //@ts-ignore
    //                         setSearchCrypto(false);
    //                         localStorage.setItem("widgetCrypto", `${base}EUR`);
    //                       }}
    //                     >
    //                       <div className="d-flex align-items-center justify-content-between py-1">
    //                         <div className="d-flex">
    //                           <div className="ps-3 d-flex align-items-center">
    //                             <img
    //                               //@ts-ignore
    //                               src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.base?.toLowerCase()}_colored.svg`}
    //                               alt="logo"
    //                               style={{ height: "25px" }}
    //                             />
    //                           </div>

    //                           <div
    //                             className="ps-2 d-flex align-items-center"
    //                             style={{
    //                               fontSize: "16px",
    //                               fontWeight: "600",
    //                             }}
    //                           >
    //                             {(cryptoInfo as { base: string })?.base}
    //                           </div>

    //                           <div
    //                             className="ps-2 text-start d-flex align-items-center"
    //                             style={{
    //                               fontSize: "14px",
    //                               fontWeight: "400",
    //                             }}
    //                           >
    //                             {(cryptoInfo as { name: string })?.name}
    //                           </div>
    //                         </div>

    //                         <div
    //                           className="mx-2 border-dark bg-grey text-black rounded px-1 d-flex align-items-center"
    //                           style={{
    //                             fontSize: "12px",
    //                             fontWeight: "600",
    //                           }}
    //                         >
    //                           {(cryptoInfo as { type: string })?.type}
    //                         </div>
    //                       </div>
    //                     </Link>
    //                   );
    //                 }
    //               )}
    //             </div>
    //           </div>
    //         )}
    //       </Dropdown>
    //     </div>
    //   </div>

    //   <Navbar.Offcanvas
    //     id="offcanvasNavbar-expand-lg"
    //     aria-labelledby="offcanvasNavbarLabel-expand-lg"
    //     placement="end"
    //     style={{
    //       backgroundColor: "rgba(226, 235, 255, 0.49)",
    //       backdropFilter: "blur(10px)",
    //     }}
    //   >
    //     <Offcanvas.Header
    //       closeButton
    //       className="d-flex align-items-center"
    //       style={{ height: headerAppHeight }}
    //     >
    //       <Offcanvas.Title
    //         id="offcanvasNavbarLabel-expand-lg"
    //         className="fw-bold fs-3 text-decoration-underline m-0"
    //       >
    //         <img src={CornerSwapLogo} alt="" height={60} />
    //       </Offcanvas.Title>
    //     </Offcanvas.Header>

    //     <Offcanvas.Body className=" p-0">
    //       <Nav className="liste-menu ps-4 pt-4 d-flex justify-content-between h-100 pb-5">
    //         <div>
    //           <Link
    //             to="/home"
    //             className={`d-flex align-items-center text-decoration-none rounded-5 ${location.pathname === "/home" && "bg-white"}`}
    //             style={{
    //               maxWidth: "223px",
    //               height: "60px",
    //               boxShadow:
    //                 location.pathname === "/home"
    //                   ? "0px 3.5px 3.5px rgba(0, 0, 0, 0.25)"
    //                   : "",
    //             }}
    //           >
    //             <div
    //               className="d-flex justify-content-center align-items-center rounded-5"
    //               style={{
    //                 width: "54px",
    //                 height: "54px",
    //                 marginLeft: "3px",
    //                 backgroundColor: "#E8ECF5",
    //               }}
    //             >
    //               <img src={homeIcon} alt="home" />
    //             </div>

    //             <span className="ps-3 text-dark fs-3">{t("app.nav.home")}</span>
    //           </Link>

    //           <Link
    //             to="/trade"
    //             className={`d-flex align-items-center text-decoration-none rounded-5 mt-2 ${location.pathname === "/trade" && "bg-white"}`}
    //             style={{
    //               maxWidth: "223px",
    //               height: "60px",
    //               boxShadow:
    //                 location.pathname === "/trade"
    //                   ? "0px 3.5px 3.5px rgba(0, 0, 0, 0.25)"
    //                   : "",
    //             }}
    //           >
    //             <div
    //               className="d-flex justify-content-center align-items-center rounded-5"
    //               style={{
    //                 width: "54px",
    //                 height: "54px",
    //                 marginLeft: "3px",
    //                 backgroundColor: "#E8ECF5",
    //               }}
    //             >
    //               <img src={tradeIcon} alt="tradeIcon" />
    //             </div>

    //             <span className="ps-3 text-dark fs-3">{t("app.nav.trade")}</span>
    //           </Link>

    //           <Link
    //             to="/explore"
    //             className={`d-flex align-items-center text-decoration-none rounded-5 mt-2 ${location.pathname === "/explore" && "bg-white"}`}
    //             style={{
    //               maxWidth: "223px",
    //               height: "60px",
    //               boxShadow:
    //                 location.pathname === "/explore"
    //                   ? "0px 3.5px 3.5px rgba(0, 0, 0, 0.25)"
    //                   : "",
    //             }}
    //           >
    //             <div
    //               className="d-flex justify-content-center align-items-center rounded-5"
    //               style={{
    //                 width: "54px",
    //                 height: "54px",
    //                 marginLeft: "3px",
    //                 backgroundColor: "#E8ECF5",
    //               }}
    //             >
    //               <img src={overviewIcon} alt="ExploreIcon" />
    //             </div>

    //             <span className="ps-3 text-dark fs-3">{t("app.nav.explore")}</span>
    //           </Link>
    //         </div>

    //         <div>
    //           <Link
    //             to="/settings"
    //             className={`d-flex align-items-center text-decoration-none rounded-5 ${location.pathname === "/settings" && "bg-white"}`}
    //             style={{
    //               maxWidth: "223px",
    //               height: "60px",
    //               boxShadow:
    //                 location.pathname === "/settings"
    //                   ? "0px 3.5px 3.5px rgba(0, 0, 0, 0.25)"
    //                   : "",
    //             }}
    //           >
    //             <div
    //               className="d-flex justify-content-center align-items-center rounded-5"
    //               style={{
    //                 width: "54px",
    //                 height: "54px",
    //                 marginLeft: "3px",
    //                 backgroundColor: "#E8ECF5",
    //               }}
    //             >
    //               <img src={settingsIcon} alt="settingsIcon" />
    //             </div>

    //             <span className="ps-3 text-dark py-1 fs-3">Settings</span>
    //           </Link>

    //           <Link
    //             to="/"
    //             className={`d-flex align-items-center text-decoration-none rounded-5 mt-2`}
    //             style={{
    //               maxWidth: "223px",
    //               height: "60px",
    //             }}
    //           >
    //             <div
    //               className="d-flex justify-content-center align-items-center rounded-5"
    //               style={{
    //                 width: "54px",
    //                 height: "54px",
    //                 marginLeft: "3px",
    //                 backgroundColor: "#E8ECF5",
    //               }}
    //             >
    //               <img src={logoutIcon} alt="logoutIcon" />
    //             </div>

    //             <span className="ps-3 text-dark fs-3">Logout</span>
    //           </Link>
    //         </div>
    //       </Nav>
    //     </Offcanvas.Body>
    //   </Navbar.Offcanvas> */}

    // </div>
    <div className="d-lg-none" style={{
      zIndex: 9999,
    }}>
      <div
        className="container p-0 d-flex justify-content-between align-items-center position-fixed bottom-0 start-50 translate-middle-x rounded-top-4 mobile__nav"
        style={{
          overflow: "hidden",
        }}
      >
        <div className="blur__container">
          <div className="blur__content d-flex justify-content-between align-items-center px-4">
            <Link to="/home" className="d-flex mobile__nav-icon">
              <img src={homeIcon} alt="homeIcon" />
            </Link>

            <button
              ref={buttonRef}
              className="border-0 bg-transparent mobile__nav-icon p-0"
              onClick={() => setSearchCrypto(!searchCrypto)}
              style={{
                width: "max-content",
              }}
            >
              <img
                src={searchMobileNav}
                alt="searchMobileNav"
                style={{
                  height: "95%",
                }}
              />
            </button>

            <Link
              to="/trade"
              className="d-flex mobile__nav-trade"
              style={{
                backgroundColor: "#DBB1F6",
                borderRadius: "50%",
                padding: "10px",
              }}
            >
              <img src={tradeIcon} alt="tradeIcon" />
            </Link>

            <Link to="/explore" className="d-flex mobile__nav-icon">
              <img src={overviewIcon} alt="overviewIcon" />
            </Link>

            <Link to="/profile" className="d-flex mobile__nav-icon">
              <img src={profileIcon} alt="" style={{ height: "100%" }} />
            </Link>
          </div>
        </div>
      </div>

      <div className="container start-50 translate-middle-x px-2 p-sm-0 px- app__nav--search-mobile">
        {searchCrypto && (
          <div
            ref={dropdownRef}
            className="pp py-3 rounded-4"
            style={{
              zIndex: 9999,
              boxShadow: "0px 3px 3px 0px rgb(184, 184, 184)",
              background: "rgb(245, 243, 252)",
            }}
          >
            <div
              className="mx-auto bg-white rounded-5 d-flex align-items-center mb-2"
              style={{ width: "90%" }}
            >
              <div className="ps-3 pe-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </div>

              <input
                className="input-search border-0"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  paddingLeft: "10px!important",
                  textTransform: "uppercase",
                  width: "75%",
                  outline: "none",
                }}
                placeholder=""
                autoFocus
              />
            </div>

            <div
              style={{
                height: "250px",
                overflowY: "auto",
                overscrollBehavior: "contain",
              }}
            >
              {Object.values(filteredListByCrypto).map((cryptoInfo, index) => {
                //@ts-ignore
                let base = cryptoInfo?.base;
                if (
                  base &&
                  base.startsWith("X") &&
                  base !== "XCN" &&
                  base !== "XRT" &&
                  base !== "XTZ"
                ) {
                  base = base.slice(1);
                }
                if (base && base.startsWith("Z")) {
                  return null;
                }
                if (base === "XBT") {
                  base = "BTC";
                }
                if (
                  base === "UST" ||
                  base === "TVK" ||
                  base === "NANO" ||
                  base === "ETHW"
                ) {
                  return null;
                }
                return (
                  <Link
                    to={`/explore/cryptoInfo/${base}EUR`}
                    key={index}
                    className="d-block text-dark text-decoration-none my-1 item-list-crypto"
                    onClick={() => {
                      //@ts-ignore
                      setSearchCrypto(false);
                      localStorage.setItem("widgetCrypto", `${base}EUR`);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between py-1">
                      <div className="d-flex">
                        <div className="ps-3 d-flex align-items-center">
                          <img
                            //@ts-ignore
                            src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.base?.toLowerCase()}_colored.svg`}
                            alt="logo"
                            style={{ height: "25px" }}
                          />
                        </div>

                        <div
                          className="ps-2 d-flex align-items-center"
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {(cryptoInfo as { base: string })?.base}
                        </div>

                        <div
                          className="ps-2 text-start d-flex align-items-center"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {(cryptoInfo as { name: string })?.name}
                        </div>
                      </div>

                      <div
                        className="mx-2 border-dark bg-grey text-black rounded px-1 d-flex align-items-center"
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {(cryptoInfo as { type: string })?.type}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarAppPhone;
