import React, { useState, Children, cloneElement } from "react";
import arrowNext from "../../../static/icons/app/home/arrowNext.svg";
import { useTranslation } from "react-i18next"

export const SliderCharts = ({ children, displayCount }: any) => {
  const { t } = useTranslation();

  const [current, setCurrent] = useState(0);

  const prevSlide = () => {
    setCurrent(
      current - displayCount < 0
        ? Children.count(children) - displayCount
        : current - displayCount
    );
  };

  const nextSlide = () => {
    setCurrent(
      current + displayCount >= Children.count(children)
        ? 0
        : current + displayCount
    );
  };

  const childrenArray = Children.toArray(children);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-4 mb-1">
        <span className="app__home-subtitle m-0">{t("app.home.charts")}</span>

        <div className="d-flex">
          <button
            className="border-0 bg-white rounded-3 app__home-charts-arrow me-2"
            style={{
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            onClick={prevSlide}
          >
            <img
              src={arrowNext}
              alt="arrowNext"
              style={{ transform: "rotate(180deg)" }}
            />
          </button>

          <button
            className="border-0 bg-white rounded-3 app__home-charts-arrow"
            style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" }}
            onClick={nextSlide}
          >
            <img src={arrowNext} alt="arrowNext" />
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        {childrenArray
          .slice(current, current + displayCount)
          .map((child, index) =>
            cloneElement(
              child as React.ReactElement<
                any,
                string | React.JSXElementConstructor<any>
              >,
              { key: index }
            )
          )}
      </div>
    </div>
  );
};
