import React, { useContext, useEffect, useState } from "react";
import cornerLogoBlack from "../../static/icons/CornerSwapFinal.png";
import cornerLogoWhite from "../../static/icons/app/navbar/CornerSwapDark.png";
import { useDispatch, useSelector } from "react-redux";
import { setListCryptocurrency } from "../../redux/slices/order.slice";
import axios from "axios";
import { IRootState } from "../../redux/store/store";
import { useTranslation } from "react-i18next";
import { Subsribe } from "../Blog/Subsribe";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../providers/ThemeProvider";
import config from "../../config";
import facebookIcon from "../../static/icons/socialNetwork/facebookIcon.png";
import facebookIconWhite from "../../static/icons/socialNetwork/facebookIconWhite.png";
import instagramIcon from "../../static/icons/socialNetwork/instagramIcon.png";
import instagramIconWhite from "../../static/icons/socialNetwork/instagramIconWhite.png";
import linkedInIcon from "../../static/icons/socialNetwork/linkedInIcon.png";
import linkedInIconWhite from "../../static/icons/socialNetwork/linkedInIconWhite.png";
import twitterIcon from "../../static/icons/socialNetwork/twitterIcon.png";
import twitterIconWhite from "../../static/icons/socialNetwork/twitterIconWhite.png";

const blogLink = process.env.REACT_APP_LINK_BLOG;

const top10 = [
  {
    name: "Bitcoin",
    blockchain: "Bitcoin, Lightning",
    search: "BTC",
    altname: "BTCEUR",
  },
  {
    name: "Ethereum ('Ether')",
    blockchain: "Ethereum (ERC-20)",
    search: "ETH",
    altname: "ETHEUR",
  },
  {
    name: "Tether",
    blockchain: "Ethereum (ERC-20)",
    search: "USDT",
    altname: "USDTEUR",
  },
  {
    name: " Binance Coin",
    blockchain: "Binance chain",
    search: "BNB",
    altname: "BNBEUR",
  },
  {
    name: "Solana",
    blockchain: "Solana (SPL)",
    search: "SOL",
    altname: "SOLEUR",
  },
  {
    name: "Ripple",
    blockchain: "BNB Smart Chain",
    search: "XRP",
    altname: "XRPEUR",
  },
  {
    name: "US Dollar Coin",
    blockchain: "Ethereum (ERC-20)",
    search: "USDC",
    altname: "USDCEUR",
  },
  {
    name: "Cardano",
    blockchain: "Cardano",
    search: "ADA",
    altname: "ADAEUR",
  },
  {
    name: "Avalanche",
    blockchain: "C-Chain",
    search: "AVAX",
    altname: "AVAXEUR",
  },
  {
    name: "Dogecoin ",
    blockchain: "Ethereum (ERC-20)",
    search: "DOGE",
    altname: "DOGEEUR",
  },
];

const Footer = () => {
  const { t } = useTranslation();

  const listCrypto = useSelector(
    ({ orderData }: IRootState) => orderData.listCryptocurrency
  );

  const [category, setCategory] = useState(10);
  const [mobileScreen, setMobileScreen] = useState(false);
  const [more, setMore] = useState(4);

  const [theme, setTheme] = useContext(ThemeContext);

  const { colorFirst, colorSecond, colorTextSecond } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setMobileScreen(window.innerWidth < 768);

    const objectToPost = {
      input: "ZEUR",
    };

    axios
      .post(`${config.apiURI}/pb/pairsByDevice`, objectToPost)
      .then(function (response: any) {
        if (response.status === 200) {
          dispatch(setListCryptocurrency(Object.values(response?.data)));
        }
      })
      .catch(function (error: any) {
        console.log("error :", error);
      });
  }, []);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth < 768;

      screenWidth !== mobileScreen && setMobileScreen(screenWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileScreen]);

  useEffect(() => {
    if (!Array.isArray(listCrypto)) {
      const arrayCrypto = Object.entries(listCrypto).map(
        ([key, value]: any) => ({
          ...value,
          id: key,
        })
      );

      dispatch(setListCryptocurrency(arrayCrypto));
    }
  }, [listCrypto]);

  const handleMore = () => {
    if (more + 2 < category) {
      setMore((prevState) => prevState + 2);
    } else {
      setMore(4);
    }
  };

  return (
    <div
      className="w-100 m-0 p-0 position-relative footer footer__border"
      style={{
        minHeight: "100vh",
        zIndex: "999",
      }}
    >
      <div className="w-100 mx-auto px-2 pt-5" style={{ maxWidth: "1320px" }}>
        <p className="fs-1 fw-bold m-0 pt-3 home__background-text">
          {t("footer.title")}
        </p>

        <div className="mt-5">
          <button
            onClick={() => {
              setCategory(10);
              setMore(4);
            }}
            className="fw-bold text-decoration-none py-2 px-4 text-center rounded-5 me-3 mb-2 mb-sm-0"
            style={{
              border: `1px solid black`,
              backgroundColor: category === 10 ? colorSecond : "",
            }}
          >
            {t("footer.top.10")}
          </button>

          <button
            onClick={() => {
              setCategory(listCrypto?.length);
              setMore(4);
            }}
            className="fw-bold text-decoration-none py-2 px-4 text-center rounded-5"
            style={{
              border: `1px solid black`,
              backgroundColor:
                category === listCrypto?.length ? colorSecond : "",
            }}
          >
            {t("footer.top.all")}
          </button>
        </div>

        <div className="row d-flex justify-content-between flex-wrap m-0 mt-2">
          {category === 10 &&
            top10
              .slice(0, mobileScreen ? more : top10.length)
              .map((cryptoInfo: any, index: number) => (
                <div
                  className="col-6 col-sm-6 col-md-4 col-lg-2 me-lg-4 mt-5"
                  key={index}
                >
                  <img
                    //@ts-ignore
                    src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.search?.toLowerCase()}_colored.svg`}
                    alt="logo"
                    style={{ height: "48px" }}
                  />

                  <p
                    className="m-0 fw-bold mt-3"
                    style={{ color: colorTextSecond }}
                  >
                    {cryptoInfo?.name}
                  </p>

                  <p className="m-0" style={{ color: colorTextSecond }}>
                    {cryptoInfo?.blockchain}
                  </p>

                  <Link
                    to={`/cryptoInfo/${cryptoInfo?.altname}`}
                    className="m-0 fw-bold home__background-text"
                  >
                    {t("footer.crypto.1")} {cryptoInfo?.search}{" "}
                    {t("footer.crypto.2")}
                  </Link>
                </div>
              ))}

          {listCrypto &&
            category === listCrypto?.length &&
            listCrypto
              ?.slice(0, mobileScreen ? more : listCrypto?.length)
              .map((cryptoInfo: any, index: number) => (
                <div
                  className="col-6 col-sm-6 col-md-4 col-lg-2 me-lg-4 mt-5"
                  key={index}
                >
                  <img
                    //@ts-ignore
                    src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.search?.toLowerCase()}_colored.svg`}
                    alt="logo"
                    style={{ height: "48px" }}
                  />

                  <p
                    className="m-0 fw-bold mt-3"
                    style={{ color: colorTextSecond }}
                  >
                    {cryptoInfo?.name}
                  </p>

                  <p className="m-0" style={{ color: colorTextSecond }}>
                    {cryptoInfo?.blockchain}
                  </p>

                  <Link
                    to={`/cryptoInfo/${cryptoInfo?.altname}`}
                    className="m-0 fw-bold home__background-text"
                  >
                    Get {cryptoInfo?.search} account details
                  </Link>
                </div>
              ))}

          {mobileScreen && (
            <button
              onClick={handleMore}
              className="mt-5 border-0 bg-transparent fw-bold text-decoration-underline home__background-text"
            >
              {more < category ? "Show more" : "Show less"}
            </button>
          )}
        </div>
      </div>

      <div className="subscribe">
        <div
          className="w-100 mx-auto px-2 py-3 my-2 my-md-5 subscribe"
          style={{ maxWidth: "1320px" }}
        >
          <Subsribe bgColor="#ECEFF5" classProp="" />
        </div>
      </div>

      <div className="w-100 mx-auto px-2 pb-5" style={{ maxWidth: "1320px" }}>
        <div
          // className="w-100 py-5 row justify-content-between mx-auto"
          className="w-100 pb-5 px-md-5 py-md-5 row justify-content-center mx-auto"
          style={{ marginTop: "100px", borderBottom: "1px solid #6c757d" }}
        >
          <div className="d-md-none p-0 text-center mb-5">
            <img
              src={theme === "light" ? cornerLogoBlack : cornerLogoWhite}
              alt="cornerLogoBlack"
              style={{
                height: "60px",
              }}
            />
          </div>

          <Nav className="col-12 col-md-2 col-lg-2 d-flex flex-column align-items-center align-items-md-start home__background-text px-1">
            <p className="m-0 fw-bold mb-3">{t("footer.categories.company")}</p>

            <Link
              to="/about"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.about")}
            </Link>

            {!!blogLink?.length && (
              <Link
                to={blogLink}
                className="m-0 p-0 text-decoration-underline home__background-text"
              >
                {t("footer.links.learn")}
              </Link>
            )}
          </Nav>

          <Nav className="col-12 col-md-2 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0 home__background-text px-1">
            <p className="m-0 fw-bold mb-3">
              {t("footer.categories.personal")}
            </p>

            <Link
              to="/signin"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.buy")}
            </Link>
            <Link
              to="/signin"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.sell")}
            </Link>
            <Link
              to="/signin"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.exchange")}
            </Link>
          </Nav>

          <Nav className="col-12 col-md-2 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0 home__background-text px-1">
            <p className="m-0 fw-bold mb-3">
              {t("footer.categories.business")}
            </p>

            <Link
              to="/onramp"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.onRamp")}
            </Link>
            <Link
              to="/business"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("navBar.business")}
            </Link>
          </Nav>

          <Nav className="col-12 col-md-2 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0 home__background-text px-1">
            <p className="m-0 fw-bold mb-3">
              {t("footer.categories.policies")}
            </p>

            <Link
              to="/policy"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.policy")}
            </Link>
            <Link
              to="/security"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.security")}
            </Link>
            <Link
              to="/amlkyc"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.amlkyc")}
            </Link>
            <Link
              to="/conditions"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.conditions")}
            </Link>
          </Nav>

          <Nav className="col-12 col-md-2 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0 home__background-text px-1">
            <p className="m-0 fw-bold mb-3">{t("footer.categories.support")}</p>

            <Link
              to="/contactus"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.contact")}
            </Link>
            <Link
              to="/faq"
              className="m-0 p-0 text-decoration-underline home__background-text"
            >
              {t("footer.links.faq")}
            </Link>
          </Nav>

          <div className="p-0 col-12 col-md-2 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0 home__background-text">
            <p className="m-0 fw-bold mb-3">{t("footer.categories.follow")}</p>

            <Nav className="d-flex">
              <Nav.Link
                href="https://www.facebook.com/profile.php?id=61557122348717"
                className="me-3 mb-md-3 d-flex text-dark p-0"
                style={{ width: "max-content" }}
              >
                <img
                  src={theme === "light" ? facebookIcon : facebookIconWhite}
                  height={24}
                  alt="facebookIcon"
                />
              </Nav.Link>

              <Nav.Link
                href="https://x.com/corner_swapp"
                className="me-3 d-flex text-dark p-0"
                style={{ width: "max-content" }}
              >
                <img
                  src={theme === "light" ? twitterIcon : twitterIconWhite}
                  height={24}
                  alt="twitterIcon"
                />
              </Nav.Link>

              {/* <Nav.Link
                href="/"
                className="me-3 d-flex text-dark p-0"
                style={{ width: "max-content" }}
              >
                <img
                  src={theme === "light" ? instagramIcon : instagramIconWhite}
                  height={24}
                  alt="instagramIcon"
                />
              </Nav.Link> */}

              <Nav.Link
                href="https://www.linkedin.com/company/cornerswap"
                className="me-xl-3 d-flex text-dark p-0"
                style={{ width: "max-content" }}
              >
                <img
                  src={theme === "light" ? linkedInIcon : linkedInIconWhite}
                  height={24}
                  alt="LinkdedInIcon"
                />
              </Nav.Link>
            </Nav>
          </div>
        </div>

        <div className="w-100 px-5 py-5 d-flex justify-content-between align-items-center">
          <div className="d-none d-md-flex">
            <img
              src={theme === "light" ? cornerLogoBlack : cornerLogoWhite}
              alt="cornerLogoBlack"
              style={{
                height: "50px",
              }}
            />
          </div>

          <p className="m-0 mx-auto mx-md-0 home__background-text">
            © CornerSwap Inc 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
