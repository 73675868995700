import React, { useEffect, useState } from "react";
import { IRootState } from "../../redux/store/store";
import { useSelector } from "react-redux";

interface IMoveArrow {
  secondColor: boolean;
}

export const MoveArrow: React.FC<IMoveArrow> = ({ secondColor }) => {
  const leftBlockWidth =
    window.innerWidth < 1320 ? "0px" : `${(window.innerWidth - 1320) / 2}px`;

  const [width, setWidth] = useState(0.2 * window.innerHeight);
  const [rotateFlag, setRotateFlag] = useState(false);

  const { colorFirst, colorSecond, colorGradient1 } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const element = document.getElementById("moveArrow");
      const rect = element?.getBoundingClientRect();

      //@ts-ignore
      setRotateFlag(+rect?.top.toFixed(2) < 0.9 * windowHeight);
      //@ts-ignore
      if (+rect?.top.toFixed(2) < windowHeight) {
        const newWidth =
          //@ts-ignore
          ((windowHeight - rect.top) / windowHeight) * 500 +
          0.2 * window.innerWidth;

        setWidth(newWidth);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className="d-flex my-5 w-100"
        style={{
          height: `${0.2 * window.innerHeight}px`,
          overflow: "hidden",
          maxWidth: "100vw",
        }}
      >
        <div className="d-flex h-100 p-0 m-0">
          <div
            className="p-0 m-0"
            style={{
              width: leftBlockWidth,
              height: `${0.2 * window.innerHeight}px`,
              background: secondColor ? colorGradient1 : colorFirst,
              zIndex: 1,
            }}
          />

          <div
            className="p-0 m-0 d-flex justify-content-end align-items-center pe-2 flex-grow-1"
            style={{
              width: `${width}px`,
              minWidth: `${0.3 * window.innerHeight}px`,
              maxWidth: "100vw",
              height: `${0.2 * window.innerHeight}px`,
              background: secondColor ? colorGradient1 : colorFirst,
              borderEndEndRadius: `${0.1 * window.innerHeight}px`,
              borderStartEndRadius: `${0.1 * window.innerHeight}px`,
              zIndex: 1,
            }}
            id="moveArrow"
          >
            <div
              className="d-flex justify-content-center align-items-center rounded-circle"
              style={{
                maxWidth: "100%",
                width: `${0.2 * window.innerHeight - 15}px`,
                height: `${0.2 * window.innerHeight - 15}px`,
                background: colorSecond,
              }}
            >
              <svg
                width="80%"
                height="80%"
                fill={colorFirst}
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path d="m22.029 11.57-8.572-8.572-1.2 1.2 7.115 7.114H1.713v1.715h17.658l-7.115 7.114 1.2 1.2 8.572-8.572a.829.829 0 0 0 0-1.2Z"></path>
              </svg>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center ms-3">
            <img
              src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
              alt="btc"
              style={{
                width: `${0.2 * window.innerHeight - 10}px`,
                height: `${0.2 * window.innerHeight - 10}px`,
                transform: `rotate(${rotateFlag ? 0.8 * width : 0}deg)`,
              }}
            />
          </div>

          <div className="d-flex justify-content-end align-items-center ms-3">
            <img
              src="https://assets.coingecko.com/coins/images/18537/large/0xef71ca2ee68f45b9ad6f72fbdb33d707b872315c.png?1696518017"
              alt="eth"
              style={{
                width: `${0.2 * window.innerHeight - 10}px`,
                height: `${0.2 * window.innerHeight - 10}px`,
                transform: `rotate(${rotateFlag ? 0.8 * width : 0}deg)`,
              }}
            />
          </div>

          <div className="d-flex justify-content-end align-items-center ms-3">
            <img
              src="https://cryptologos.cc/logos/shiba-inu-shib-logo.png"
              alt="shiba"
              style={{
                width: `${0.2 * window.innerHeight - 10}px`,
                height: `${0.2 * window.innerHeight - 10}px`,
                transform: `rotate(${rotateFlag ? 0.8 * width : 0}deg)`,
              }}
            />
          </div>

          <div className="d-flex justify-content-end align-items-center ms-3">
            <img
              src="	https://seeklogo.com/images/S/sushiswap-sushi-logo-1317C99D2C-seeklogo.com.png"
              alt="Sushi"
              style={{
                width: `${0.2 * window.innerHeight - 10}px`,
                height: `${0.2 * window.innerHeight - 10}px`,
                transform: `rotate(${rotateFlag ? 0.6 * width : 0}deg)`,
              }}
            />
          </div>

          <div className="d-flex justify-content-end align-items-center ms-3">
            <img
              src="https://cdn-icons-png.flaticon.com/512/5987/5987962.png"
              alt="Solana"
              style={{
                width: `${0.2 * window.innerHeight - 10}px`,
                height: `${0.2 * window.innerHeight - 10}px`,
                transform: `rotate(${rotateFlag ? 0.8 * width : 0}deg)`,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
