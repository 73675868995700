import React, { useState } from "react";
import { useSelector } from "react-redux"
import { IRootState } from "../../redux/store/store"
import PlaceholderEndInput from "./PlaceholderEndInput"
import { Link } from "react-router-dom"

const OrderWindow = () => {
  const [methodBuy, setMethodBuy] = useState(true);

  const { colorGrey, colorGreyDark } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  return (
    <div className="d-flex flex-column w-100 border rounded-3 bg-white shadow-sm px-3 py-4">
      <p className="m-0 fs-5 fw-bold">Make a trade</p>
      <p className="m-0 fw-bold mt-3">Choose offer type</p>

      <div
        className="rounded-3 mt-2"
        style={{
          background: colorGrey,
          border: `1px solid ${colorGreyDark}`,
        }}
      >
        <button
          className={`w-50 py-2 fw-bold ${methodBuy ? "rounded-3 bg-white" : "border-0 bg-transparent"}`}
          onClick={() => setMethodBuy(true)}
        >
          Buy
        </button>

        <button
          className={`w-50 py-2 fw-bold ${!methodBuy ? "rounded-3 bg-white" : "border-0 bg-transparent"}`}
          onClick={() => setMethodBuy(false)}
        >
          Sell
        </button>
      </div>

      <p className="m-0 fw-bold mt-4">How often?</p>

      <select
        defaultValue={"Once"}
        className="form-select form-select-sm mt-2 py-3 ps-3 fw-bold outline-none rounded-3"
        aria-label=".form-select-sm example"
      >
        <option value="1">Once</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select>
      <p className="m-0 fw-bold mt-4">How much?</p>

      <PlaceholderEndInput />

      <Link
        to="/signin"
        className="mt-4 w-100 text-light text-decoration-none bg-dark text-center py-3 rounded-5"
      >
        Continue
      </Link>

      <p className="m-0 fw-bold text-center btn">
        Looking for other order types?
      </p>
    </div>
  );
};

export default OrderWindow;
