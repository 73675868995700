import React, { useEffect, useRef, useState } from "react";
import card1 from "../static/images/card1.png";
import card2 from "../static/images/card2.png";
import card3 from "../static/images/card3.png";
import { useSelector } from "react-redux"
import { IRootState } from "../redux/store/store"
import { useTranslation } from "react-i18next"

const Slider = () => {
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [items, setItems] = useState([true, true, true]);
  const [isVisible, setIsVisible] = useState(false);

  const { colorFirst } = useSelector(({ styleData }: IRootState) => styleData)

  const componentRef = useRef(null);

  const leftBlockWidth =
    window.innerWidth < 1320 ? "0px" : `${(window.innerWidth - 1320) / 2}px`;

  const handleNext = () => {
    setItems((prevItems) =>
      prevItems.map((item, index) => (index === currentIndex ? false : item))
    );
    setCurrentIndex((prevIndex) =>
      prevIndex < items.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrev = () => {
    setItems((prevItems) =>
      prevItems.map((item, index) => (index === currentIndex - 1 ? true : item))
    );
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (componentRef.current) {
        const componentTopOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef.current.getBoundingClientRect().top) /
          window.innerHeight;

        const componentBottomOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef.current.getBoundingClientRect().bottom) /
          window.innerHeight;

        setIsVisible(componentBottomOffset < 0.6 && componentTopOffset > 0.3);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-100 d-flex flex-column flex-md-row py-5 animated-component ${isVisible ? "visible" : "hidden"}`}
      ref={componentRef}
      style={{ margin: "150px 0" }}
    >
      <div className="h-100" style={{ width: leftBlockWidth }}></div>

      <div
        className="w-100 px-2 px-lg-4 d-flex flex-column justify-content-center"
        style={{ maxWidth: "660px" }}
      >
        <p
          className="m-0 header__title fw-bold lh-1"
          style={{ fontFamily: "Wise Sans" }}
        >
          {t("personal.people.title")}
        </p>

        <div className="d-none d-md-block mt-5">
          <button
            className="border-0 p-3 rounded-5"
            onClick={handlePrev}
            style={{
              background: `${currentIndex > 0 ? colorFirst : "#dbd6d6"}`,
            }}
            disabled={currentIndex === 0}
          >
            <svg
              width="35"
              height="35"
              fill="currentColor"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path d="M22.286 11.316H4.629l7.114-7.114-1.2-1.2-8.572 8.571a.829.829 0 0 0 0 1.2l8.572 8.572 1.2-1.2-7.114-7.114h17.657v-1.715Z"></path>
            </svg>
          </button>

          <button
            className="border-0 p-3 rounded-5 ms-3"
            onClick={handleNext}
            style={{
              background: `${currentIndex < items.length - 1 ? colorFirst : "#dbd6d6"}`,
            }}
            disabled={currentIndex === items.length - 1}
          >
            <svg
              width="35"
              height="35"
              fill="currentColor"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path d="m22.029 11.57-8.572-8.572-1.2 1.2 7.115 7.114H1.713v1.715h17.658l-7.115 7.114 1.2 1.2 8.572-8.572a.829.829 0 0 0 0-1.2Z"></path>
            </svg>
          </button>
        </div>
      </div>

      <div
        className="d-flex align-items-center slider my-5 my-md-0"
        style={{ overflow: "hidden" }}
      >
        <div className="cards d-flex justify-content-start h-100">
          <div
            className={`card__info rounded-5 d-flex flex-column justify-content-end ${items[0] && "active"}`}
          >
            <img src={card1} alt="card1" style={{ height: "100%" }} />
          </div>

          <div
            className={`card__info rounded-5 d-flex flex-column justify-content-end ${items[1] && "active"}`}
          >
            <img src={card2} alt="card2" style={{ height: "100%" }} />
          </div>

          <div
            className={`card__info rounded-5 d-flex flex-column justify-content-end ${items[2] && "active"}`}
          >
            <img src={card3} alt="card3" style={{ height: "100%" }} />
          </div>
        </div>
      </div>

      <div className="d-block d-md-none ms-4">
        <button
          className="border-0 p-3 rounded-5"
          onClick={handlePrev}
          style={{
            background: `${currentIndex > 0 ? colorFirst : "#dbd6d6"}`,
          }}
          disabled={currentIndex === 0}
        >
          <svg
            width="35"
            height="35"
            fill="currentColor"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path d="M22.286 11.316H4.629l7.114-7.114-1.2-1.2-8.572 8.571a.829.829 0 0 0 0 1.2l8.572 8.572 1.2-1.2-7.114-7.114h17.657v-1.715Z"></path>
          </svg>
        </button>

        <button
          className="border-0 p-3 rounded-5 ms-3"
          onClick={handleNext}
          style={{
            background: `${currentIndex < items.length - 1 ? colorFirst : "#dbd6d6"}`,
          }}
          disabled={currentIndex === items.length - 1}
        >
          <svg
            width="35"
            height="35"
            fill="currentColor"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path d="m22.029 11.57-8.572-8.572-1.2 1.2 7.115 7.114H1.713v1.715h17.658l-7.115 7.114 1.2 1.2 8.572-8.572a.829.829 0 0 0 0-1.2Z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Slider;
