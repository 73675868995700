import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import prev from "../../../static/icons/app/IconPrev.png";
import next from "../../../static/icons/app/IconNext.png";

interface IPaginateList {
  rowsPerPage: number;
  setRowsPerPage: (value: number) => void;
  rowsLength: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

export const PaginateList: React.FC<IPaginateList> = ({
  rowsPerPage,
  setRowsPerPage,
  rowsLength,
  currentPage,
  setCurrentPage,
}) => {
  const [beginPageValue, setBeginPageValue] = useState(1);
  const [finalPageValue, setFinalPageValue] = useState(
    rowsPerPage > rowsLength ? rowsLength : rowsPerPage
  );

  useEffect(() => {
    setBeginPageValue(1);
    setFinalPageValue(rowsPerPage > rowsLength ? rowsLength : rowsPerPage);
    setCurrentPage(1);
  }, [rowsPerPage]);

  const handlePrevPage = () => {
    if (currentPage === 1) return;

    if (finalPageValue === rowsLength) {
      setCurrentPage(currentPage - 1);
      setBeginPageValue(beginPageValue - rowsPerPage);
      setFinalPageValue(finalPageValue - (finalPageValue % rowsPerPage));
    } else {
      setCurrentPage(currentPage - 1);
      setBeginPageValue(beginPageValue - rowsPerPage);
      setFinalPageValue(finalPageValue - rowsPerPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage === Math.ceil(rowsLength / rowsPerPage)) return;

    if (currentPage + 1 === Math.ceil(rowsLength / rowsPerPage)) {
      setFinalPageValue(rowsLength);
      setBeginPageValue(beginPageValue + rowsPerPage);
      setCurrentPage(currentPage + 1);
      
      return;
    }

    setCurrentPage(currentPage + 1);
    setBeginPageValue(beginPageValue + rowsPerPage);
    setFinalPageValue(finalPageValue + rowsPerPage);
  };

  return (
    <>
      {rowsLength > 5 && (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <span className="d-none d-md-block">Rows per page:</span>

          <NavDropdown
            className="d-none d-md-block mx-3 border px-3 rounded-4 bg-white"
            title={rowsPerPage}
            id="offcanvasNavbarDropdown-expand-lg"
          >
            <NavDropdown.Item onClick={() => setRowsPerPage(5)}>
              5
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => setRowsPerPage(10)}>
              10
            </NavDropdown.Item>
          </NavDropdown>

          <span className="d-none d-md-block me-3">
            {beginPageValue} – {finalPageValue} of {rowsLength}
          </span>

          <button
            className="border rounded-5 px-3 py-2 bg-white me-3"
            onClick={handlePrevPage}
          >
            <img src={prev} alt="prev" height={15} />
          </button>

          <span className="d-md-none me-3">
            {beginPageValue} – {finalPageValue} of {rowsLength}
          </span>

          <button
            className="border rounded-5 px-3 py-2 bg-white"
            onClick={handleNextPage}
          >
            <img src={next} alt="next" height={15} />
          </button>
        </div>
      )}
    </>
  );
};
