import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuth {
  fetchAuth: {
    token: any;
    country: string;
    ClientId: any;
    lastName: any;
    firstName: any;
    isKyc: any;
    signature: any;

    isMail: any;
    isQcm: any;
    isPdata: any;

    checkCorner: any;
    phone: any;

    isSms: any;
    isNewsletter: any;

    totalFiatInvest: any;
    link_kyc: any;
    email: any;
    statut: any;
    kyc: any;
    wallets: any;
    wallet: any;
    walletType: any;
    historique: any;
    isFirstEntry: any;
  };

  isFirstEntry: any;
  answers: {
    qcm1: string;
    qcm2: string;
    qcm3: string;
    qcm4: string;
    qcm5: string;
    qcm6: string;
    buy: string;
    assisted: string;
    anydesk: string;
    risks: string;
  };
}

const initialState: IAuth = {
  fetchAuth: {
    country: "pl",
    token: null,
    ClientId: null,
    lastName: "Nom",
    firstName: "Prenom",
    isKyc: null,
    signature: null,

    isMail: null,
    isQcm: null,
    isPdata: null,

    checkCorner: null,
    phone: null,

    isSms: null,
    isNewsletter: 0,

    totalFiatInvest: null,
    link_kyc: null,
    email: "email@email.com",
    statut: null,
    kyc: "",
    wallets: [],
    wallet: "",
    walletType: null,
    historique: [],
    isFirstEntry: null,
  },
  isFirstEntry: null,
  answers: {
    qcm1: "",
    qcm2: "",
    qcm3: "",
    qcm4: "",
    qcm5: "",
    qcm6: "",
    buy: "",
    assisted: "",
    anydesk: "",
    risks: "",
  },
};

type AuthEmail = PayloadAction<string>;
type Country = PayloadAction<string>;
type FirstName = PayloadAction<string>;

type Answers = PayloadAction<string>;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action: AuthEmail) => {
      state.fetchAuth.email = action.payload;
    },
    setCountry: (state, action: Country) => {
      state.fetchAuth.country = action.payload;
    },
    setAuth: (state, { payload }) => {
      state.fetchAuth = payload;
    },
    setSignature: (state, { payload }) => {
      state.fetchAuth.signature = payload;
    },
    setIsFirstEntry: (state, { payload }) => {
      state.isFirstEntry = payload;
    },
    setStatut: (state, { payload }) => {
      state.fetchAuth.statut = payload;
    },
    setKyc: (state, { payload }) => {
      state.fetchAuth.kyc = payload;
    },
    //@ts-ignore
    setInitiateCorer: (state, _) => {
      state.fetchAuth.ClientId = null;
      //@ts-ignore
      state.fetchAuth.lastName = null;
      //@ts-ignore
      state.fetchAuth.firstName = null;
      state.fetchAuth.isKyc = null;
      state.fetchAuth.signature = null;
      state.fetchAuth.checkCorner = null;
      state.fetchAuth.phone = null;
      state.fetchAuth.isMail = null;
      state.fetchAuth.isSms = null;
      state.fetchAuth.isNewsletter = null;
      state.fetchAuth.isQcm = null;
      state.fetchAuth.totalFiatInvest = null;
      //@ts-ignore
      state.fetchAuth.isFirstEntry = null;
      state.fetchAuth.kyc = "";
    },
    setFirstName: (state, { payload }: FirstName) => {
      state.fetchAuth.firstName = payload;
    },
    setAnswer1: (state, { payload }: Answers) => {
      state.answers.qcm1 = payload;
    },
    setAnswer2: (state, { payload }: Answers) => {
      state.answers.qcm2 = payload;
    },
    setAnswer3: (state, { payload }: Answers) => {
      state.answers.qcm3 = payload;
    },
    setAnswer4: (state, { payload }: Answers) => {
      state.answers.qcm4= payload;
    },
    setAnswer5: (state, { payload }) => {
      state.answers.qcm5 = payload;
    },
    setAnswer6: (state, { payload }: Answers) => {
      state.answers.qcm6 = payload;
    },
    setAnswerBuy: (state, { payload }: Answers) => {
      state.answers.buy = payload;
    },
    setAnswerAnydesk: (state, { payload }: Answers) => {
      state.answers.anydesk = payload;
    },
    setAnswerAssisted: (state, { payload }: Answers) => {
      state.answers.assisted = payload;
    },
    setAnswerRisks: (state, { payload }: Answers) => {
      state.answers.risks = payload;
    },
    setLogOut: () => {
      return initialState;
    },
  },
});

export const {
  setEmail,
  setCountry,
  setAuth,
  setIsFirstEntry,
  setInitiateCorer,
  setStatut,
  setKyc,
  setSignature,
  setFirstName,
  setAnswer1,
  setAnswer2,
  setAnswer3,
  setAnswer4,
  setAnswer5,
  setAnswer6,
  setAnswerBuy,
  setAnswerAnydesk,
  setAnswerAssisted,
  setAnswerRisks,
  setLogOut,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
