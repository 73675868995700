import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import { Link } from "react-router-dom";
import plusIcon from "../static/icons/faq/plusIcon.png";
import plusIconWhite from "../static/icons/faq/plusIconWhite.png";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../providers/ThemeProvider"

export const FAQ = () => {
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);
  const [question5, setQuestion5] = useState(false);

  const { t } = useTranslation();

  const [theme, setTheme] = useContext(ThemeContext);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div className="container py-5 my-md-5">
        <p className="contact__title">FAQ</p>

        <div className="w-100 border-bottom border-dark mb-5"></div>

        <div className="d-flex">
          <span className="contact__subtitle">1.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">{t("faq.1.question")}</span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question1 ? "100px" : "0",
                opacity: question1 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              {t("faq.1.answer")}
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion1(!question1)}
          >
            <img
              src={theme === "light" ? plusIcon : plusIconWhite}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question1 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="d-flex">
          <span className="contact__subtitle">2.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">{t("faq.2.question")}</span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question2 ? "100px" : "0",
                opacity: question2 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              {t("faq.2.answer")}
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion2(!question2)}
          >
            <img
              src={theme === "light" ? plusIcon : plusIconWhite}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question2 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="d-flex">
          <span className="contact__subtitle">3.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">{t("faq.3.question")}</span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question3 ? "100px" : "0",
                opacity: question3 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              {t("faq.3.answer")}{" "}
              <Link to="/conditions" className="text-dark">
                {t("faq.3.link")}
              </Link>
              .
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion3(!question3)}
          >
            <img
              src={theme === "light" ? plusIcon : plusIconWhite}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question3 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="d-flex">
          <span className="contact__subtitle">4.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">{t("faq.4.question")}</span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question4 ? "100px" : "0",
                opacity: question4 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              {t("faq.4.answer")}
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion4(!question4)}
          >
            <img
              src={theme === "light" ? plusIcon : plusIconWhite}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question4 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="d-flex">
          <span className="contact__subtitle">5.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">{t("faq.5.question")}</span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question5 ? "100px" : "0",
                opacity: question5 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              {t("faq.5.answer")}{" "}
              <Link to="/conditions" className="text-dark">
                {t("faq.3.link")}
              </Link>
              .
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion5(!question5)}
          >
            <img
              src={theme === "light" ? plusIcon : plusIconWhite}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question5 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="w-100 border-bottom border-dark mt-5"></div>

        <p className="faq__message text-center mt-4">
        {t("faq.message")}
        </p>

        <div className="text-center mt-4">
          <Link
            to="/contactus"
            className="btn text-white fw-bols fs-3 rounded-5 px-4 py-1"
            style={{
              backgroundColor: "#DBB1F6",
            }}
          >
            {t("faq.button")}
          </Link>
        </div>
      </div>
    </Layout>
  );
};
