import React, { useState } from "react";
import { RegisterStep1 } from "../components/Registration/RegisterStep1";
import { RegisterStep2 } from "../components/Registration/RegisterStep2";
import { RegisterStep3 } from "../components/Registration/RegisterStep3";
import { RegisterStep4 } from "../components/Registration/RegisterStep4";

export const Registration = () => {
  const [step, setStep] = useState(1);

  return (
    <div
      className="d-flex flex-column h-100 w-100 m-0 p-0 position-relative"
      style={{ minHeight: "100vh", background: "#252430" }}
    >
      {step === 1 && <RegisterStep1 setStep={setStep} />}

      {step === 2 && <RegisterStep2 setStep={setStep} />}

      {step === 3 && <RegisterStep3 />}

      {step === 4 && <RegisterStep4 />}
    </div>
  );
};
