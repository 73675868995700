import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootState } from "../redux/store/store";

function useCheckSecurity() {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const { isFirstEntry, firstName } = useSelector(
    ({ authData }: IRootState) => authData.fetchAuth
  );

  useEffect(() => {
    if (firstName === "Prenom" || token?.length === 0) {
      setTimeout(() => {
        navigate("/signin");
      }, 100);

      return;
    } else if (isFirstEntry) {
      // cas ou la personne vient pour la premiere fois
      return;
    }

    return;
  }, [firstName, token, isFirstEntry]);
}

export default useCheckSecurity;
