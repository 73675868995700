import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { ThemeContext } from "../../providers/ThemeProvider"

const AdvancedChart = () => {
  const { cryptoName } = useParams();

  const [theme] = useContext(ThemeContext);

  return (
    <div
      className="mt-4 rounded-3 app__card border-color2"
    >
      <div
        className=""
        style={{ height: "500px", width: "100%" }}
      >
        <AdvancedRealTimeChart symbol={cryptoName} theme={theme} width="100%" autosize></AdvancedRealTimeChart>
      </div>
    </div>
  );
};

export default AdvancedChart;
