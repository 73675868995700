import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import arrowBack from "../static/icons/signIn/arrowBack.svg";
import cornerLogo from "../static/icons/CornerSwap_Logo.svg";
import handIcon from "../static/icons/signIn/handIcon.png";
import googleIcon from "../static/icons/signIn/googleIcon.png";
import globeUp from "../static/icons/signIn/globeUp.png";
import globeDown from "../static/icons/signIn/globeDown.png";
import { useTranslation } from "react-i18next";
import { LanguagesBar } from "../components/Navbar/LanguagesBar";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAuth } from "../redux/slices/auth.slice";
import config from "../config";

interface IFormValide {
  email: string;
  password: string;
}

export const SignIn = () => {
  const [visible, setVisible] = useState(false);
  const [visibleGlob, setVisibleGlob] = useState(false);
  const [typePassword, setTypePassword] = useState(true);
  const [loader, setLoader] = useState(false);
  const [errorLogIn, setErrorLogIn] = useState(false);
  const [fetchError, setFetchError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setVisibleGlob(true);
    }, 100);
  }, []);

  const handleButtonClick = () => {
    setVisible(false);
    setVisibleGlob(false);

    setTimeout(() => {
      navigate("/signup");
    }, 1000);
  };

  const handleHome = () => {
    setVisible(false);
    setVisibleGlob(false);

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const handleRecovery = () => {
    setVisible(false);
    setVisibleGlob(false);

    setTimeout(() => {
      navigate("/recovery");
    }, 1000);
  };

  const schema = yup.object().shape({
    email: yup.string().required().email("Invalid email"),
    password: yup.string().required(),
  });

  const ValideForm = (obj: IFormValide) => {
    setLoader(true);
    setErrorLogIn(false);
    localStorage.setItem("email", obj.email);
    axios
      .post(`${config.apiURI}/users/login`, obj)
      .then((response) => {
        if (response.status === 200) {
          const { qcm, token } = response.data;

          qcm === 1 && dispatch(setAuth(response.data));

          localStorage.setItem("token", token);

          setLoader(false);

          setVisible(false);

          setTimeout(() => {
            navigate(qcm === 1 ? "/home" : `/form/${token}`);
          }, 1000);
        } else {
          setLoader(false);
          setErrorLogIn(true);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorLogIn(true);
      });
  };

  const handleGoogle = () => {
    setFetchError("")
    fetch(`${config.apiURI}/users/auth/google`)
      .then((res) => res.json())
      .then((data) => {
        setVisible(false);
        setVisibleGlob(false);
        setTimeout(() => {
          window.location.href = data.url;
        }, 1000);
      }).catch((error) => {
        error.message === "Failed to fetch" && setFetchError("error server")
      });
  };

  return (
    <div
      className="d-flex flex-column h-100 w-100 m-0 p-0 position-relative"
      style={{ minHeight: "100vh", background: "#23222E" }}
    >
      <div
        className="p-0 m-0 position-absolute h-100 w-100 top-0 start-0 d-flex flex-column justify-content-between"
        style={{
          zIndex: 0,
          opacity: visibleGlob ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div className="w-50 h-50">
          <img
            src={globeUp}
            alt="globeUp"
            style={{
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>

        <div className="w-100 text-end signin__icon-globe">
          <img
            src={globeDown}
            alt="globeDown"
            style={{
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-between align-items-center py-2 py-lg-3 px-m-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid rgba(95, 95, 95, 1)",
          zIndex: 1,
          opacity: visible ? 1 : 0,
          transform: `scale(${visible ? 1 : 0.8})`,
          transition: "all 1.5s",
        }}
      >
        <button
          onClick={handleHome}
          className="text-white bg-transparent border-0 d-flex align-items-center signin--text-size"
        >
          <img src={arrowBack} alt="arrowBack" height={12} />
          <span className="ms-sm-4 ps-2">{t("logIn.back")}</span>
        </button>

        <div className="d-flex">
          <LanguagesBar classProp="me-3" />

          <button
            className="text-white bg-transparent border-0 text-decoration-none d-flex align-items-center signin--text-size"
            onClick={handleButtonClick}
          >
            {t("logIn.createAcc")}
          </button>
        </div>
      </div>

      <div
        className="container d-flex flex-column my-auto"
        style={{
          zIndex: 1,
          opacity: visible ? 1 : 0,
          transform: `scale(${visible ? 1 : 0.8})`,
          transition: "all 1.5s",
        }}
      >
        <div className="text-center d-none d-md-block">
          <img src={cornerLogo} alt="cornerLogo" height={85} />
        </div>

        <div className="text-center d-md-none">
          <img src={cornerLogo} alt="cornerLogo" height={70} />
        </div>

        <div className="d-flex align-items-center justify-content-center mt-2">
          <span className="fs-2 fw-semibold text-white me-2">
            {t("logIn.greatSee")}
          </span>
          <img src={handIcon} alt="handIcon" height={27} />
        </div>

        <div className="row m-0 p-0 justify-content-center my-4 my-md-5">
          <div className="col-11 col-md-8 col-lg-4 p-0 d-flex flex-column justify-content-center mb-lg-5">
            <p className="text-center text-white fs-3 fw-semibold m-0 mb-md-3">
              {t("navBar.signIn")}
            </p>

            <Formik
              validationSchema={schema}
              onSubmit={ValideForm}
              initialValues={{ password: "", email: "" }}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form
                  className="mb-5 mb-lg-0 my-3 text-center"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Row className="mb-2 mb-md-4 signin__button--size mx-auto">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationFormik03"
                      className="p-0"
                    >
                      <Field
                        className={`form-control signin--text-size px-4 py-2 rounded-5 signin__button--size ${
                          errors.email &&
                          touched.email &&
                          "border border-danger"
                        } ${!errors.email && touched.email && "border border-success"}`}
                        style={{
                          boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                          border: "1px solid transparent",
                          background: "#fff",
                        }}
                        id="email"
                        name="email"
                        value={values.email}
                        placeholder={t("logIn.email")}
                        type="email"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationFormik03"
                      className="p-0"
                    >
                      <div className="position-relative d-flex mb-3">
                        <Field
                          className={`form-control signin--text-size px-4 py-4 py-lg-3 rounded-5 mb-0 pe-5 signin__button--size ${
                            errors.password &&
                            touched.password &&
                            "border border-danger"
                          }  ${!errors.password && touched.password && "border border-success"}`}
                          style={{
                            boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                            border: "1px solid transparent",
                            background: "#fff",
                          }}
                          id="password"
                          name="password"
                          value={values.password}
                          placeholder={t("logIn.password")}
                          type={typePassword ? "password" : "text"}
                          onChange={handleChange}
                        />
                        <span
                          className="signin__icon position-absolute top-50 end-0 translate-middle-y me-4"
                          style={{
                            backgroundColor: "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => setTypePassword(!typePassword)}
                        >
                          <img
                            src={
                              typePassword
                                ? "https://cdn1.iconfinder.com/data/icons/hawcons/32/699007-icon-21-eye-hidden-512.png"
                                : "https://www.freeiconspng.com/thumbs/eye-icon/eyeball-icon-png-eye-icon-1.png"
                            }
                            alt="eye icon"
                            style={{
                              width: "100%",
                            }}
                          />
                        </span>
                      </div>
                    </Form.Group>
                  </Row>

                  <Button
                    type="submit"
                    className="signin__button signin__button--size mx-auto w-100 px-4 border-0 py-2 rounded-5"
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      t("navBar.signIn")
                    )}
                  </Button>

                  {
                    fetchError && (
                      <p
                      className={`text-center m-0 mt-2 text-danger `}
                      // className="text-center m-0 mt-2 text-danger"
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Error Server
                    </p>
                    )
                  }

                  <p
                    className={`text-center m-0 mt-2 ${errorLogIn ? "text-danger" : "text-transparent"}`}
                    // className="text-center m-0 mt-2 text-danger"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Incorrect Email or Password
                  </p>

                  <p
                    className="d-lg-none text-center m-0 mt- text-white"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {t("logIn.forgot.1")}{" "}
                    <Link to="/signin" className="text-white">
                      {t("logIn.forgot.click")}
                    </Link>{" "}
                    {t("logIn.forgot.2")}
                  </p>
                </Form>
              )}
            </Formik>
          </div>

          <div className="col-11 col-md-8 col-lg-1 d-flex flex-lg-column justify-content-between justify-content-lg-center align-items-center signin--text-size">
            <div
              className="d-none d-lg-flex"
              style={{
                borderRight: "1px solid #FFF",
                height: "120px",
                width: "1px",
              }}
            />

            <div
              className="d-flex d-lg-none"
              style={{
                borderBottom: "1px solid #FFF",
                height: "1px",
                width: "45%",
              }}
            />

            <p className="my-2 text-white">{t("logIn.or")}</p>

            <div
              className="d-none d-lg-flex"
              style={{
                borderRight: "1px solid #FFF",
                height: "120px",
                width: "1px",
              }}
            />

            <div
              className="d-flex d-lg-none"
              style={{
                borderBottom: "1px solid #FFF",
                height: "1px",
                width: "45%",
              }}
            />
          </div>

          <div className="col-11 col-md-8 col-lg-4 d-flex flex-column justify-content-center p-0 pb-4 signin--text-size">
            <Button
              className="d-flex justify-content-between align-items-center bg-white px-3 py-2 rounded-5 text-decoration-none mt-4 signin__button--size mx-auto border-0"
              style={{
                boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                color: "rgba(0, 0, 0, 0.5)",
              }}
              onClick={handleGoogle}
            >
              <div className="signin__icon">
                <img
                  src={googleIcon}
                  alt="googleIcon"
                  style={{
                    width: "100%",
                  }}
                />
              </div>

              <span>{t("logIn.continue")} Google</span>

              <div></div>
            </Button>

            {/* <Link
              to="/signin"
              className="d-flex align-items-center bg-white w-100 px-3 py-2 rounded-5 text-decoration-none mt-3 signin__button--size mx-auto"
              style={{
                boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="signin__icon">
                <img
                  src={facebookIcon}
                  alt="facebookIcon"
                  style={{
                    width: "100%",
                  }}
                />
              </div>

              <span className="ps-5">{t("logIn.continue")} Facebook</span>
            </Link> */}
          </div>
        </div>

        <p className="d-none d-lg-block text-center signin--text-size text-white">
          {t("logIn.forgot.1")}{" "}
          <button
            onClick={handleRecovery}
            className="bg-transparent border-0 text-decoration-underline text-white"
          >
            {t("logIn.forgot.click")}
          </button>{" "}
          {t("logIn.forgot.2")}
        </p>
      </div>
    </div>
  );
};
