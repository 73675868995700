import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { LanguagesBar } from "../components/Navbar/LanguagesBar";
import { useTranslation } from "react-i18next";
import step2Bg from "../static/images/Register/step2Bg.png";
import { Button } from "react-bootstrap";
import axios from "axios";
import config from "../config";
import { setAuth } from "../redux/slices/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../redux/store/store";

export const AuthPage = () => {
  const { t } = useTranslation();

  const { token } = useParams<{ token: string }>();

  const [visible, setVisible] = useState(false);
  const [visibleGlob, setVisibleGlob] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);

  const fetchAuth = useSelector(
    ({ authData }: IRootState) => authData.fetchAuth
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    token && localStorage.setItem("token", token);

    setTimeout(() => {
      setVisible(true);
      setVisibleGlob(true);
    }, 100);

    handleClick();
  }, []);

  const handleSingIn = () => {
    setVisible(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  const handleClick = () => {
    setErrorLogin(false);

    axios
      .post(
        `${config.apiURI}/users/user_information`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const { qcm, token } = response.data;

          qcm === 1 && dispatch(setAuth(response.data));

          setTimeout(() => {
            setVisible(false);
          }, 1000);

          setTimeout(() => {
            navigate(qcm === 1 ? "/home" : `/form/${token}`);
          }, 2000);
        } else {
          setErrorLogin(true);
        }
      })
      .catch(() => setErrorLogin(true));
  };

  return (
    <div
      className="d-flex flex-column h-100 w-100 m-0 p-0 position-relative"
      style={{
        minHeight: "100vh",
        background: "#252430",
      }}
    >
      <div
        className="d-flex justify-content-end align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div className="d-flex align-items-center text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <span>{t("register.step2.haveAcc")}</span>

          <button
            onClick={handleSingIn}
            className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div className="container d-flex flex-column text-light pt-5 position-relative">
          <h1 className="text-center mb-5">{t("logIn.welcome")}CornerSwap</h1>

          {errorLogin ? (
            <Button
              type="button"
              onClick={handleClick}
              className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4 mx-auto"
              style={{
                zIndex: 1,
              }}
            >
              Try again
            </Button>
          ) : (
            <div className="d-flex justify-content-center">
              <PuffLoader color="#DBB1F6" size={100} />
            </div>
          )}

          <div
            className="register__step2 d-flex align-items-center justify-content-center"
            style={{
              opacity: visibleGlob ? 1 : 0,
              transition: "all 1.5s",
              zIndex: 0,
            }}
          >
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
