import React, { useState } from "react";
import LayoutApp from "../../components/Application/LayoutApp";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import { useTranslation } from "react-i18next";

export const Settings = () => {
  const { t } = useTranslation();

  const [googleAuth, setGoogleAuth] = useState(false);
  const [emailVerification, setEmailVerification] = useState(true);

  const { email } = useSelector(({ authData }: IRootState) => authData.fetchAuth);

  return (
    <LayoutApp>
      <p
        className="app__home-title mb-4 border-color mx-xl-2"
        style={{
          fontFamily: "Inter 500",
        }}
      >
        {t("app.settings.title")}
      </p>

      <p className="app__settings-title px-2 px-xl-3 ">
        {t("app.settings.accInfo")}
      </p>

      <div
        className="app__card rounded-4 mx-xl-2 mb-2 p-2 pt-md-3 px-md-3 pb-5"
      >
        <div className="row p-0 m-0 app__settings-title--sub mb-3">
          <div className="col-6 col-md-5 col-lg-4 p-0">
            {t("app.settings.email")}
          </div>
          <div className="col-6 col-md-5 col-lg-4">{email}</div>
        </div>

        <p className="app__settings-content pb-2">
          {t("app.settings.updateEmail.1")}{" "}
          <span className="fw-bold">{t("app.settings.updateEmail.2")}</span>
          {t("app.settings.updateEmail.3")}.
        </p>

        <div
          className="p-0 my-4"
          style={{
            borderBottom: "1px solid #969696",
          }}
        />

        <p className="app__settings-subtitle">{t("app.settings.security")}</p>

        <div className="row p-0 w-100">
          <div
            className="col-9 col-md-6"
            style={{
              flexGrow: 1,
            }}
          >
            <div className="row p-0 m-0 app__settings-title--sub mb-3">
              <div className="col-7 col-md-8 p-0">{t("app.settings.googleAuth")}</div>

              <div className="col-5 col-md-4 p-0">
                {t("app.settings.status")}{" "}
                <span
                  style={{
                    color: googleAuth ? "#47B920" : "#F41616",
                  }}
                >
                  {googleAuth
                    ? t("app.settings.active")
                    : t("app.settings.inactive")}
                </span>
              </div>
            </div>

            <p className="p-0 m-0 app__settings-content">
              {t("app.settings.googleSub")}
            </p>
          </div>

          <div className="col-3 col-md-3 text-end p-0">
            <button
              className="app__settings-button border-0 rounded-5 px-4"
              onClick={() => setGoogleAuth(!googleAuth)}
            >
              {googleAuth
                ? t("app.settings.disable")
                : t("app.settings.enable")}
            </button>
          </div>
        </div>

        <div
          className="p-0 my-4"
          style={{
            borderBottom: "1px solid #969696",
          }}
        />

        <div>
          <div className="row p-0 m-0 app__settings-title--sub mb-3">
            <div className="col-6 p-0">{t("app.settings.emailVerif")}</div>

            <div className="col-6 p-0">
              {t("app.settings.status")}{" "}
              <span
                style={{
                  color: emailVerification ? "#47B920" : "#F41616",
                }}
              >
                {emailVerification
                  ? t("app.settings.active")
                  : t("app.settings.inactive")}
              </span>
            </div>
          </div>

          <p className="app__settings-content pb-2">
            {t("app.settings.updateEmail.1")}{" "}
            <span className="fw-bold">{t("app.settings.updateEmail.2")}</span>
            {t("app.settings.updateEmail.3")}.
          </p>
        </div>

        <div
          className="p-0 my-4"
          style={{
            borderBottom: "1px solid #969696",
          }}
        />

        <div className="d-flex w-100">
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <span className="col-6 p-0 app__settings-title--sub">
              {t("app.settings.password")}
            </span>

            <p className="p-0 m-0 app__settings-content mt-2">
              {t("app.settings.useGoogleAuth")}
            </p>
          </div>

          <button className="app__settings-button border-0 rounded-5 px-4">
            {t("app.settings.set")}
          </button>
        </div>
      </div>
    </LayoutApp>
  );
};
