import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import "./Creation.css";
import { useTranslation } from "react-i18next";
import { PaginateList } from "../../components/Application/OrderHistory/PaginateList";
import LayoutApp from "../../components/Application/LayoutApp";
import config from "../../config"

interface IRow {
  o_currency: any;
  timestamp: string;
  i_amount: any;
  i_currency: string;
  reference: string;
  status: string;
  idOrders: string;
  founds_sent: string;
}

const OrderHistory = () => {
  const path = useLocation().pathname;
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowOrders, setRowOrders] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const pages: IRow[] = [];

  for (let i = 0; i < rowOrders.length; i += rowsPerPage) {
    //@ts-ignore
    pages.push(rowOrders.slice(i, i + rowsPerPage));
  }

  useEffect(() => {
    FetchListOrder();
  }, []);

  const FetchListOrder = () => {
    const objForCreateSdkToken = {
      applicant_id: "",
      limit: path === "/home" ? 3 : 100,
    };

    axios
      .post(`${config.apiURI}/order/listorder`, objForCreateSdkToken, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setIsLoader(false);
          setRowOrders(response.data);
        }
      })

      .catch(function (error) {
        setIsLoader(false);
      });
  };

  return (
    <>
      <LayoutApp>
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="row col-12 m-0 p-lg-2">
            <p
              className="app__home-title border-color"
              style={{
                fontFamily: "Inter 500",
              }}
            >
              {t("app.orderHistory.title")}
            </p>

            {isLoader ? (
              <div
                className="border rounded d-flex flex-column"
                style={{
                  boxShadow: "0px 0px 4px 2px rgba(0,0,0,0.1)",
                  height: "100px",
                  marginTop: "20px",
                }}
              >
                <div className="m-1 placeholder col-5"></div>
                <div className="m-1 placeholder col-7  placeholder-lg"></div>
                <div className="m-1 placeholder col-8"></div>
                <div className="m-1 placeholder col-4"></div>
              </div>
            ) : (
              <>
                {rowOrders.length === 0 ? (
                  <div
                    className="app__card p-3 rounded-4 centrer"
                    style={{
                      height: "100px",
                      // marginTop: "20px",
                    }}
                  >
                    {t("app.orderHistory.noActive")}
                  </div>
                ) : (
                  <div className="m-0 p-0 px-2">
                    <div style={{ fontSize: "14px" }}>
                      {//@ts-ignore
                      pages[currentPage - 1]?.map((row, index) => (
                        <div key={index}>
                          {!!row.o_currency && (
                            <div
                              className="d-flex justify-content-between app__card rounded-4 h-100 px-4 py-2 py-xl-3 my-3 my-xl-0 mb-xl-2"
                            >
                              <div className="w-100">
                                <div className="fw-bold">
                                  {row?.timestamp?.substring(0, 10)}{" "}
                                  {row?.timestamp?.substring(11, 16)}
                                </div>

                                <div className="d-flex justify-content-between w-100">
                                  <div className="d-flex align-items-center my-2">
                                    <div className="me-2">
                                      {row?.i_amount.toLocaleString("de-DE")}
                                      ,00
                                    </div>

                                    <div className="py-2">
                                      {row?.i_currency}
                                    </div>

                                    <div className="d-flex align items-center">
                                      {row?.o_currency ? (
                                        <img
                                          src={`https://assets.kraken.com/marketing/web/icons/sym-${row.o_currency.toLocaleLowerCase()}_colored.svg`}
                                          alt=""
                                          className="mx-2"
                                          style={{
                                            height: "22px",
                                            width: "22px",
                                          }}
                                        />
                                      ) : (
                                        <div className="text-center mt-3"></div>
                                      )}
                                      {row?.o_currency}
                                    </div>
                                  </div>

                                  <div className="py-1 d-flex align-items-center">
                                    {row?.status === "0" && (
                                      <button
                                        className="btn btn-stop"
                                        style={{
                                          borderRadius: 50,
                                          fontSize: 12,
                                        }}
                                      >
                                        {t("app.orderHistory.canceled")}
                                      </button>
                                    )}

                                    {row?.status === "1" && (
                                      <button
                                        className="btn btn-waiting"
                                        style={{
                                          borderRadius: 50,
                                          fontSize: 12,
                                        }}
                                      >
                                        {t("app.orderHistory.progress")}
                                      </button>
                                    )}

                                    {row?.status === "2" && (
                                      <button
                                        className="btn btn-traitement"
                                        style={{
                                          borderRadius: 50,
                                          fontSize: 12,
                                        }}
                                      >
                                        {t("app.orderHistory.progressing")}
                                      </button>
                                    )}

                                    {row?.status === "3" && (
                                      <button
                                        className="btn btn-traitement"
                                        style={{
                                          borderRadius: 50,
                                          fontSize: 12,
                                        }}
                                      >
                                        {t("app.orderHistory.progressing")}
                                      </button>
                                    )}
                                    {row?.status === "4" && (
                                      <button
                                        className="btn btn-verif"
                                        style={{
                                          borderRadius: 50,
                                          fontSize: 12,
                                        }}
                                      >
                                        {t("app.orderHistory.verificate")}{" "}
                                      </button>
                                    )}
                                    {row?.status === "5" && (
                                      <button
                                        className="btn btn-termine"
                                        style={{
                                          borderRadius: 50,
                                          fontSize: 12,
                                        }}
                                      >
                                        {t("app.orderHistory.completed")}
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <div className="d-flex me-3">
                                  {row?.reference !== "" ? (
                                    <>
                                      <div className="fw-bold">
                                        {t("app.orderHistory.reference")}
                                      </div>
                                      <div className="ms-2">
                                        {row?.reference}
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          {rowOrders.length > 0 && (
            <PaginateList
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              rowsLength={rowOrders.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </LayoutApp>
    </>
  );
};

export default OrderHistory;
