import React, { useEffect } from "react";
import { Layout } from "../components/Layout";
import SymbolInfo from "../components/CryptoInfo/symbolInfo";
import AdvancedChart from "../components/CryptoInfo/advancedChart";
import TechnicalAnalysisWidget from "../components/CryptoInfo/technicalAnalysis";
import TopStories from "../components/CryptoInfo/topStories";
import MarketCrypto from "../components/CryptoInfo/marketCrypto";
import OrderWindow from "../components/CryptoInfo/OrderWindow";
import CompanyProfileWidget from "../components/CryptoInfo/companyProfile";
import { useParams } from "react-router"

const CryptoInfo = () => {
  const { cryptoName } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
  }, [cryptoName]);

  return (
    <Layout>
      <div className="container my-5">
        <div className="row p-0 m-0">
          <div className="d-lg-none mb-3 p-0">
            <OrderWindow />
          </div>

          <div className="col-12 col-lg-9 p-0 pe-lg-3">
            <SymbolInfo />

            <AdvancedChart />

            <CompanyProfileWidget />

            <div className="row mt-3 p-0 pt-2">
              <div className="col-12 col-md-6" style={{ height: "700px" }}>
                <TechnicalAnalysisWidget />
              </div>

              <div className="col-12 col-md-6">
                <TopStories />
              </div>
            </div>

            <MarketCrypto />
          </div>

          <div className="col-3 d-none d-lg-flex position-relative p-0 pt-2">
            <div
              className="sticky-top w-100"
              style={{
                height: "max-content",
                top: "90px",
                zIndex: "999",
              }}
            >
              <OrderWindow />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CryptoInfo;
