import React from "react";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";
import LayoutApp from "../../components/Application/LayoutApp";

import avalancheCard from "../../static/images/App/explore/avalanche.png";
import binanceCard from "../../static/images/App/explore/binance.png";
import bitcoinCard from "../../static/images/App/explore/bitcoin.png";
import cardanoCard from "../../static/images/App/explore/cardano.png";
import dogeCard from "../../static/images/App/explore/doge.png";
import etherCard from "../../static/images/App/explore/ether.png";
import maticCard from "../../static/images/App/explore/matic.png";
import rippleCard from "../../static/images/App/explore/ripple.png";
import shibaCard from "../../static/images/App/explore/shiba.png";
import solanaCard from "../../static/images/App/explore/solana.png";
import sushiCard from "../../static/images/App/explore/shiba.png";
import tetherCard from "../../static/images/App/explore/tether.png";
import tronCard from "../../static/images/App/explore/tron.png";
import usdcCard from "../../static/images/App/explore/usdc.png";
import { Link } from "react-router-dom";

const cardList = [
  {
    image: bitcoinCard,
    url: "/cryptoInfo/BTCEUR",
  },
  {
    image: etherCard,
    url: "/cryptoInfo/ETHEUR",
  },
  {
    image: binanceCard,
    url: "/cryptoInfo/BNBEUR",
  },
  {
    image: cardanoCard,
    url: "/cryptoInfo/ADAEUR",
  },
  {
    image: rippleCard,
    url: "/cryptoInfo/XRPEUR",
  },
  {
    image: tetherCard,
    url: "/cryptoInfo/USDTEUR",
  },
  {
    image: dogeCard,
    url: "/cryptoInfo/DOGEEUR",
  },
  {
    image: avalancheCard,
    url: "/cryptoInfo/AVAXEUR",
  },
  {
    image: solanaCard,
    url: "/cryptoInfo/SOLEUR",
  },
  {
    image: tronCard,
    url: "/cryptoInfo/TRXEUR",
  },
  {
    image: maticCard,
    url: "/cryptoInfo/MATICEUR",
  },
  {
    image: sushiCard,
    url: "/cryptoInfo/SUSHIEUR",
  },
  {
    image: shibaCard,
    url: "/cryptoInfo/SHIBEUR",
  },
  {
    image: usdcCard,
    url: "/cryptoInfo/USDCEUR",
  },
];

export const Explore = () => {
  const { t } = useTranslation();

  return (
    <LayoutApp>
      <p
        className="app__home-title mb-4 border-bottom border-dark mx-xl-2"
        style={{
          fontFamily: "Inter 500",
        }}
      >
        {t("app.explore.title")}
      </p>

      <div className="row p-0 m-0">
        {cardList.map((card, index) => (
          <div
            key={index}
            className="text-center col-12 col-sm-6 col-md-4 col-xxl-3 mb-4 py-2 position-relative"
          >
            <img
              src={card.image}
              alt="card"
              className="rounded-4"
              style={{
                boxShadow: "0px 3.5px 3.5px 0px rgba(0, 0, 0, 0.25)",
              }}
            />

            <Link
              to={`/explore${card.url}` || "/explore/cryptoInfo/ETHUSD"}
              className="d-block mt-2 rounded-4 ms-4 px-3 py-1 app__explore-button position-absolute"
              style={{
                color: "#8C8C8C",
                border: "1px solid #8C8C8C",
              }}
            >
              {t("app.explore.button")}
            </Link>
          </div>
        ))}
      </div>
    </LayoutApp>
  );
};
