import { IStyleReducer, styleReducer } from './../slices/style';
import { combineReducers } from "redux";
import { ILangIcon, langIconReducer } from "../slices/language.slice";
import { IOrderSlice, orderReducer } from "../slices/order.slice";
import { IAuth, authReducer } from '../slices/auth.slice'

export interface IRootState {
  languageData: ILangIcon;
  orderData: IOrderSlice;
  styleData: IStyleReducer;
  authData: IAuth;
}

const rootReducer = combineReducers({
  languageData: langIconReducer,
  orderData: orderReducer,
  styleData: styleReducer,
  authData: authReducer,
});

export default rootReducer;
