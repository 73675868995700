import React from "react";
import { Link } from "react-router-dom";
import businessHeader from "../../static/images/business/businessHeader.png";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const { t } = useTranslation();

  return (
    <div
      className="position-relative"
      style={{ overflow: "hidden", marginBottom: "96px" }}
    >
      <div className="d-none d-lg-block business__header-image h-100 position-absolute end-0 top-0 mt-xxl-4"></div>

      <div className="container mx-auto">
        <div className="d-lg-none">
          <img
            src={businessHeader}
            alt="businessHeader"
            style={{
              width: "100%",
            }}
          />
        </div>

        <div className="row m-0 pt-5">
          <div className="col-12 col-lg-6 col-xxl-5 d-sm-grid gap-4 p-0">
            <div
              className="d-flex align-items-center ps-1 pe-2 py-1 rounded-2 business__header-name"
            >
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.625 33.125C26.3235 33.125 33.375 26.0735 33.375 17.375C33.375 8.67652 26.3235 1.625 17.625 1.625C8.92652 1.625 1.875 8.67652 1.875 17.375C1.875 26.0735 8.92652 33.125 17.625 33.125Z"
                  stroke="orange"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M15 11.75V18.5H20.25C21.9068 18.5 23.25 16.9889 23.25 15.125C23.25 13.2611 21.9068 11.75 20.25 11.75H18.75M15 11.75H12M15 11.75V8.375M15 11.75H18.75M18.75 11.75V8.375"
                  stroke="orange"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M14.9118 23.75V18.5H21.4632C23.0713 18.5 24.375 19.6753 24.375 21.125C24.375 22.5747 23.0713 23.75 21.4632 23.75H18.5515M14.9118 23.75V26.375M14.9118 23.75H12H18.5515M14.9118 23.75H18.5515M18.5515 23.75V26.375"
                  stroke="orange"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>

              <span className="ps-3">
                {t("business.header.name")}
              </span>
            </div>

            <p className="business__header-title">
              {t("business.header.title")}
            </p>

            <p className="business__text-color">
              {t("business.header.sub")}
            </p>

            <Link
              to="/contactus"
              className="d-block text-white business__header-button py-3 rounded-5"
              style={{
                width: "max-content",
              }}
            >
              {t("business.header.button")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
