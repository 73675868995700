import React from "react";
import rocket from "../../static/icons/onRamp/rocket.png";
import iphoneIpad from "../../static/icons/onRamp/iphoneIpad.png";
import clock from "../../static/icons/onRamp/clock.png";
import time from "../../static/icons/onRamp/time.png";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import { useTranslation } from "react-i18next";

export const Potential = () => {
  const { t } = useTranslation();

  const { color3 } = useSelector(({ styleData }: IRootState) => styleData);

  return (
    <div className="container">
      <p
        className="onramp__title--size text-center home__background-text mx-auto my-5 py-md-5"
        style={{
          maxWidth: "1049px",
        }}
      >
        {t("onRamp.potential.title.1")}{" "}
        <span
          className="px-1"
          style={{
            background: color3,
            color: "white",
          }}
        >
          {t("onRamp.potential.title.2")}
        </span>{" "}
        <img src={rocket} alt="rocket" className="onramp__card-icon" />
      </p>

      <div className="w-100 onramp__card--buy rounded-5 p-4 p-md-5 text-center text-md-start">
        <p className="onramp__title--size m-0 home__background-text">{t("onRamp.potential.sub")}</p>

        <p
          className="mt-3 mt-md-4 onramp__buy-text"
          style={{
            color: "#989898",
            maxWidth: "1049px",
          }}
        >
          {t("onRamp.potential.text")}
        </p>

        <div className="row m-0 p-0">
          <div className="col-12 col-md-6 d-flex">
            <div className="w-100 d-flex align-items-end py-5 ">
              <img src={clock} alt="clock" style={{ width: "25%" }} />
              <img
                src={time}
                alt="time"
                style={{
                  marginLeft: "10px",
                  width: "60%",
                }}
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <img src={iphoneIpad} alt="iphoneIpad" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
