import React, { useContext, useEffect } from "react";
import LayoutApp from "../../components/Application/LayoutApp";
import { useDispatch, useSelector } from "react-redux";
import { Market } from "../../components/Application/Dashboard/Markets/Market";
import { Transactions } from "../../components/Application/Dashboard/Transactions";
import { ExchangeHome } from "../../components/Application/Dashboard/Exchange/ExchangeHome";
import { useTranslation } from "react-i18next";
import { currencies } from "../../components/Application/Trade/ExchangeTrade";
import { setInputCurrencyBuy } from "../../redux/slices/order.slice";
import { ThemeContext } from "../../providers/ThemeProvider";
import { SliderCharts } from "../../components/Application/Dashboard/SliderCharts"
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets"
import { IRootState } from "../../redux/store/store"

const chartsList = ["BINANCE:BTCEUR", "COINBASE:ETHEUR", "COINBASE:SOLEUR"];

const Dashboard = () => {
  const { t } = useTranslation();

  const [theme] = useContext(ThemeContext);

  const dispatch = useDispatch();

  useEffect(() => {
    const currency = localStorage.getItem("currency");

    if (currency) {
      const newCurrency = currencies.find((cur) => cur.maj === currency);

      newCurrency && dispatch(setInputCurrencyBuy(newCurrency));
    }
  }, []);

  return (
    <LayoutApp>
      <div className="ps-0 ps-xl-0">
        <div className="row p-lg-0 m-0">
          <div className="col-12 col-xl-8 p-0 pe-lg-4">
            <p className="app__home-title mb-1 border-color">
              {t("app.nav.home")}
            </p>
          </div>
        </div>

        <div className="row justify-content-between pt-5 m-0 mb-4 mb-md-0">
          <div className="col-12 col-xl-8 p-0 pe-xl-4 pb-2">
            <Market />

            <SliderCharts displayCount={1}>
              {chartsList.map((chart) => (
                <div
                  className="rounded-4 w-100 app__card"
                  style={{
                    overflow: "hidden",
                    height: "400px",
                  }}
                  key={chart}
                >
                  <AdvancedRealTimeChart
                    symbol={chart}
                    theme={theme}
                    autosize
                  ></AdvancedRealTimeChart>
                </div>
              ))}
            </SliderCharts>
          </div>

          <div className="col-12 col-xl-4 p-0 ps-xl-3 pt-4 pt-xl-0 pe-lg-2">
            <div className="row m-0 p-0">
              <div className="col-12 col-md-6 col-xl-12 p-0 px-md-1 order-1 order-md-0">
                <Transactions />
              </div>

              <div className="col-12 col-md-6 col-xl-12 p-0 px-md-1">
                <ExchangeHome />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutApp>
  );
};

export default Dashboard;
