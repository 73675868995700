import React, { useEffect, useState } from "react";
import step2Bg from "../static/images/Register/step2Bg.png";
import Step1 from "../components/FormInscription/Step1";
import Step2 from "../components/FormInscription/Step2";
import Step3 from "../components/FormInscription/Step3";
import Step4 from "../components/FormInscription/Step4";
import { LanguagesBar } from "../components/Navbar/LanguagesBar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Step5 from "../components/FormInscription/Step5";
import Step6 from "../components/FormInscription/Step6";
import Step7 from "../components/FormInscription/Step7";

export const AnswersStepRegistration = () => {
  const { t } = useTranslation();

  const [stepQuest, setStepQuest] = useState(1);
  const [visible, setVisible] = useState(false);
  const [visibleGlob, setVisibleGlob] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setVisibleGlob(true);
    }, 100);
  }, []);

  const handleSingIn = () => {
    setVisible(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  return (
    <div
      className="d-flex flex-column h-100 w-100 m-0 p-0 position-relative"
      style={{ minHeight: "100vh", background: "#252430" }}
    >
      <div
        className="d-flex justify-content-end align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div className="d-flex align-items-center text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <span>{t("register.step2.haveAcc")}</span>

          <button
            onClick={handleSingIn}
            className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
        }}
      >
        <div className="container d-flex flex-column text-light pt-5 position-relative">
          <h1
            className="text-center mb-5"
            style={{
              opacity: visible ? 1 : 0,
              transition: "all 1.5s",
            }}
          >
            {t("form.qcmTitle")}
          </h1>

          {stepQuest === 1 && <Step1 stateStep={setStepQuest} />}

          {stepQuest === 2 && <Step2 stateStep={setStepQuest} />}

          {stepQuest === 3 && <Step3 stateStep={setStepQuest} />}

          {stepQuest === 4 && <Step4 stateStep={setStepQuest} />}

          {stepQuest === 5 && <Step5 stateStep={setStepQuest} />}

          {stepQuest === 6 && <Step6 stateStep={setStepQuest} />}

          {stepQuest === 7 && (
            <Step7
              setVisibleBg={setVisible}
              setVisibleGlobBg={setVisibleGlob}
            />
          )}

          <div
            className="register__step2 d-flex align-items-center justify-content-center"
            style={{
              opacity: visibleGlob ? 1 : 0,
              transition: "all 1.5s",
              zIndex: 0,
            }}
          >
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
