import { Tooltip } from "react-bootstrap";

const RenderTooltip = (props: any) => (
  <>
    <Tooltip id="button-tooltip" {...props}>
      Copied
    </Tooltip>
  </>
);

export default RenderTooltip;
