const config = {
  local: {
    apiURI: 'http://localhost:8000',
    apiHOST: 'http://localhost:3000',

  },

  prod: {
    apiURI: 'https://w73384a20f60434b90461f1868172d15-pcz4o.ondigitalocean.app',
    apiHOST: 'https://cornerswap.io',

  },
};

module.exports = config[process.env.REACT_APP_CONFIG];