import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import config from "../../config";

interface IGlobalEtape1of3 {
  stateStep: (el: number) => void;
  handleSingIn: (el: string) => void;
}

const GlobalEtape1of3: React.FC<IGlobalEtape1of3> = ({
  stateStep,
  handleSingIn,
}) => {
  let { token } = useParams();
  const [errorMessage, setErrorMessage] = useState(false);

  const handleClick = () => {
    axios
      .get(`${config.apiURI}/users/mail`, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.status === 200 && handleSingIn("/answersReg"))
      // .then((response) => response.status === 200 && stateStep(1))
      .catch((error) => setErrorMessage(true));

    // axios
    //   .post(
    //     `${config.apiURI}/users/user_information`,
    //     {},
    //     {
    //       headers: {
    //         authorization: `Bearer ${token}`,
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   // .then((response) => console.log(response))
    //   .then((response) => response.status === 200 && handleSingIn("/answersReg"))
    //   .catch((error) => console.log(error));
  };

  const { t } = useTranslation();

  return (
    <div className="py-4">
      <Button
        type="button"
        className="signin__button signin__button--size w-100 border-0 p-2 rounded-5"
        onClick={handleClick}
      >
        {t("register.step2.button")}
      </Button>

      {errorMessage && (
        <div className="text-danger">⚠ Sorry, an error has occurred ⚠ </div>
      )}
    </div>
  );
};

export default GlobalEtape1of3;
