import React, { useEffect } from "react";
import { Layout } from "../components/Layout";
import { Header } from "../components/Business/Header";
import { Banking } from "../components/Business/Banking";
import { Trade } from "../components/Business/Trade";
import { Transfers } from "../components/Business/Transfers";

export const Business = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <Header />

      <Banking />

      <Trade />

      <Transfers />
    </Layout>
  );
};
