import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../redux/store/store";
import exchangeArrowDown from "../../../../static/icons/app/home/exchangeArrowDown.svg";
import exchangeArrowRight from "../../../../static/icons/app/home/exchangeSrrowRight.svg";
import { setInputAmountBuy, setInputCurrencyBuy, setInputCurrencySell } from "../../../../redux/slices/order.slice";
import config from "../../../../config"

interface ICurrencies {
  maj: string;
  min: string;
  quote: string;
  name: string;
  symbol: string;
}

interface IBuyBodyHome {
  currencies: ICurrencies[];
  handleListCrypto: (value: string) => void;
}

export const BuyBodyHome: React.FC<IBuyBodyHome> = ({
  currencies,
  handleListCrypto,
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [change, setChange] = useState("0");
  const [currencySel, setCurrencySel] = useState("ETH");
  const [totalFees, setTotalFees] = useState("...");
  const [searchCrypto, setSearchCrypto] = useState(false);
  const [searchLanguage, setSearchLanguage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filteredListByCrypto, setFilteredListByCrypto] = useState({});

  const dropdownRef = useRef(null);
  const dropdownRefLng = useRef(null);
  const buttonRef = useRef(null);
  const buttonRefLng = useRef(null);

  const listByCrypto = useSelector(
    ({ orderData }: IRootState) => orderData.listCryptocurrency
  );
  const {
    inputAmountBuy,
    inputCurrencyBuy,
    outputAmountBuy,
    outputCurrencyBuy,
  } = useSelector(({ orderData }: IRootState) => orderData);

  const dispatch = useDispatch();

  const handleExchangeData = () => {
    setLoader(true);

    const objectToPost = {
      input: { currency: inputCurrencyBuy.maj, amount: inputAmountBuy.replace(/,/g, ".") },
      output: { currency: outputCurrencyBuy },
      blockchain: "eth",
      dir: "fiat_to_crypto",
    };

    axios
      .post(`${config.apiURI}/quotes/buy`, objectToPost)
      .then((response: any) => {
        if (response.status === 201) {
          const quotesData = response?.data?.quotes;

          setChange(quotesData.output.amount);
          setTotalFees(quotesData.response?.taux);
          setLoader(false);
        }
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    setLoader(true);

    const timer = setTimeout(() => {
      handleExchangeData();
    }, 1500);

    return () => clearTimeout(timer);
  }, [inputCurrencyBuy, currencySel, inputAmountBuy]);

  useEffect(() => {
    if (search === "") {
      setFilteredListByCrypto(listByCrypto);
    } else {
      const searchUpper = search.toUpperCase();
      const searchCapitalized =
        search.charAt(0).toUpperCase() + search.slice(1).toLowerCase();
      const filtered = Object.values(listByCrypto).filter(
        (item) =>
          // @ts-ignore
          item?.search?.startsWith(searchUpper) ||
          //@ts-ignore
          item?.name?.startsWith(searchCapitalized)
      );
      setFilteredListByCrypto(filtered);
    }
  }, [search, listByCrypto]);

  useEffect(() => {
    handleListCrypto(inputCurrencyBuy.quote);

    function handleClickOutside(event: { target: any }) {
      const dropResult =
        dropdownRef.current &&
        //@ts-ignore
        !dropdownRef.current.contains(event.target) &&
        //@ts-ignore
        !buttonRef.current.contains(event.target);

      const dropResultLng =
        dropdownRefLng.current &&
        //@ts-ignore
        !dropdownRefLng.current.contains(event.target) &&
        //@ts-ignore
        !buttonRefLng.current.contains(event.target);

      dropResult && setSearchCrypto(false);
      dropResultLng && setSearchLanguage(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = e.target.value.replace(/,/g, "");

    !isNaN(+numberValue) && dispatch(setInputAmountBuy(numberValue));
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-between mt-3">
      <div
        className="background-input-white mt-md-3 mt-xl-4 rounded-5 app__home-exchange-input"
        style={{
          border: "1px solid #D4D4D4",
        }}
      >
        <div className="d-flex align-items-center">
          <div className="exchangeInpDivLeft rounded-5 p-0">
            <input
              className="exchangeInpInput background-input-white d-flex align-items-center app__home-transaction--name p-0"
              inputMode="decimal"
              value={inputAmountBuy}
              onChange={handleChangeInput}
            />
          </div>

          <div className="d-flex">
            <div className="position-relative d-flex justify-content-center align-items-center">
              {" "}
              <Dropdown className="p-0">
                <Dropdown.Toggle
                  ref={buttonRefLng}
                  variant="lg"
                  id="dropdown-basic"
                  className="text-black fw-bold my-auto p-0"
                  style={{
                    minWidth: "100%",
                  }}
                  onClick={() => setSearchLanguage(!searchLanguage)}
                >
                  <div className="d-flex exchange__select--direction justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center app__home-exchange--currency--icon">
                      <img
                        src={`https://landing.fra1.cdn.digitaloceanspaces.com/Flag-png/${inputCurrencyBuy?.min}.png`}
                        alt=""
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>

                    <div className="ms-2 app__home-exchange--currency background-input-white">
                      {inputCurrencyBuy?.maj?.toUpperCase()}
                    </div>

                    <img
                      src="https://assets.revolut.com/assets/icons/ChevronDown.svg"
                      alt="ChevronDown"
                      height={12}
                    />
                  </div>
                </Dropdown.Toggle>

                {searchLanguage && (
                  <div
                    ref={dropdownRefLng}
                    className="app__home-exchange--search bg-white py-2 mt-3 position-absolute"
                    style={{
                      zIndex: 1000,
                      width: "250%",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 5px rgb(184, 184, 184)",
                    }}
                    onClick={() => setSearchLanguage(!searchLanguage)}
                  >
                    <div
                      style={{
                        height: "250px",
                        overflowY: "auto",
                        overscrollBehavior: "contain",
                      }}
                    >
                      {currencies?.map((currency: ICurrencies) => (
                        <div
                          className="btn m-0 p-0 border-0"
                          onClick={() => {
                            handleListCrypto(currency.quote);
                            dispatch(setInputCurrencyBuy(currency));
                          }}
                          key={currency?.min}
                        >
                          <div className="d-flex justify-content-start align-items-center p-1 list-lng-card ps-3">
                            <img
                              src={`https://landing.fra1.cdn.digitaloceanspaces.com/Flag-png/${currency?.min}.png`}
                              alt=""
                              height="24px"
                            />
                            <div
                              className="ms-3 p-2"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              {currency?.maj?.toUpperCase()}
                            </div>
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              {currency?.name}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center my-2">
        <div
          className="p-0 m-0 d-flex justify-content-center align-items-center rounded-5"
          style={{
            width: "18px",
            height: "18px",
            background: "#AAAAAA",
          }}
        >
          <img
            src={exchangeArrowDown}
            alt="exchangeArrowDown"
            style={{
              width: "14px",
              height: "14px",
            }}
          />
        </div>
      </div>

      <div
        className="background-input-white rounded-5 app__home-exchange-input"
        style={{
          border: "1px solid #D4D4D4",
        }}
      >
        <div className="d-flex align-items-center">
          <div className="exchangeInpDivLeft rounded-5 p-0">
            <input
              className="exchangeInpInput background-input-white d-flex align-items-center app__home-transaction--name p-0"
              inputMode="decimal"
              value={loader || +change === 0 ? "..." : (+change).toFixed(8)}
              disabled={true}
            />
          </div>

          <div className="d-flex">
            <div className="position-relative d-flex justify-content-center align-items-center">
              {" "}
              <Dropdown>
                <Dropdown.Toggle
                  ref={buttonRef}
                  variant="lg"
                  id="dropdown-basic"
                  className="text-black my-auto fw-bold p-0"
                  style={{
                    minWidth: "100%",
                  }}
                  onClick={() => setSearchCrypto(!searchCrypto)}
                >
                  <div className="d-flex exchange__select--direction align-items-center justify-content-center">
                    <div className="d-flex justify-content-center align-items-center app__home-exchange--currency--icon">
                      <img
                        src={`https://assets.kraken.com/marketing/web/icons/sym-${currencySel?.toLocaleLowerCase()}_colored.svg`}
                        alt=""
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>

                    <div className="ms-2 app__home-exchange--currency background-input-white">
                      {currencySel}
                    </div>

                    <img
                      src="https://assets.revolut.com/assets/icons/ChevronDown.svg"
                      alt="ChevronDown"
                      height={12}
                    />
                  </div>
                </Dropdown.Toggle>{" "}
                {searchCrypto && (
                  <div
                    ref={dropdownRef}
                    className="app__home-exchange--search bg-white pt-3 mt-3 rounded-4"
                    style={{
                      zIndex: 1000,
                      boxShadow: "0px 0px 5px rgb(184, 184, 184)",
                    }}
                  >
                    <div
                      className="mx-auto bg-search rounded d-flex align-items-center mb-2"
                      style={{ width: "90%" }}
                    >
                      <div className="py-2 ps-3 pe-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </div>
                      <input
                        className="input-search border-0"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "16px",
                          paddingLeft: "10px!important",
                          textTransform: "uppercase",
                          width: "75%",
                        }}
                        placeholder=""
                        autoFocus
                      />
                    </div>
                    <div
                      style={{
                        height: "250px",
                        overflowY: "auto",
                        overscrollBehavior: "contain",
                      }}
                    >
                      {Object.values(filteredListByCrypto).map(
                        (cryptoInfo, index) => {
                          //@ts-ignore
                          let base = cryptoInfo?.base;
                          if (
                            base &&
                            base.startsWith("X") &&
                            base !== "XCN" &&
                            base !== "XRT" &&
                            base !== "XTZ"
                          ) {
                            base = base.slice(1);
                          }
                          if (base && base.startsWith("Z")) {
                            return null;
                          }
                          if (base === "XBT") {
                            base = "BTC";
                          }
                          if (
                            base === "UST" ||
                            base === "TVK" ||
                            base === "NANO" ||
                            base === "ETHW"
                          ) {
                            return null;
                          }
                          return (
                            <div
                              key={index}
                              className="my-1"
                              onClick={() => {
                                //@ts-ignore
                                setCurrencySel(cryptoInfo?.base);
                                setSearchCrypto(false);
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between py-1">
                                <div className="d-flex">
                                  <div className="ms-3 d-flex align-items-center">
                                    <img
                                      //@ts-ignore
                                      src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.base?.toLowerCase()}_colored.svg`}
                                      alt="logo"
                                      style={{ height: "25px" }}
                                    />
                                  </div>

                                  <div
                                    className="ms-2 d-flex align-items-center"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {(cryptoInfo as { base: string })?.base}
                                  </div>
                                  <div
                                    className="ms-2 text-start d-flex align-items-center"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {(cryptoInfo as { name: string })?.name}
                                  </div>
                                </div>

                                <div
                                  className="mx-2 border-dark bg-grey text-black rounded px-1 d-flex align-items-center"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {(cryptoInfo as { type: string })?.type}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
