import React, { useEffect } from "react";
import { Layout } from "../components/Layout";
import { ContactForm } from "../components/Application/ContactForm";
import { useTranslation } from "react-i18next"

export const ContactHome = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div className="container py-5">
        <p className="contact__title home__background-text">{t("business.header.button")}</p>

        <div className="w-100 border-bottom border-dark mb-4"></div>

        <ContactForm />
      </div>
    </Layout>
  );
};
