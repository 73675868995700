import React, { useContext } from "react";
import { ThemeContext } from "../../providers/ThemeProvider";
import {
  FundamentalData,
  Timeline,
} from "react-ts-tradingview-widgets";
import { useParams } from "react-router-dom";

const TopStories = () => {
  const [theme] = useContext(ThemeContext);
  const { cryptoName } = useParams();

  return (
    <div className="rounded-2 border-color2">
      {/* <Timeline colorTheme={theme} feedMode="market" market="crypto" height={650} width="100%"></Timeline> */}

      <FundamentalData
        symbol={"NASDAQ:AAPL"}
        // symbol={cryptoName?.slice(0,-3)}
        colorTheme={theme}
        height={650}
        width="100%"
      ></FundamentalData>
    </div>
  );
};

export default TopStories;
