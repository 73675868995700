import React, { useContext } from "react";
import arrowRight from "../../static/icons/onRamp/arrowRight.svg";
import arrowRightWhite from "../../static/icons/onRamp/arrowRightWhite.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";
import { ThemeContext } from "../../providers/ThemeProvider"

const blogLink = process.env.REACT_APP_LINK_BLOG;

export const WhyCorner = () => {
  const { t } = useTranslation();

  const [theme, setTheme] = useContext(ThemeContext)

  return (
    <div className="container mx-auto">
      <div className="p-0 mt-lg-5">
        <p
          className="onramp__title--size text-center home__background-text"
          style={{
            fontFamily: "Inter, sans-serif",
            lineHeight: "56px",
            fontWeight: 600,
          }}
        >
          {t("onRamp.whyCorner.title")}
        </p>

        <div className="onramp__card m-0 mt-3 mt-md-5">
          <Nav.Link
            href={blogLink}
            className="row m-0 p-2 p-sm-4 w-100 border-color1 onramp__card d-flex justify-content-between text-decoration-none"
          >
            <div className="col-10 col-md-9 p-0 ps-3">
              <p
                className="onramp__title--size home__background-text mt-3"
                style={{
                  fontFamily: "Inter, sans-serif",
                  lineHeight: "10px",
                  fontWeight: 600,
                }}
              >
                {t("onRamp.whyCorner.link.title")}
              </p>

              <p
                className="onramp__text m-0 mb-3 mb-lg-2 mt-md-4 lh-sm"
                style={{
                  fontFamily: "Inter, sans-serif",
                  lineHeight: "34px",
                  color: "#989898",
                }}
              >
                {t("onRamp.whyCorner.link.text")}{" "}
              </p>
            </div>

            <div
              className="col-2 col-md-3 d-flex align-items-center justify-content-end
          p-0 pe-3"
            >
              <img
                src={theme === "light" ? arrowRight : arrowRightWhite}
                alt="arrowRight"
                className="onramp__card-icon"
              />
            </div>
          </Nav.Link>
        </div>
      </div>
    </div>
  );
};
