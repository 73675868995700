import React, { useContext } from "react";
import { useParams } from "react-router";
import { TechnicalAnalysis } from "react-ts-tradingview-widgets";
import { ThemeContext } from "../../providers/ThemeProvider";

const TechnicalAnalysisWidget = () => {
  const { cryptoName } = useParams();

  const [theme] = useContext(ThemeContext);

  return (
    <div
      className="rounded-3 h-100 border-color2"
      style={{
        overflow: "hidden",
        maxHeight: "685px",
      }}
    >
      <div className="" style={{ height: "100%", width: "100%" }}>
        <TechnicalAnalysis
          symbol={cryptoName}
          colorTheme={theme}
          width="100%"
          height="100%"
        ></TechnicalAnalysis>
      </div>
    </div>
  );
};

export default TechnicalAnalysisWidget;
