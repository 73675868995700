import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
import step2Bg from "../../static/images/Register/step2Bg.png";
import { useTranslation } from "react-i18next";
import { LanguagesBar } from "../Navbar/LanguagesBar";

export const RecoveryPasswordStep2 = () => {
  const [visible, setVisible] = useState(false);
  const [visibleGlob, setVisibleGlob] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setVisibleGlob(true);
    }, 100);
  }, []);

  const handleSingIn = () => {
    setVisible(false);
    setVisibleGlob(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  return (
    <>
      <div
        className="d-flex justify-content-end align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >

        <div className="d-flex align-items-center text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <span>{t("register.step2.haveAcc")}</span>

          <button
            onClick={handleSingIn}
            className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
        }}
      >
        <div className="container d-flex flex-column pt-5 position-relative">
          <div
            className="h-100 d-flex flex-column align-items-center"
            style={{
              zIndex: 1,
              opacity: visible ? 1 : 0,
              transform: `scale(${visible ? 1 : 0.8})`,
              transition: "all 1.5s",
            }}
          >
            <div className="text-center d-none d-md-block">
              <img src={cornerLogo} alt="cornerLogo" height={100} />
            </div>

            <div className="text-center d-md-none">
              <img src={cornerLogo} alt="cornerLogo" height={70} />
            </div>

            <span className="fs-2 fw-semibold text-white text-center mt-5">
              {t("recovery.stepEmail")}
            </span>

            <button
              onClick={handleSingIn}
              className="signin__button signin__button--size text-decoration-none w-100 border-0 p-2 rounded-5 mt-5"
            >
              {t("recovery.confirm")}
            </button>
          </div>

          <div
            className="register__step2 d-flex align-items-center justify-content-center"
            style={{
              opacity: visibleGlob ? 1 : 0,
              transition: "all 1.5s",
            }}
          >
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </>
  );
};
