import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "../../components/Layout";
import Slider from "../../components/Slider";
import { Header } from "../../components/Home/Header";
import { MoveArrow } from "../../components/Home/MoveArrow";
import { OpenAccount } from "../../components/Home/OpenAccount";
import { MeetCrypto } from "../../components/Home/MeetCrypto";
import { Thieves } from "../../components/Home/Thieves";
import ExchangeCryptoBlock from "../../components/ExchangeCrypto/ExchangeCryptoBlock";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import ScrollBrand from "../../components/Home/ScrollBrand";

const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { t } = useTranslation();

  const [componentBackground, setComponentBackground] = useState(false);
  const [rotate, setRotate] = useState(-64);
  const [width, setWidth] = useState(0.15 * window.innerHeight);
  const [height, setHeight] = useState(0.5 * window.innerHeight);
  const [visibleGreenEl, setVisibleGreenEl] = useState(false);

  const { colorFirst, colorTextSecond, colorGradient1, colorGradient2 } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const componentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (componentRef.current) {
        //@ts-ignore
        const componentRefRect = componentRef.current.getBoundingClientRect();

        const componentTopOffset =
          (window.innerHeight - componentRefRect.top) / window.innerHeight;

        const componentBottomOffset =
          (window.innerHeight - componentRefRect.bottom) / window.innerHeight;

        setComponentBackground(
          componentBottomOffset < 0.65 && componentTopOffset > 0.2
        );
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setRotate(0.2 * window.scrollY - 64);
    };

    if (componentBackground && !visibleGreenEl) {
      setWidth(4 * window.innerWidth);
      setHeight(4 * window.innerHeight);
    } else {
      setWidth(0.15 * window.innerHeight);
      setHeight(0.5 * window.innerHeight);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.scrollY]);

  return (
    <Layout>
      <div
      className="home__background"
      >
        <Header />

        <ScrollBrand />

        {(visibleGreenEl || componentBackground) && (
          <div
            className="animation__backround position-fixed home__background-card--head"
            style={{
              top: `${0.5 * window.innerHeight}px`,
              transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
              width: `${width}px`,
              height: `${height}px`,
              // backgroundColor: colorFirst,
              zIndex: 0,
            }}
          ></div>
        )}

        <OpenAccount setShow={setVisibleGreenEl} />

        <div
          ref={componentRef}
          className={`animated-component ${componentBackground ? "visible" : "hidden"}`}
        >
          <div className="w-100 pb-5">
            <div
              className="row mx-auto py-5 px-2"
              style={{ maxWidth: "1320px" }}
            >
              <div className="col-12 col-md-6 h-100 my-auto">
                <p className="fw-bold fs-1 lh-sm">
                  {t("personal.saveUp.title")}
                </p>

                <p
                  className="fs-5 lh-sm col-11"
                  style={{ color: colorTextSecond }}
                >
                  {t("personal.saveUp.sub")}
                </p>
              </div>

              <div className="col-12 col-md-6 h-100 d-flex justify-content-center px-0 position-relative">
                <ExchangeCryptoBlock />
              </div>
            </div>
          </div>
        </div>

        <MoveArrow secondColor={componentBackground} />
        
        <Slider />

        <MeetCrypto />

        <Thieves />
      </div>
    </Layout>
  );
};

export default Home;
