import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { SingleTicker } from "react-ts-tradingview-widgets";
import { ThemeContext } from "../../providers/ThemeProvider"

const SymbolInfo = () => {
  const { cryptoName } = useParams();

  const [theme] = useContext(ThemeContext);

  return (
    <div className="pt-1 pt-md-2 position-relative">
      <div
        className="app__card border-color2 rounded-3"
        style={{
          pointerEvents: "none",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <SingleTicker
          symbol={cryptoName}
          colorTheme={theme}
          width="100%"
        ></SingleTicker>
      </div>
    </div>
  );
};

export default SymbolInfo;
