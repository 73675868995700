import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Field, Formik, Form } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { Button, Col, Row, Form as BootstrapForm } from "react-bootstrap";
import config from "../../config"

const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

interface ISubscribe {
  bgColor: string;
  classProp: string;
}

interface IFormValues {
  email: string;
  // receive: boolean;
}
export const Subsribe: React.FC<ISubscribe> = ({ bgColor, classProp }) => {
  const { t } = useTranslation();

  const [loader, setLoader] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    // receive: yup
    //   .boolean()
    //   .oneOf([true], "You must accept the terms and conditions.")
    //   .required(),
  });

  const handleSubmit = (values: IFormValues, { resetForm }: any) => {
    setLoader(true);

    const objectToPost = {
      email: values.email,
      name: "Subscribe",
      captcha: captchaValue,
    };

    axios
      .post(`${config.apiURI}/captcha`, objectToPost)
      .then(function (response) {
        setLoader(false);
        response.status === 200 && resetForm();
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error :", error);
      });
  };

  function onChange(value: any) {
    setCaptchaValue(value);
  }

  return (
    <div
      className={`input-group d-flex rounded-2 subscribe ${classProp}`}
      // style={{
      //   background: `${bgColor}`,
      // }}
    >
      <div className="d-flex justify-content-between align-items-center px-2 px-sm-4 py-3 home__background-text">
        <div className="d-flex flex-column flex-lg-row m-0 align-items-lg-center">
          <span className=" blog__title--sub-1 fw-bold m-0 p-0 pe-2">
            {t("blog.subscribe.title")}
          </span>

          <span
            className="blog__title--sub-1 fw-bold m-0 p-0"
            style={{
              background: "#D8AFF3",
              lineHeight: "1.2",
            }}
          >
            {t("blog.subscribe.news")}
          </span>
        </div>

        <p className="m-0 d-flex justify-content-center col-5 pe-xl-3 blog__title--sub">
          {t("blog.subscribe.sub")}
        </p>
      </div>

      <Formik
        initialValues={{ email: "", receive: false }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleChange, handleBlur, values }) => (
          <Form className="w-100 p-0 px-2 px-md-3">
            <div
              className="w-100 mb-3"
              style={{
                border: "1px solid #DDE1E2",
              }}
            />

            <div className="row justify-content-between ps-sm-3 pe-2 pe-sm-4 py-2">
              <Field
                type="email"
                id="email"
                name="email"
                placeholder={t("blog.subscribe.placeholder")}
                className={`border-0 col-8 subscribe rounded subscribe__input ${errors.email && touched.email ? "border-danger" : "border-0"}`}
                style={{
                  borderRadius: "40px",
                  outline: "none",
                }}
              />

              <button
                type="submit"
                className="home__background-text border-0 bg-transparent subscribe__button text-decoration-underline p-0"
                style={{
                  width: "max-content",
                }}
                id="button-addon2"
                disabled={!captchaValue}
              >
                {loader ? (
                  <div className="spinner-border me-5" role="status"></div>
                ) : (
                  t("blog.subscribe.button")
                )}
              </button>
            </div>

            <div
              className="w-100 my-3"
              style={{
                border: "1px solid #DDE1E2",
              }}
            />

            <BootstrapForm.Group
              controlId="privacy"
              className="ms-2 ms-lg-4 p-0"
            >
              <BootstrapForm.Check
                required
                name="receive"
                label={t("blog.subscribe.checkBox")}
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.receive}
                isInvalid={!!errors.receive && touched.receive}
                className="text-start home__background-text"
              />
            </BootstrapForm.Group>

            {values.receive && (
              <div className="mt-3 ps-2">
                <ReCAPTCHA sitekey={captchaKey} onChange={onChange} />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
