import React, { useContext, useEffect, useState } from "react";
import { SliderS } from "../SlideS";
import { useTranslation } from "react-i18next";
import { MiniChart } from "react-ts-tradingview-widgets";
import { ThemeContext } from "../../../../providers/ThemeProvider";
import { Link } from "react-router-dom";

const marketsList = [
  "BINANCE:BTCEUR",
  "COINBASE:ETHEUR",
  "OKX:SOLEUR",
  "OKX:USDTEUR",
];

export const Market = () => {
  const { t } = useTranslation();

  const [theme] = useContext(ThemeContext);

  const [amountCharts, setAmountCharts] = useState(
    window.innerWidth > 991 ? 3 : 2
  );

  useEffect(() => {
    const handleResize = () => {
      setAmountCharts(window.innerWidth > 991 ? 3 : 2);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span className="app__home-subtitle m-0">{t("app.home.market")}</span>

        <Link
          to="/explore"
          className="border-0 bg-transparent text-decoration-none p-0 m-0"
          style={{
            color: "#777777",
            fontSize: "12px",
          }}
        >
          {t("app.home.viewAll")}
        </Link>
      </div>

      <div className="mt-2">
        <SliderS displayCount={amountCharts}>
          {marketsList.map((market: any) => (
            <div
              className="rounded-4 app__home-charts app__card"
              style={{
                overflow: "hidden",
                pointerEvents: "none"
              }}
              key={market}
            >
              <MiniChart
                symbol={market}
                colorTheme={theme}
                width="100%"
              ></MiniChart>
            </div>
          ))}
        </SliderS>
      </div>
    </div>
  );
};
