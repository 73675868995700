import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../redux/store/store";
import axios from "axios";
import { setListCryptocurrency } from "../../../redux/slices/order.slice";
import { Link } from "react-router-dom";
import { Dropdown, Nav, NavDropdown, Navbar } from "react-bootstrap";
import NavbarAppPhone from "./NavbarAppPhone";
import logoIcon from "../../../static/icons/CornerSwapFinal.png";
import logoIconDArk from "../../../static/icons/app/navbar/CornerSwapDark.png";
import userIcon from "../../../static/images/App/navbar/userLogo.jfif";
import selectArrow from "../../../static/icons/app/navbar/selectArrow.svg";
import searchIcon from "../../../static/icons/app/navbar/searchIcon.svg";
import searchIconDark from "../../../static/icons/app/navbar/search-normal.png";

import profileIcon from "../../../static/icons/app/navbar/profileIcon.png";
import darkModeIcon from "../../../static/icons/app/navbar/darkModeIcon.png";
import toggleOn from "../../../static/icons/app/navbar/toggleOn.png";
import toggleOff from "../../../static/icons/app/navbar/toggleOff.png";
import contactIcon from "../../../static/icons/app/navbar/contactIcon.png";
import helpIcon from "../../../static/icons/app/navbar/helpIcon.png";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../providers/ThemeProvider"
import config from "../../../config"

const NavbarApp = () => {
  const [theme, setTheme] = useContext(ThemeContext)

  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [searchCrypto, setSearchCrypto] = useState(false);
  const [filteredListByCrypto, setFilteredListByCrypto] = useState({});

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const { colorGrey } = useSelector(({ styleData }: IRootState) => styleData);
  const { email, checkCorner } = useSelector(({ authData }: IRootState) => authData.fetchAuth);
  const listByCrypto = useSelector(
    ({ orderData }: IRootState) => orderData.listCryptocurrency
  );

  const dispatch = useDispatch();

  const changeTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  }

  useEffect(() => {
    if (search === "") {
      setFilteredListByCrypto(listByCrypto);
    } else {
      const searchUpper = search.toUpperCase();
      const searchCapitalized =
        search.charAt(0).toUpperCase() + search.slice(1).toLowerCase();
      const filtered = Object.values(listByCrypto).filter(
        (item) =>
          // @ts-ignore
          item?.search?.startsWith(searchUpper) ||
          //@ts-ignore
          item?.name?.startsWith(searchCapitalized)
      );
      setFilteredListByCrypto(filtered);
    }
  }, [search, listByCrypto]);

  useEffect(() => {
    handleListCrypto();

    function handleClickOutside(event: { target: any }) {
      const dropResult =
        dropdownRef.current &&
        //@ts-ignore
        !dropdownRef.current.contains(event.target) &&
        //@ts-ignore
        !buttonRef.current.contains(event.target);

      dropResult && setSearchCrypto(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleListCrypto = (value: string = "ZEUR") => {
    const objectToPost = {
      input: value,
    };

    axios
      .post(`${config.apiURI}/pb/pairsByDevice`, objectToPost)
      .then(
        (response: any) =>
          response.status === 200 &&
          dispatch(setListCryptocurrency(response?.data))
      )
      .catch(function (error: any) {
        console.log("error :", error);
      });
  };

  const accountIcon = (
    <div>
      <img
        src={userIcon}
        alt="userIcon"
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />

      <span className="fw-bold mx-2 color-text-dark">{email}</span>

      <img src={selectArrow} alt="selectArrow" />
    </div>
  );

  return (
    <div className={`d-flex align-items-center ${checkCorner !== 7 && "py-lg-4"}`}>
      <div className="w-100 d-flex justify-content-between align-items-center">
        <Navbar
          bg="transparent"
          expand="lg"
          className="w-100 d-flex flex-row justify-content-between py-0"
        >
          <Link
            to="/home"
            className="fw-bold fs-3 text-decoration-underline p-0 app__logo"
          >
            <img
              // src={logoIcon}
              src={theme === "light" ? logoIcon : logoIconDArk}
              alt="logoIcon"
              style={{
                height: "85px",
              }}
            />
          </Link>

          <div
            className="d-flex"
            style={{
              width: "max-content",
              justifyContent: "flex-end",
            }}
          >
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="d-none d-lg-flex justify-content-end"
            >
              <Nav>
                <div
                  className="p-0 me-5"
                  style={{
                    width: "265px",
                  }}
                >
                  <button
                    ref={buttonRef}
                    onClick={() => setSearchCrypto(!searchCrypto)}
                    className="app__card h-100 d-flex align-items-center rounded-5"
                    // className="border-0 h-100 bg-white d-flex align-items-center rounded-5"
                    style={{
                      outline: "transparent",
                      fontSize: "12px",
                      width: "265px",
                    }}
                  >
                    <img
                      src={theme === "light" ? searchIcon : searchIconDark}
                      alt="searchIcon"
                      style={{
                        height: "40px",
                      }}
                    />

                    <span
                      style={{
                        color: "#909093",
                      }}
                    >
                      {t("app.nav.search")}
                    </span>
                  </button>
                </div>

                <div className="d-flex">
                  <div className="position-relative d-flex justify-content-center align-items-center">
                    {" "}
                    <Dropdown className="p-0">
                      {searchCrypto && (
                        <div
                          ref={dropdownRef}
                          className="app__nav--search bg-white pt-3 rounded-5 me-5"
                          style={{
                            zIndex: 9999,
                            boxShadow: "0px 3px 3px 0px rgb(184, 184, 184)",
                          }}
                        >
                          <div
                            className="mx-auto bg-white rounded-5 d-flex align-items-center mb-2"
                            style={{ width: "90%" }}
                          >
                            <div className="px-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                className="bi bi-search"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </div>

                            <input
                              className="input-search border-0"
                              type="text"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              style={{
                                backgroundColor: "transparent",
                                fontSize: "16px",
                                paddingLeft: "10px!important",
                                textTransform: "uppercase",
                                width: "75%",
                              }}
                              placeholder=""
                              autoFocus
                            />
                          </div>

                          <div
                            style={{
                              height: "250px",
                              overflowY: "auto",
                              overscrollBehavior: "contain",
                            }}
                          >
                            {Object.values(filteredListByCrypto).map(
                              (cryptoInfo: any, index: number) => {
                                //@ts-ignore
                                let base = cryptoInfo?.base;
                                if (
                                  base &&
                                  base.startsWith("X") &&
                                  base !== "XCN" &&
                                  base !== "XRT" &&
                                  base !== "XTZ"
                                ) {
                                  base = base.slice(1);
                                }
                                if (base && base.startsWith("Z")) {
                                  return null;
                                }
                                if (base === "XBT") {
                                  base = "BTC";
                                }
                                if (
                                  base === "UST" ||
                                  base === "TVK" ||
                                  base === "NANO" ||
                                  base === "ETHW"
                                ) {
                                  return null;
                                }
                                return (
                                  <Link
                                    to={`/explore/cryptoInfo/${cryptoInfo?.altname}`}
                                    // target="_blank"
                                    key={index}
                                    className="d-block text-dark text-decoration-none my-1 item-list-crypto"
                                    onClick={(e) =>{
                                       setSearchCrypto(false)
                                      // e.preventDefault();
                                      
                                      }
                                    }
                                  >
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="d-flex">
                                        <div className="ps-2 d-flex align-items-center">
                                          <img
                                            //@ts-ignore
                                            src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.base?.toLowerCase()}_colored.svg`}
                                            alt="logo"
                                            style={{ height: "25px" }}
                                          />
                                        </div>

                                        <div
                                          className="ps-2 d-flex align-items-center"
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {
                                            (cryptoInfo as { base: string })
                                              ?.base
                                          }
                                        </div>

                                        <div
                                          className="ps-1 text-start d-flex align-items-center"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {
                                            (cryptoInfo as { name: string })
                                              ?.name
                                          }
                                        </div>
                                      </div>

                                      <div
                                        className="mx-2 border-dark bg-grey text-black rounded d-flex align-items-center"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {(cryptoInfo as { type: string })?.type}
                                      </div>
                                    </div>
                                  </Link>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                    </Dropdown>
                  </div>
                </div>

                <NavDropdown
                  title={accountIcon}
                  id="offcanvasNavbarDropdown-expand-lg"
                >
                  <Link
                    to="/profile"
                    className="p-0 py-2 w-100 px-3 text-dark text-decoration-none d-flex align-items-center"
                    style={{
                      borderBottom: "0.5px solid #777777",
                    }}
                  >
                    <div
                      className="p-0"
                      style={{
                        width: "23px",
                      }}
                    >
                      <img src={profileIcon} alt="profileIcon" height={18} />
                    </div>

                    <span
                      className="ms-2 ps-1 pt-1"
                      style={{
                        color: "#636262",
                        fontSize: "12px",
                      }}
                    >
                      {t("app.nav.profile")}
                    </span>
                  </Link>

                  <button
                    onClick={changeTheme}
                    className="p-0 py-2 ps-3 pe-1 w-100 text-dark text-decoration-none bg-transparent d-flex justify-content-between"
                    style={{
                      borderBottom: "0.5px solid #777777",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        className="p-0"
                        style={{
                          width: "23px",
                        }}
                      >
                        <img
                          src={darkModeIcon}
                          alt="darkModeIcon"
                          height={18}
                        />
                      </div>
                      <span
                        className="ms-2 pt-1 ps-1"
                        style={{
                          color: "#636262",
                          fontSize: "12px",
                        }}
                      >
                        {t("app.nav.darkMode")}
                      </span>
                    </div>

                    <div>
                      <img
                        src={theme === "light" ? toggleOff : toggleOn}
                        alt="toggleDarkMode"
                        height={12}
                      />
                    </div>
                  </button>

                  <Link
                    to="/contact"
                    className="p-0 py-2 w-100 px-3 text-dark text-decoration-none d-flex align-items-center"
                  >
                    <div
                      className="p-0"
                      style={{
                        width: "23px",
                      }}
                    >
                      <img src={contactIcon} alt="contactIcon" height={18} />
                    </div>

                    <span
                      className="ms-2 pt-1 ps-1"
                      style={{
                        color: "#636262",
                        fontSize: "12px",
                      }}
                    >
                      {t("app.nav.contact")}
                    </span>
                  </Link>

                  {/* <Link
                    to="/help"
                    className="p-0 pb-2 w-100 px-3 text-dark text-decoration-none d-flex align-items-center"
                  >
                    <div
                      className="p-0 text-center"
                      style={{
                        width: "23px",
                      }}
                    >
                      <img src={helpIcon} alt="helpIcon" height={18} />
                    </div>

                    <span
                      className="ms-2 pt-1 ps-1"
                      style={{
                        color: "#636262",
                        fontSize: "12px",
                      }}
                    >
                      {t("app.nav.help")}
                    </span>
                  </Link> */}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </div>

          {/* <NavbarAppPhone /> */}
        </Navbar>
      </div>
    </div>
  );
};

export default NavbarApp;
