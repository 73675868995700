import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ContainerRadio from "./ContainerRadio";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setAnswer3 } from "../../redux/slices/auth.slice";

interface IStep3 {
  stateStep: (el: number) => void;
}

const Step3: React.FC<IStep3> = ({ stateStep }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [activAnswer, setActivAnswer] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleNextStep = () => {
    dispatch(setAnswer3(activAnswer));

    setVisible(false);

    setTimeout(() => {
      stateStep(4);
    }, 1000);
  };

  const handlechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivAnswer(event.target.value);
  };

  return (
    <div
      style={{
        zIndex: 1,
        opacity: visible ? 1 : 0,
        transform: `scale(${visible ? 1 : 0.8})`,
        transition: "all 1.5s",
      }}
    >
      <figcaption className="m-0 text-secondary">{t("form.3")}</figcaption>

      <h2 className="text-start my-3 h4">{t("form.mensuel")}</h2>

      <ContainerRadio
        handlechange={handlechange}
        id="flexCheckDefault1"
        name="step3"
        response={t("form.dessous")}
        value="-1000€"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.1001")}
        value="1001/2000"
        name="step3"
        id="CheckDefault2"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.2001")}
        value="2001/5000"
        name="step3"
        id="CheckDefault3"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.5001")}
        value="5001/10000"
        name="step3"
        id="CheckDefault4"
      />
      <ContainerRadio
        handlechange={handlechange}
        response={t("form.Plus")}
        value="+10000€"
        name="step3"
        id="CheckDefault5"
      />

      <div className="w-100 text-center text-lg-end">
        <Button
          disabled={!activAnswer}
          type="button"
          onClick={handleNextStep}
          className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4"
        >
          {t("form.Suivant")}
        </Button>
      </div>
    </div>
  );
};

export default Step3;
