import React from "react";
import NavbarApp from "./Navbar/NavbarApp";
import AsideLeft from "./AsideLeft";
import NavbarAppPhone from "./Navbar/NavbarAppPhone";
import useCheckSecurity from "../../hooks/useCheckSecurity";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";

const LayoutApp = ({ children }: any) => {
  useCheckSecurity();

  const { checkCorner } = useSelector(
    ({ authData }: IRootState) => authData.fetchAuth
  );

  return (
    <div
      className="app"
      style={{ minHeight: "100vh" }}
    >
      {checkCorner === 7 && (
        <div
          className="w-100 text-center py-3"
          style={{
            background: "rgb(216, 175, 243)",
          }}
        >
          This is a "test" account with full KYC, however, no transaction will
          be successful.
        </div>
      )}

      <div className="container p-0 h-100 d-flex flex-column">
        <NavbarApp />

        <div className="row p-0 pt-5 pt-lg-0 m-0" style={{ flexGrow: 1 }}>
          <div className="d-none d-lg-block col-lg-3">
            <AsideLeft />
          </div>

          <div className="col-12 col-lg-9 px-2 px-sm-0 h-100 app__home-row">
            {children}
          </div>
        </div>
      </div>

      <div className="d-lg-none mobile__nav"></div>

      <NavbarAppPhone />
    </div>
  );
};

export default LayoutApp;
