import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
import step2Bg from "../../static/images/Register/step2Bg.png";
import arrowBack from "../../static/icons/signIn/arrowBack.svg";
import { useTranslation } from "react-i18next";
import { LanguagesBar } from "../Navbar/LanguagesBar";
import axios from "axios";
import config from "../../config"

interface IFormValide {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordRepeat: string;
  birthday: string;
  terms: boolean;
  privacy: boolean;
  newsletter: boolean;
}

interface IRegisterStep2 {
  setStep: (value: number) => void;
}

export const RegisterStep2: React.FC<IRegisterStep2> = ({ setStep }) => {
  const [visible, setVisible] = useState(false);
  const [visibleGlob, setVisibleGlob] = useState(false);
  const [errorRegistration, setErrorRegistration] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setVisibleGlob(true);
    }, 100);
  }, []);

  const schema = yup.object().shape({
    firstName: yup.string().required(t("register.errors.required")),
    lastName: yup.string().required(t("register.errors.required")),
    email: yup.string().email(t("register.errors.email")).required(t("register.errors.required")),
    password: yup
      .string()
      .required()
      .required(t("register.errors.required"))
      .min(8, t("register.errors.shortPass"))
      .test(
        "isValidPass",
        t("register.errors.characterePass"),
        (value) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSymbole = /[!@#%&]/.test(value);
          let validConditions = 0;
          const numberOfMustBeValidConditions = 4;
          const conditions = [
            hasLowerCase,
            hasUpperCase,
            hasNumber,
            hasSymbole,
          ];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );

          return validConditions >= numberOfMustBeValidConditions;
        }
      ),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref("password")], t("register.errors.differentPass"))
      .required(t("register.errors.required")),
    birthday: yup
      .string()
      .test(
        "is-adult",
        t("register.errors.adult"),
        (value: string | undefined) => {
          return checkIsAdult(value || "");
        }
      )
      .required(t("register.errors.required")),
    terms: yup
      .boolean()
      .oneOf([true], t("register.errors.terms"))
      .required(),
    privacy: yup
      .boolean()
      .oneOf([true], t("register.errors.privacy"))
      .required(),
    newsletter: yup.bool(),
  });

  const [typePassword, setTypePassword] = useState(true);
  const [typePasswordRep, setTypePasswordRep] = useState(true);

  const ValideForm = (obj: IFormValide) => {
    const objectToPost = {
      firstName: obj.firstName,
      lastName: obj.lastName,
      password: obj.password,
      email: obj.email,
      agreeTermAndCondition: obj.terms ? 1 : 0,
      agreePrivacyPolicy: obj.privacy ? 1 : 0,
      isNewsletter: obj.newsletter ? 1 : 0,
      birthday: obj.birthday.split("-").reverse().join("/"),
    };

    axios
      .post(`${config.apiURI}/users/signup`, objectToPost)
      .then(function (response) {
        setErrorRegistration(false);

        if (response.status === 200) {
          setVisible(false);

          setTimeout(() => {
            setStep(3);
          }, 1500);
        }
      })
      .catch((error) => {
        setErrorRegistration(true);

        if (error.response.status === 555) {
          setErrorMessage("This email is already registered.");
        } else if (error.response.status === 500) {
          setErrorMessage("Problem when we try to create an wallet.");
        } else if (error.response.status === 400) {
          setErrorMessage("Content can not be empty!");
        } else {
          setErrorMessage("Something went wrong.");
        }
      });
  };

  const handleSingIn = () => {
    setVisible(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  const handlePrevStep = () => {
    setVisible(false);
    setVisibleGlob(false);

    setTimeout(() => {
      setStep(1);
    }, 1000);
  };

  function checkIsAdult(birthDate: string) {
    const today = new Date(); // Today date
    const birth = new Date(birthDate); // Date of birthday
    const age = today.getFullYear() - birth.getFullYear();

    const hasBirthdayPassed =
      today.getMonth() > birth.getMonth() ||
      (today.getMonth() === birth.getMonth() &&
        today.getDate() >= birth.getDate());

    return age > 18 || (age === 18 && hasBirthdayPassed);
  }

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div
          className="btn text-white text-decoration-none d-flex align-items-center p-0"
          onClick={handlePrevStep}
        >
          <img src={arrowBack} alt="arrowBack" height={12} />
          <span className="ms-sm-4 ps-2 signin--text-size">
            {t("logIn.back")}
          </span>
        </div>

        <div className="d-flex align-items-center text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <span>{t("register.step2.haveAcc")}</span>

          <button
            onClick={handleSingIn}
            className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
        }}
      >
        <div className="container d-flex flex-column pt-5 position-relative">
          <div
            style={{
              zIndex: 1,
              opacity: visible ? 1 : 0,
              transform: `scale(${visible ? 1 : 0.8})`,
              transition: "all 1.5s",
            }}
          >
            <div className="text-center d-none d-md-block">
              <img src={cornerLogo} alt="cornerLogo" height={100} />
            </div>

            <div className="text-center d-md-none">
              <img src={cornerLogo} alt="cornerLogo" height={70} />
            </div>

            <div className="d-flex align-items-center justify-content-center mt-4 mt-md-5">
              <span className="fs-2 fw-semibold text-white">
                {t("register.step2.createAcc")}
              </span>
            </div>

            <div className="col-11 col-md-9 col-lg-5 p-0 d-flex flex-column justify-content-center mx-auto mt-3">
              <Formik
                validationSchema={schema}
                onSubmit={ValideForm}
                initialValues={{
                  firstName: "",
                  lastName: "",
                  password: "",
                  email: "",
                  passwordRepeat: "",
                  birthday: "",
                  terms: false,
                  privacy: false,
                  newsletter: false,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form
                    className="my-3 text-center"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Row
                      className="d-flex flex-column flex-lg-row justify-content-between mb-3 mb-md-4 signin__button--size mx-auto"
                      style={{
                        height: "max-content",
                      }}
                    >
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik03"
                        className="p-0"
                      >
                        <Field
                          className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 signin__button--size ${
                            errors.firstName &&
                            touched.firstName &&
                            "border border-danger"
                          } ${!errors.firstName && touched.firstName && "border border-success"}`}
                          style={{
                            boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                            border: "1px solid transparent",
                            background: "#fff",
                          }}
                          id="firstName"
                          name="firstName"
                          value={values.firstName}
                          placeholder={t("register.step2.firstName")}
                          type="text"
                          onChange={handleChange}
                        />

                        {errors.firstName && touched.firstName && (
                          <p className="text-danger text-start ps-2">
                            {errors.firstName}
                          </p>
                        )}
                      </Form.Group>
                    </Row>

                    <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik03"
                        className="p-0"
                      >
                        <Field
                          className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 signin__button--size ${
                            errors.lastName &&
                            touched.lastName &&
                            "border border-danger"
                          } ${!errors.lastName && touched.lastName && "border border-success"}`}
                          style={{
                            boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                            border: "1px solid transparent",
                            background: "#fff",
                          }}
                          id="lastName"
                          name="lastName"
                          value={values.lastName}
                          placeholder={t("register.step2.lastName")}
                          type="text"
                          onChange={handleChange}
                        />

                        {errors.lastName && touched.lastName && (
                          <p className="text-danger text-start ps-2">
                            {errors.lastName}
                          </p>
                        )}
                      </Form.Group>
                    </Row>

                    <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik03"
                        className="p-0"
                      >
                        <Field
                          className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 signin__button--size ${
                            errors.email &&
                            touched.email &&
                            "border border-danger"
                          } ${!errors.email && touched.email && "border border-success"}`}
                          style={{
                            boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                            border: "1px solid transparent",
                            background: "#fff",
                          }}
                          id="email"
                          name="email"
                          value={values.email}
                          placeholder={t("logIn.email")}
                          type="email"
                          onChange={handleChange}
                        />

                        {errors.email && touched.email && (
                          <p className="text-danger text-start ps-2">
                            {errors.email}
                          </p>
                        )}
                      </Form.Group>
                    </Row>

                    <Row
                      className={`signin__button--width mx-auto ${errors.password && touched.password ? "m-0" : "mb-3 mb-md-4"}`}
                    >
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik03"
                        className="p-0 signin__button--size"
                      >
                        <div className="position-relative d-flex">
                          <Field
                            className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 mb-0 pe-5 signin__button--size ${
                              errors.password &&
                              touched.password &&
                              "border border-danger"
                            }  ${!errors.password && touched.password && "border border-success"}`}
                            style={{
                              boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                              border: "1px solid transparent",
                              background: "#fff",
                            }}
                            id="password"
                            name="password"
                            value={values.password}
                            placeholder={t("logIn.password")}
                            type={typePassword ? "password" : "text"}
                            onChange={handleChange}
                          />
                          <span
                            className="signin__icon position-absolute top-50 end-0 translate-middle-y me-3"
                            style={{
                              backgroundColor: "transparent",
                              cursor: "pointer",
                            }}
                            onClick={() => setTypePassword(!typePassword)}
                          >
                            <img
                              src={
                                typePassword
                                  ? "https://cdn1.iconfinder.com/data/icons/hawcons/32/699007-icon-21-eye-hidden-512.png"
                                  : "https://www.freeiconspng.com/thumbs/eye-icon/eyeball-icon-png-eye-icon-1.png"
                              }
                              alt="eye icon"
                              style={{
                                width: "100%",
                              }}
                            />
                          </span>
                        </div>
                      </Form.Group>
                      {errors.password && touched.password && (
                        <p className="text-danger text-start m-0 ps-2">
                          {errors.password}
                        </p>
                      )}
                    </Row>

                    <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik03"
                        className="p-0"
                      >
                        <div className="position-relative d-flex">
                          <Field
                            className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 mb-0 pe-5 signin__button--size ${
                              errors.passwordRepeat &&
                              touched.passwordRepeat &&
                              "border border-danger"
                            }  ${!errors.passwordRepeat && touched.passwordRepeat && "border border-success"}`}
                            style={{
                              boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                              border: "1px solid transparent",
                              background: "#fff",
                            }}
                            id="passwordRepeat"
                            name="passwordRepeat"
                            value={values.passwordRepeat}
                            placeholder={t("register.step2.repeatPass")}
                            type={typePasswordRep ? "password" : "text"}
                            onChange={handleChange}
                          />
                          <span
                            className="signin__icon position-absolute top-50 end-0 translate-middle-y me-3"
                            style={{
                              backgroundColor: "transparent",
                              cursor: "pointer",
                            }}
                            onClick={() => setTypePasswordRep(!typePasswordRep)}
                          >
                            <img
                              src={
                                typePasswordRep
                                  ? "https://cdn1.iconfinder.com/data/icons/hawcons/32/699007-icon-21-eye-hidden-512.png"
                                  : "https://www.freeiconspng.com/thumbs/eye-icon/eyeball-icon-png-eye-icon-1.png"
                              }
                              alt="eye icon"
                              style={{
                                width: "100%",
                              }}
                            />
                          </span>
                        </div>
                      </Form.Group>
                      {errors.passwordRepeat && touched.passwordRepeat && (
                        <p className="text-danger text-start m-0 ps-2">
                          {errors.passwordRepeat}
                        </p>
                      )}
                    </Row>

                    <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationFormik03"
                        className="p-0"
                      >
                        <Field
                          className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 signin__button--size ${
                            errors.birthday &&
                            touched.birthday &&
                            "border border-danger"
                          } ${!errors.birthday && touched.birthday && "border border-success"}`}
                          style={{
                            boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                            border: "1px solid transparent",
                            background: "#fff",
                          }}
                          id="birthday"
                          name="birthday"
                          value={values.birthday}
                          placeholder="Date of birth"
                          type="text"
                          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.target.type = "date"; // Change the type to date on focus
                            e.target.showPicker(); // Open calendar after click
                          }}
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            if (!e.target.value) e.target.type = "text"; // Change type to text, if field is empty
                          }}
                          onChange={handleChange}
                        />

                        {errors.birthday && touched.birthday && (
                          <p className="text-danger text-start ps-2">
                            {errors.birthday}
                          </p>
                        )}
                      </Form.Group>
                    </Row>

                    <Row className="mb-2 signin__button--size mx-auto">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="terms"
                        className="p-0"
                      >
                        <Form.Check
                          required
                          name="terms"
                          label={t("register.step2.terms")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.terms}
                          isInvalid={!!errors.terms && touched.terms}
                          className="text-start text-light"
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-2 signin__button--size mx-auto">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="privacy"
                        className="p-0"
                      >
                        <Form.Check
                          required
                          name="privacy"
                          label={t("register.step2.policy")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.privacy}
                          isInvalid={!!errors.privacy && touched.privacy}
                          className="text-start text-light"
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="newsletter"
                        className="p-0"
                      >
                        <Form.Check
                          name="newsletter"
                          label={t("register.step2.newsLetter")}
                          onChange={handleChange}
                          id="newsletter"
                          className="text-light text-start"
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-1 signin__button--size mx-auto">
                      <p
                        className={`text-start p-0 m-0 ${errorRegistration ? "text-danger" : "d-none"}`}
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {errorMessage}
                      </p>
                    </Row>

                    <Button
                      type="submit"
                      className="signin__button signin__button--size w-100 border-0 p-2 rounded-5"
                    >
                      {t("register.step2.button")}
                    </Button>

                    <p
                      className="signin__button--size mx-auto mt-2 text-start px-1"
                      style={{
                        color: "#FFF",
                        fontSize: "14px",
                      }}
                    >
                      {t("register.step1.sub.1")}{" "}
                      <Link to="/signin" className="text-white">
                        {t("register.step1.sub.2")}
                      </Link>
                      {t("register.step1.sub.3")}{" "}
                      <Link to="/signin" className="text-white">
                        {t("register.step1.sub.4")}{" "}
                      </Link>
                      .
                    </p>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div
            className="register__step2 d-flex align-items-center justify-content-center"
            style={{
              opacity: visibleGlob ? 1 : 0,
              transition: "all 1.5s",
            }}
          >
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </>
  );
};
