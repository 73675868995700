import React from 'react'

interface IContainerRadio {
  handlechange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  response: string,
  value: string,
  name: string,
  id: string,
}

const ContainerRadio: React.FC<IContainerRadio> = ({handlechange, response,value, name,id}) => {
    return (
            <div className="row justify-content-start align-items-center">
                <div className="col-auto">
                    <input className="form-check-input-form" onChange={handlechange} type="radio" name={name} value={value} id={id} />
                </div>
                <div className="col-auto p-0 text-start pb-1">{response}</div>
            </div>
    )
}

export default ContainerRadio
