import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setAnswer6 } from "../../redux/slices/auth.slice";

interface IStep6 {
  stateStep: (el: number) => void;
}

const Step6: React.FC<IStep6> = ({ stateStep }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [activAnswer, setActivAnswer] = useState("");
  const [certify, setCertify] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleNextStep = () => {
    dispatch(setAnswer6(activAnswer));

    setVisible(false);

    setTimeout(() => {
      stateStep(7);
    }, 1000);
  };

  return (
    <div
      style={{
        zIndex: 1,
        opacity: visible ? 1 : 0,
        transform: `scale(${visible ? 1 : 0.8})`,
        transition: "all 1.5s",
      }}
    >
      <figcaption className="m-0 text-secondary">{t("form.6")}</figcaption>

      <Form.Group className="my-3">
        <Form.Label>{t("form.entreprise")}</Form.Label>
        <Form.Select
          onChange={(e) => setActivAnswer(e.target.value)}
          name="sourceMarketing"
          aria-label="Default select example"
          className="rounded-5"
        >
          <option value=""></option>
          <option value="Facebook">Facebook</option>
          <option value="Twitter">Twitter</option>
          <option value="Instagram">Instagram</option>
          <option value="Google">Google</option>
          <option value="Telegram">Telegram</option>
          <option value="connaissance">{t("form.Autres")}</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="my-3">
        <Form.Check
          type="switch"
          className="text-start"
          name="step6"
          label={t("form.politiquement")}
          onChange={(e) => setCertify(e.target.checked)}
          id="PEPcondition"
        ></Form.Check>
      </Form.Group>

      <div className="w-100 text-center text-lg-end">
        <Button
          disabled={!activAnswer || !certify}
          type="button"
          onClick={handleNextStep}
          className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4"
        >
          {t("form.Suivant")}
        </Button>
      </div>
    </div>
  );
};

export default Step6;
