import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ContainerRadio from "./ContainerRadio";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnswerAnydesk,
  setAnswerAssisted,
  setAnswerBuy,
  setAnswerRisks,
  setAuth,
} from "../../redux/slices/auth.slice";
import { Button } from "react-bootstrap";
import axios from "axios";
import config from "../../config";
import { IRootState } from "../../redux/store/store";
import { useNavigate } from "react-router-dom";

interface IStep7 {
  setVisibleBg: (val: boolean) => void;
  setVisibleGlobBg: (val: boolean) => void;
}

const Step7: React.FC<IStep7> = ({ setVisibleBg, setVisibleGlobBg }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [activAnswer, setActivAnswer] = useState({
    buy: "",
    assisted: "",
    anydesk: "",
    risks: "",
  });

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const { answers } = useSelector(({ authData }: IRootState) => authData);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handlerStartLoader = () => {
    setErrorLogin(false);
    setLoader(true);
  };

  const handlerEndLoader = () => {
    setLoader(false);
    setVisible(false);

    setVisibleBg(false);
    setVisibleGlobBg(false);
  };

  const handlerErrorLoader = () => {
    setErrorLogin(true);
    setLoader(false);
  };

  const handlerLogin = () => {
    axios
      .post(
        `${config.apiURI}/users/user_information`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(setAuth(response.data));

          handlerEndLoader();

          setTimeout(() => {
            navigate("/home");
          }, 1000);
        } else {
          handlerErrorLoader();
        }
      })
      .catch(() => {
        handlerErrorLoader();
      });
  };

  const handleNextStep = () => {
    handlerStartLoader();

    const { buy, assisted, anydesk, risks } = activAnswer;

    dispatch(setAnswerBuy(buy));
    dispatch(setAnswerAssisted(assisted));
    dispatch(setAnswerAnydesk(anydesk));
    dispatch(setAnswerRisks(risks));

    axios
      .post(`${config.apiURI}/form/form`, answers, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const { status } = response;

        status === 200 ? handlerLogin() : handlerErrorLoader();
      })
      .catch((error) => {
        const { message } = error.response.data;

        message === "DUPLICATE ENTRY" ? handlerLogin() : handlerErrorLoader();
      });
  };

  const isAllFieldsEmpty = Object.values(activAnswer).every(
    (value) => value !== ""
  );

  return (
    <div
      className="mb-5"
      style={{
        zIndex: 1,
        opacity: visible ? 1 : 0,
        transform: `scale(${visible ? 1 : 0.8})`,
        transition: "all 1.5s",
      }}
    >
      <figcaption className="m-0 text-secondary">{t("form.7")}</figcaption>

      <h2 className="text-start my-3 h4">{t("form.tiers")}</h2>
      <ContainerRadio
        handlechange={(e) =>
          setActivAnswer((prevState) => ({
            ...prevState,
            buy: e.target.value,
          }))
        }
        response={t("form.oui")}
        value="1"
        name="interetPropre"
        id="interetPropre"
      />
      <ContainerRadio
        handlechange={(e) =>
          setActivAnswer((prevState) => ({
            ...prevState,
            buy: e.target.value,
          }))
        }
        response={t("form.non")}
        value="0"
        name="interetPropre"
        id="interetPropre"
      />

      <h2 className="text-start my-3 h4">{t("form.inscription")}</h2>
      <ContainerRadio
        handlechange={(e) =>
          setActivAnswer((prevState) => ({
            ...prevState,
            assisted: e.target.value,
          }))
        }
        response={t("form.oui")}
        value="1"
        name="finaliserInscription"
        id="finaliserInscription"
      />
      <ContainerRadio
        handlechange={(e) =>
          setActivAnswer((prevState) => ({
            ...prevState,
            assisted: e.target.value,
          }))
        }
        response={t("form.non")}
        value="0"
        name="finaliserInscription"
        id="finaliserInscription"
      />

      <h2 className="text-start my-3 h4">{t("form.Quelqu")}</h2>
      <ContainerRadio
        handlechange={(e) =>
          setActivAnswer((prevState) => ({
            ...prevState,
            anydesk: e.target.value,
          }))
        }
        response={t("form.oui")}
        value="1"
        name="anydesk"
        id="finInscrip"
      />
      <ContainerRadio
        handlechange={(e) =>
          setActivAnswer((prevState) => ({
            ...prevState,
            anydesk: e.target.value,
          }))
        }
        response={t("form.non")}
        value="0"
        name="anydesk"
        id="finInscrip"
      />

      <h2 className="text-start my-3 h4">{t("form.connaissances")}</h2>
      <ContainerRadio
        handlechange={(e) =>
          setActivAnswer((prevState) => ({
            ...prevState,
            risks: e.target.value,
          }))
        }
        response={t("form.oui")}
        value="1"
        name="risque"
        id="risque"
      />
      <ContainerRadio
        handlechange={(e) =>
          setActivAnswer((prevState) => ({
            ...prevState,
            risks: e.target.value,
          }))
        }
        response={t("form.non")}
        value="0"
        name="risque"
        id="risque"
      />

      <div className="w-100 d-flex justify-content-center justify-content-lg-end">
        <div className="d-flex flex-column signin__button--size text-center mb-5">
          <Button
            disabled={!isAllFieldsEmpty}
            type="button"
            onClick={handleNextStep}
            className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4"
          >
            {loader ? (
              <div
                className="spinner-border mx-auto text-dark"
                role="status"
                style={{
                  height: "20px",
                  width: "20px",
                }}
              />
            ) : (
              t("form.Suivant")
            )}
          </Button>

          {errorLogin && (
            <span
              className="text-danger"
              style={{
                fontSize: "12px",
              }}
            >
              {t("errors.someWrong")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step7;
