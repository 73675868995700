import React, { useEffect } from "react";
import { Layout } from "../components/Layout";
import { Link } from "react-router-dom";
import logoCorner from "../static/icons/CornerSwapFinal.svg";

export const Security = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div className="container py-5">
        <div className="d-flex justify-content-center flex-wrap px-2">
          <Link
            to="/policy"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Privacy Policy
          </Link>

          <Link
            to="/security"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
          >
            Security
          </Link>

          <Link
            to="/amlkyc"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            AML/KYC
          </Link>

          <Link
            to="/conditions"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Terms and conditions
          </Link>
        </div>
        <p className="privacy__title mt-5">Security Policy</p>
        <p className="privacy__text">Last Updated: 06.06.24</p>
        <p className="privacy__text">
          Cornerswap ("we," "our," or "us") is committed to protecting the
          security of our platform and the data of our users. This Security
          Policy outlines the measures we take to ensure the confidentiality,
          integrity, and availability of the information and assets under our
          control. By using Cornerswap, you agree to comply with this Security
          Policy
        </p>
        <p className="privacy__subtitle">1. Security Governance</p>
        <p className="privacy__text">
          We maintain a comprehensive security program designed to protect our
          platform and user data. This program is overseen by our dedicated
          security team, which is responsible for implementing and maintaining
          security controls, monitoring for threats, and responding to security
          incidents.
        </p>
        <p className="privacy__subtitle">2. Access Control</p>

        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Authentication: </span>
            We enforce strong authentication mechanisms, including multi-factor
            authentication (MFA), to verify the identity of users accessing our
            platform.
          </li>

          <li>
            <span className="fw-bold">Authorization: </span>
            Access to systems and data is granted on a need-to-know basis,
            ensuring that users only have access to the resources necessary for
            their role.
          </li>

          <li>
            <span className="fw-bold">User Management: </span>
            User accounts are regularly reviewed and deactivated when no longer
            needed or when an employee leaves the company.
          </li>
        </ul>
        <p className="privacy__subtitle">3. Data Protection</p>

        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Encryption: </span>
            We use industry-standard encryption protocols to protect data in
            transit and at rest. Sensitive data, such as personal and financial
            information, is encrypted using robust algorithms.
          </li>

          <li>
            <span className="fw-bold">Data Minimization: </span>
            We collect and retain only the minimum amount of data necessary to
            provide our services.
          </li>

          <li>
            <span className="fw-bold">Data Backup: </span>
            Regular backups of critical data are performed to ensure data
            availability and integrity. Backups are stored securely and tested
            periodically for reliability.
          </li>
        </ul>
        <p className="privacy__subtitle">4. Network Security</p>

        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Firewalls and Intrusion Detection: </span>
            We deploy firewalls and intrusion detection systems to monitor and
            protect our network from unauthorized access and malicious activity.
          </li>

          <li>
            <span className="fw-bold">Secure Communication: </span>
            All communications between users and our platform are encrypted
            using secure protocols such as TLS.
          </li>

          <li>
            <span className="fw-bold">Network Segmentation: </span>
            Our network is segmented to limit the impact of potential security
            breaches and to separate sensitive data from less critical systems.
          </li>
        </ul>
        <p className="privacy__subtitle">5. Application Security</p>

        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Secure Development Practices: </span>
            Our development team follows secure coding practices and performs
            regular code reviews to identify and mitigate vulnerabilities.
          </li>

          <li>
            <span className="fw-bold">Vulnerability Management: </span>
            We regularly scan our applications for vulnerabilities and apply
            patches and updates in a timely manner to address security issues.
          </li>

          <li>
            <span className="fw-bold">Penetration Testing: </span>
            We conduct periodic penetration tests to identify and address
            potential security weaknesses in our platform.
          </li>
        </ul>
        <p className="privacy__subtitle">6. Incident Response</p>

        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Incident Detection: </span>
            We continuously monitor our systems for signs of security incidents,
            using automated tools and manual processes.
          </li>

          <li>
            <span className="fw-bold">Incident Response Plan: </span>
            We have a detailed incident response plan in place to quickly and
            effectively respond to security incidents. This plan includes steps
            for containment, investigation, remediation, and communication.
          </li>

          <li>
            <span className="fw-bold">Incident Reporting: </span>
            Users and employees are encouraged to report any suspected security
            incidents or vulnerabilities to our security team immediately.
          </li>
        </ul>
        <p className="privacy__subtitle">7. Employee Training and Awareness</p>

        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Security Training: </span>
            All employees receive regular training on security best practices,
            data protection, and how to identify and respond to potential
            security threats.
          </li>

          <li>
            <span className="fw-bold">Awareness Programs: </span>
            We run ongoing security awareness programs to keep employees
            informed about the latest security trends and threats.
          </li>
        </ul>
        <p className="privacy__subtitle">8. Compliance and Audits</p>

        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Regulatory Compliance: </span>
            We comply with applicable laws, regulations, and industry standards
            related to data security and privacy.
          </li>

          <li>
            <span className="fw-bold">Third-Party Audits: </span>
            Third-Party Audits: We undergo regular third-party security audits
            and assessments to validate the effectiveness of our security
            controls and to identify areas for improvement.
          </li>
        </ul>
        <p className="privacy__subtitle">9. Vendor Management</p>

        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Third-Party Risk Management: </span>
            We assess the security practices of third-party vendors and partners
            before engaging with them and require them to adhere to our security
            standards.
          </li>

          <li>
            <span className="fw-bold">Continuous Monitoring: </span>
            We regularly monitor third-party vendors for compliance with our
            security requirements and address any identified risks.
          </li>
        </ul>
        <p className="privacy__subtitle">10. Policy Review</p>
        <p className="privacy__text">
          This Security Policy is reviewed and updated regularly to ensure it
          remains relevant and effective in addressing emerging security threats
          and changes in our operational environment.
        </p>
        <p className="privacy__subtitle">Contact Us</p>
        <p className="privacy__text">
          If you have any questions or concerns about this Security Policy,
          please contact us at:{" "}
          <span className="fw-bold">office@cornerswap.io</span>
        </p>
        <p className="privacy__text">
          By using our platform, you acknowledge that you have read and
          understood this Security Policy. Thank you for trusting Cornerswap
          with the security of your information.
        </p>
      </div>
    </Layout>
  );
};
